import React, { useState } from 'react';

const BuyDefiButton = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleTouchStart = (e) => {
    e.preventDefault();
    setShowTooltip(true);
  };

  const handleTouchEnd = () => {
    setTimeout(() => {
      setShowTooltip(false);
    }, 2000);
  };

  return (
    <div 
      className="buy-defi-container"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      style={{ display: 'inline-block', position: 'relative' }}
    >
      <button className="buy-defi-button">
        Buy $DEFI
      </button>
      {showTooltip && (
        <div style={{
          position: 'absolute',
          top: '100%',
          left: '-20px',
          backgroundColor: '#161A42',
          color: 'white',
          padding: '8px 12px',
          borderRadius: '8px',
          fontSize: '12px',
          width: '220px',
          textAlign: 'center',
          zIndex: 1000,
          border: '1px solid #4DFFFFFF',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          pointerEvents: 'none',
          marginTop: '5px'
        }}>
The DeFi Official Token is launching before Christmas! Stay tuned Degens!
</div>
      )}
      <style jsx>{`
        @keyframes glowing {
          0% { box-shadow: 0 0 5px #6366f1, 0 0 10px #6366f1, 0 0 12px #8b5cf6; }
          50% { box-shadow: 0 0 7px #6366f1, 0 0 15px #6366f1, 0 0 15px #8b5cf6; }
          100% { box-shadow: 0 0 5px #6366f1, 0 0 10px #6366f1, 0 0 12px #8b5cf6; }
        }

        .buy-defi-button {
          background: linear-gradient(180deg, #161A42 0%, #161A42 0%);
          color: #FFFFFF;
          padding: 6px 12px;
          font-size: 12px;
          font-weight: normal;
          border-radius: 8px;
          height: 35px;
          border: 1px solid #4DFFFFFF;
          cursor: pointer;
          margin-right: 0px;
          margin-left: 14px;
          animation: glowing 2s infinite;
          transition: all 0.3s ease;
        }

        .buy-defi-button:hover {
          animation: none;
          box-shadow: 0 0 15px #6366f1, 0 0 20px #8b5cf6, 0 0 25px #8b5cf6;
        }

        @media (max-width: 768px) {
          .buy-defi-container {
            display: none !important;
          }
        }
      `}</style>
    </div>
  );
};

export default BuyDefiButton;