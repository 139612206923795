import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import "../launchpadApply/index.css";
import Context from "./context/Context";
import { supportNetwork } from "../../hooks/network";
import { useChainId } from "wagmi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import successImage from '../../images/success.png'; // Adjust the path based on your project structure

export default function TokenDetails(props) {
  const [copied, setCopied] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const context = useWeb3React();
  const chainId = useChainId();
  const tokenAddress = params.get("addr");
  const scanUrl = `${supportNetwork[chainId]['scan']}address/${tokenAddress}`;
  const networkName = `${supportNetwork[chainId]['name']}`;

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const desktopButtonStyle = {
    backgroundColor: "#8e44ad",
    color: "#fff",
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 300,
    fontSize: "14px",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    margin: "0 5px",
    transition: "background-color 0.3s ease",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };

  const mobileButtonStyle = {
    backgroundColor: "#8e44ad",
    color: "#fff",
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 300,
    fontSize: "12px",
    border: "none",
    padding: "10px 16px", // Ensure padding is consistent
    borderRadius: "5px",
    margin: "5px 0",
    transition: "background-color 0.3s ease",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40px", // Set a fixed height for uniformity
    width: "100%", // Optional: Ensure full width, adjust as needed
    maxWidth: "250px" // Optional: Control max width if buttons are too wide
  };

  return (
    <Context.Provider>
      <React.Fragment>
        <style>{`
          .desktop-view, .mobile-view {
            padding-top: 0;
            margin-top: 0;
          }

          /* Default to Desktop View */
          .desktop-view {
            display: block;
          }

          .mobile-view {
            display: none;
          }

          /* Mobile View */
          @media (max-width: 768px) {
            .desktop-view {
              display: none;
            }

            .mobile-view {
              display: block;
            }

            /* Ensure all buttons have the same height */
            .mobile-view .btn {
              height: 40px;
              width: 100%;
              max-width: 250px;
              margin: 5px 0;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        `}</style>

        {/* Desktop View */}
        <section className="desktop-view">
          <div className="container px-3">
            <div className="my-5">
              <div className="card stepcard">
                <div className="card-body">
                  <h5 className="text-center" style={{ marginBottom: '10px' }}>
                    SUCCESS!!!!
                  </h5>
                  <div className="text-center" style={{ margin: '10px 0' }}>
                    <img src={successImage} alt="Success" style={{ maxWidth: '300px', margin: '0px' }} />
                  </div>
                  <h5 className="text-center my-5 ">
                    {tokenAddress}
                  </h5>

                  <div className="d-flex justify-content-center">
                    <CopyToClipboard text={tokenAddress} onCopy={handleCopy}>
                      <button style={desktopButtonStyle}>
                        {copied ? "Copied" : "Copy Address"}
                      </button>
                    </CopyToClipboard>

                    <a
                      className="btn"
                      href={`${scanUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={desktopButtonStyle}
                    >
                      View on {networkName}
                    </a>

                    <Link
                      className="btn"
                      to="/fairlaunch"
                      style={desktopButtonStyle}
                    >
                      Create Fairlaunch
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Mobile View */}
        <section className="mobile-view">
          <div className="container px-3">
            <div className="my-5">
              <div className="card stepcard">
                <div className="card-body">
                  <h5 className="text-center" style={{ marginBottom: '5px' }}>
                    SUCCESS!!
                  </h5>
                  <div className="text-center" style={{ margin: '5px 0' }}>
                    <img src={successImage} alt="Success" style={{ maxWidth: '150px', margin: '0px' }} />
                  </div>
                  <h6 className="text-center" style={{ marginTop: '5px' }}>
                    <br /><span style={{ fontSize: '10px' }}>{tokenAddress}</span>
                  </h6>

                  <div className="d-flex flex-column align-items-center">
                    <CopyToClipboard text={tokenAddress} onCopy={handleCopy}>
                      <button style={mobileButtonStyle}>
                        {copied ? "Copied" : "Copy Address"}
                      </button>
                    </CopyToClipboard>

                    <a
                      className="btn"
                      href={`${scanUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={mobileButtonStyle}
                    >
                      View on {networkName}
                    </a>

                    <Link
                      className="btn"
                      to="/fairlaunch"
                      style={mobileButtonStyle}
                    >
                      Create Fair Launch
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    </Context.Provider>
  );
}
