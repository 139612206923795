import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StakingPoolCard from './StakingPoolCard';
import { AlertCircle, X } from 'lucide-react';

// Mock data for staking pools
const stakingPools = [
    {
      id: 1,
      name: 'HOLD',
      tokenSymbol: 'EARN',
      totalStaked: '900,993,959 EARN',
      apy: '0 %',
      hardCap: '100M',
      totalStakers: '8',
      liveStatus: true,
      timeRemaining: '13:00:58',
      logoUrl: 'https://i.postimg.cc/63NmBk4q/template.jpg',  // Direct URL to project logo
      bannerUrl: 'https://i.postimg.cc/2554h2pG/template-banner.jpg', // Direct URL to project banner
    },
    {
      id: 2,
      name: 'Mecat Staking #01',
      tokenSymbol: 'MECAT',
      totalStaked: '594,039,973 MECAT',
      apy: '200 %',
      hardCap: '5M',
      totalStakers: '594K',
      liveStatus: true,
      timeRemaining: '3:06:20:58',
      logoUrl: 'https://i.postimg.cc/63NmBk4q/template.jpg',  // Direct URL to project logo
      bannerUrl: 'https://i.postimg.cc/2554h2pG/template-banner.jpg', // Direct URL to project banner
    },
];

const StakingPoolsList = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleCreatePool = () => {
    setShowModal(true);
  };

  return (
    <div
      style={{
        width: '100%',
        minHeight: '100vh',
        backgroundColor: '#0A0B1E',
        color: '#FFFFFF',
        padding: '20px',
        fontFamily: "'Quicksand', sans-serif",
        position: 'relative',
      }}
    >
      {/* Header Section */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <h1 style={{ fontSize: '2rem', fontWeight: 'bold' }}>Staking Pools</h1>
        <button
          style={{
            padding: '10px 20px',
            backgroundColor: '#3461FF',
            color: '#FFFFFF',
            fontSize: '1rem',
            fontWeight: '500',
            borderRadius: '8px',
            cursor: 'pointer',
            border: 'none',
          }}
          onClick={handleCreatePool}
        >
          Create Pool
        </button>
      </div>

      {/* Staking Pools Grid */}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gap: '20px',
        }}
      >
        {stakingPools.map((pool) => (
          <StakingPoolCard key={pool.id} pool={pool} />
        ))}
      </div>

      {/* Modal */}
      {showModal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: '#161A42',
              borderRadius: '12px',
              padding: '32px',
              maxWidth: '500px',
              width: '90%',
              position: 'relative',
            }}
          >
            {/* Close button */}
            <button
              onClick={() => setShowModal(false)}
              style={{
                position: 'absolute',
                right: '16px',
                top: '16px',
                background: 'none',
                border: 'none',
                color: '#7B91B0',
                cursor: 'pointer',
              }}
            >
              <X size={24} />
            </button>

            {/* Modal content */}
            <div style={{ textAlign: 'center' }}>
              <div style={{ marginBottom: '24px', display: 'flex', justifyContent: 'center' }}>
                <AlertCircle size={48} color="#3461FF" />
              </div>
              
              <h2 style={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '16px' }}>
                Staking Will Be Activated After The DeFi Token Launch
              </h2>
              
              <p style={{ color: '#7B91B0', marginBottom: '32px' }}>
                The staking functionality is currently paused and will be available after our token launch. Please check back later.
              </p>
              
              <button
                onClick={() => setShowModal(false)}
                style={{
                  width: '100%',
                  padding: '12px',
                  backgroundColor: '#3461FF',
                  color: '#FFFFFF',
                  fontWeight: '500',
                  borderRadius: '8px',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StakingPoolsList;