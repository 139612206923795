import React from 'react';
import { useNavigate } from 'react-router-dom';
import CreateTokenIcon from '../images/CreateCoinIcon.svg';
import BondingCurveIcon from '../images/PumpSaleIcon.svg';
import CenterShadow from '../images/CenterShadowBehindHomeIcon.svg';
import HomeIcon from "../images/HomeIconBottom.svg";
import FairLaunchIcon from "../images/FairLaunchIconBottom.svg";
import DiamondIcon from "../images/DiamondIconBottom.svg";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChatIcon from "../images/ChatIconBottom.svg";
import PortfolioIcon2 from '../images/PortfolioIcon2.svg';


const BottomNavigation = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:768px)'); // Check if the screen width is mobile size

  if (!isMobile) return null; // Return nothing if it's not mobile

  const handleHomeClick = () => {
    navigate("/");
  };

  const handlePortfolioClick = () => {
    navigate("/wallet");
  }; 
  const handleCreateTokenClick = () => {
    navigate("/token/Standard");
  };

  const handleBondingCurveClick = () => {
    navigate("/bonding-token-sale");
  };

  const handleFairLaunchClick = () => {
    navigate("/fairlaunch");
  };

  const handleFavoritesClick = () => {
    navigate("/favorites");
  };

  const handleChatClick = () => {
    navigate("/new-chat"); // Updated to navigate to the new chat component
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      position: 'fixed',
      bottom: '0px',
      width: '100%',
      background: '#161A42',
      padding: '0',
      zIndex: 10000,
      boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      height: '60px'
    }}>
      <img src={BondingCurveIcon} alt="Bonding Curve" className="bottom-navigation-icon" onClick={handleBondingCurveClick} />
      <img src={PortfolioIcon2} alt="Wallet" className="bottom-navigation-icon" onClick={handlePortfolioClick} />
       {/* <img src={FairLaunchIcon} alt="Fair Launch" className="bottom-navigation-icon" onClick={handleFairLaunchClick} /> */}
      <div className="center-icon-container" onClick={handleHomeClick}>
        <img src={CenterShadow} alt="Center Shadow" className="center-shadow" style={{ position: 'absolute', top: 0, left: '50%', transform: 'translateX(-50%)', height: '150px', width: '80px', objectFit: 'cover' }} />
        <img src={HomeIcon} alt="Home" className="bottom-navigation-icon center-icon" style={{ position: 'relative', zIndex: 1, left: '3.5px', top: '2px' }} />
      </div>
      <img src={ChatIcon} alt="Chat" className="bottom-navigation-icon" onClick={handleChatClick} />
      <img src={DiamondIcon} alt="Diamond" className="bottom-navigation-icon" onClick={handleFavoritesClick} />
    </div>
  );
};

export default BottomNavigation;
