import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import templateImg from "../images/template.jpg";

const TopPumpSellers = ({ bondingStats }) => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();

  const getTopSellers = () => {
    if (!bondingStats?.poolList) return [];

    const MINIMUM_MARKET_CAP = 1000; // $1000 minimum threshold

    const filtered = bondingStats.poolList
      .filter(pool => {
        const marketCap = Number(pool.currentMK);
        return !isNaN(marketCap) && marketCap >= MINIMUM_MARKET_CAP;
      })
      .sort((a, b) => Number(b.currentMK) - Number(a.currentMK))
      .slice(0, 10);

      return [...filtered, ...filtered, ...filtered, ...filtered, ...filtered, ...filtered, ...filtered, ...filtered, ...filtered, ...filtered, ...filtered, ...filtered, ...filtered, ...filtered, ...filtered, ...filtered, ...filtered, ...filtered, ...filtered];
    };

  const topSellers = getTopSellers();

  useEffect(() => {
    if (topSellers.length === 0) return;

    const scrollSpeed = 1;
    let animationFrameId;
    let lastTimestamp = 0;

    const animate = (timestamp) => {
      if (!lastTimestamp) lastTimestamp = timestamp;
      const delta = timestamp - lastTimestamp;
      
      setScrollPosition(prev => {
        const newPosition = prev - (scrollSpeed * delta) / 16;
        const resetPoint = -(topSellers.length * 150) / 2;
        return newPosition <= resetPoint ? 0 : newPosition;
      });

      lastTimestamp = timestamp;
      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrameId);
  }, [topSellers.length]);

  const handleProjectClick = (poolAddress) => {
    if (poolAddress) {
      navigate(`/bonding-details/${poolAddress}`);
    }
  };

  if (topSellers.length === 0) return null;

  return (
    <div style={{ 
      padding: '0 10px',
      marginBottom: '0px'
    }}>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'flex-start', 
        alignItems: 'center',
        marginBottom: isMobile ? '4px' : '8px',
        gap: isMobile ? '75px' : '20px',
        marginTop: '4px',
        paddingBottom: isMobile ? '0' : '4px'
      }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
          <span style={{ 
            color: '#6EE7B7', 
            fontSize: isMobile ? '14px' : '16px',
            fontWeight: '500'
          }}>🔥 {isMobile ? 'Pump' : 'Pump Sale'}</span>
          <span style={{ 
            color: '#7B91B0', 
            fontSize: isMobile ? '14px' : '16px',
            fontWeight: '500'
          }}>&gt;</span>
          <span style={{ 
            color: '#6EE7B7', 
            fontSize: isMobile ? '14px' : '16px',
            fontWeight: '500'
          }}>Hot Sellers</span>
        </div>
        <Link 
          to="/bonding-token-sale"
          style={{
            backgroundColor: '#3461FF',
            color: 'white',
            padding: isMobile ? '7px 12px' : '6px 20px',
            borderRadius: '8px',
            fontSize: isMobile ? '12px' : '12px',
            textDecoration: 'none',
            fontWeight: '500',
            marginBottom: isMobile ? '4px' : '4px'
          }}
        >
          {isMobile ? 'CREATE PUMP' : 'CREATE PUMP'}
        </Link>
      </div>

      <div style={{ 
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: isMobile ? '45px' : '55px',
        marginBottom: '1px'
      }}>
        <div style={{ 
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          transform: `translateX(${scrollPosition}px)`,
          transition: 'transform 0.1s linear',
          gap: '24px',
          padding: 0
        }}>
          {topSellers.map((seller, index) => (
            <div 
              key={`${seller.poolAddress}-${index}`}
              onClick={() => handleProjectClick(seller.poolAddress)}
              style={{ 
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                minWidth: 'max-content',
                transition: 'transform 0.2s ease',
                ':hover': {
                  transform: 'translateY(-2px)'
                }
              }}
            >
              <img
                src={seller.logourl || '/placeholder.png'}
                alt={seller.name}
                onError={(e) => {
                  e.target.src = templateImg;
                }}
                style={{
                  width: isMobile ? '34px' : '42px',
                  height: isMobile ? '34px' : '42px',
                  borderRadius: '6px',
                  objectFit: 'cover'
                }}
              />
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2px'
              }}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px'
                }}>
                  <span style={{ 
                    color: 'white', 
                    fontSize: isMobile ? '13px' : '15px',
                    fontWeight: '500'
                  }}>
                    {seller.name}
                  </span>
                  <div style={{
                    width: isMobile ? '10px' : '12px',
                    height: isMobile ? '10px' : '12px',
                    backgroundColor: '#00FF00',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: isMobile ? '7px' : '8px',
                    color: 'white'
                  }}>
                    ✓
                  </div>
                </div>
                <span style={{ 
                  color: '#6EE7B7', 
                  fontSize: isMobile ? '13px' : '15px',
                  fontWeight: '500'
                }}>
                  ${Number(seller.currentMK).toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopPumpSellers;