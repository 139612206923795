import { useEffect, useState, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import bondingPoolAbi from "../../../../json/BondingPool.json";
// import lockAbi from '../../../../json/lockabi.json';
import ERC20Abi from "../../../../json/ERC20.json";
import {
  MulticallContractWeb3,
  multiCallContractConnect,
} from "../../../../hooks/useContracts";
import { getWeb3 } from "../../../../hooks/connectors";
import tokenAbi from "../../../../json/token.json";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { supportNetwork } from "../../../../hooks/network";
import { currencies } from "../../../../hooks/currencies";
import { readBondTxdata } from "./backend";
import { useAccount, useChainId } from "wagmi";

export const useCommonStats = (update) => {
  const context = useWeb3React();
  const chainId = useChainId()
  let navigate = useNavigate();

  // let poolAddress = "0x6816e27bca20fbbe779ca9725d48c1e01a02943c";

  const location = useLocation();
  let urlAddress = location.pathname.split("/").pop();
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get("chainid");

  const getCurrencyList = (currencies) => {
    let currencyList = [];
    currencies.map((currency, index) => {
      currencyList[currency.address] = currency.symbol;
    });
    return currencyList;
  };

  let currencyList = getCurrencyList(
    currencies[chainId] !== undefined
      ? currencies[chainId]
      : currencies["default"]
  );

  let web3 = getWeb3(queryChainId ? queryChainId : chainId);

  let poolContract = new web3.eth.Contract(bondingPoolAbi, urlAddress);

  const [stats, setStats] = useState({
    poolDetails: 0,
    poolState: 0,
    tokenPrice: 0,
    currentMK: 0,
    holders: [],
    token: 0,
    tokenName: "",
    tokenDecimal: 0,
    tokenSymbol: 0,
    tokenSupply: 0,
    poolAddress: 0,
    governance: 0,
    auditStatus: false,
    kycStatus: false,
    marketCap: 0,
    circulatingSupply: 0,
    currencySymbol: supportNetwork[queryChainId ? queryChainId : chainId]
      ? supportNetwork[queryChainId ? queryChainId : chainId].symbol
      : supportNetwork["default"].symbol,
  });

  const mc = MulticallContractWeb3(queryChainId ? queryChainId : chainId);

  // let lockAddress = contract[queryChainId ? queryChainId : chainId] ? contract[queryChainId ? queryChainId : chainId].lockAddress : contract['default'].lockAddress;
  // let lmc = new web3.eth.Contract(lockAbi, lockAddress);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await mc.aggregate([

          poolContract.methods.getPoolInfo(),
          poolContract.methods.governance(),
          // poolContract.methods.routerVersion()
        ]);
        // let tokenContract = new web3.eth.Contract(tokenAbi, data[9]);

        // const tokendata = await mc.aggregate([
        //   tokenContract.methods.name(),
        //   tokenContract.methods.decimals(),
        //   tokenContract.methods.symbol(),
        //   tokenContract.methods.totalSupply(),
        //   tokenContract.methods.balanceOf(
        //     "0x000000000000000000000000000000000000dead"
        //   ),
        // ]);


        // const lockdata = await mc.aggregate([
        //   lmc.methods.cumulativeLockInfo(data[9])
        // ]);
        setStats({
          poolDetails: data[0][5],
          poolState: data[0][1][0],
          tokenPrice: data[0][9],
          currentMK: data[0][2][5] / 10 ** (18 + Number(data[0][1][2])) * data[0][9],
          holders: data[0][8],
          token: data[0][0],
          tokenName: data[0][3],
          tokenDecimal: data[0][1][2],
          tokenSymbol: data[0][4],
          tokenSupply: data[0][10],
          poolAddress: urlAddress,
          governance: data[1],
          auditStatus: data[0][2][3],
          kycStatus: data[0][2][2],
          marketCap: data[0][2][4],
          circulatingSupply: data[0][2][5],
          currencySymbol: supportNetwork[queryChainId ? queryChainId : chainId]
            ? supportNetwork[queryChainId ? queryChainId : chainId].symbol
            : supportNetwork["default"].symbol,
        });
      } catch (err) {
        console.log("Err", err);

      }
    };

    if (mc) {
      fetch();
    } else {
      setStats({
        poolDetails: 0,
        poolState: 0,
        tokenPrice: 0,
        currentMK: 0,
        holders: [],
        token: 0,
        tokenName: "",
        tokenDecimal: 0,
        tokenSymbol: 0,
        tokenSupply: 0,
        poolAddress: 0,
        governance: 0,
        auditStatus: false,
        kycStatus: false,
        marketCap: 0,
        circulatingSupply: 0,
        currencySymbol: supportNetwork[queryChainId ? queryChainId : chainId]
          ? supportNetwork[queryChainId ? queryChainId : chainId].symbol
          : supportNetwork["default"].symbol
      });
    }
    // eslint-disable-next-line
  }, [update, chainId]);

  return stats;
};

export const useAccountStats = (updater) => {
  const context = useWeb3React();
  const chainId = useChainId();
  const { address: account } = useAccount();
  const location = useLocation();
  let navigate = useNavigate();
  let urlAddress = location.pathname.split("/").pop();
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get("chainid");

  let web3 = getWeb3(queryChainId ? queryChainId : chainId);

  try {
    let poolAddress = web3.utils.isAddress(urlAddress);
    let isCode = web3.eth.getCode(urlAddress);
    if (isCode === "0x" || !poolAddress) {
      navigate("/sale-list");
    }
  } catch (err) {
    navigate("/");
  }

  let poolContract = new web3.eth.Contract(bondingPoolAbi, urlAddress);
  const [stats, setStats] = useState({
    balance: 0,
    tokenBalance: 0,
    allowance: 0
  });

  const mc = MulticallContractWeb3(queryChainId ? queryChainId : chainId);
  const mcc = multiCallContractConnect(queryChainId ? queryChainId : chainId);

  useEffect(() => {
    const fetch = async () => {
      try {

        const data = await mc.aggregate([
          mcc.methods.getEthBalance(account),
          poolContract.methods.token(),
        ]);
        let tokenContract = new web3.eth.Contract(tokenAbi, data[1]);

        const tokenBalance = await mc.aggregate([
          tokenContract.methods.allowance(account, urlAddress),
          tokenContract.methods.balanceOf(account),
        ]);

        setStats({ balance: data[0], tokenBalance: tokenBalance[1], allowance: tokenBalance[0] })
      } catch (err) {
        console.log("err reson", err);
        // fetch();
        navigate("/sale-list");
      }
    };

    if (mc && account) {
      fetch();
    } else {
      setStats({
        balance: 0,
        tokenBalance: 0,
        allowance: 0
      });
    }
    // eslint-disable-next-line
  }, [account, updater, chainId]);

  return stats;
};

export const useTransactionStats = (updater, poolAddress) => {
  const [stats, setStats] = useState([]);
  const context = useWeb3React();
  const chainId = useChainId()
  const setBondTxData = useCallback(async () => {
    try {
      const response_data = await readBondTxdata({ chainId: chainId ? chainId.toString() : "8453", poolAddress });
      setStats(response_data);
    }
    catch (err) {
      console.log("server error!")
      setStats([])
    }

  }, [updater, chainId, poolAddress])
  useEffect(() => {
    setBondTxData();
  }, [updater, chainId, poolAddress])
  return stats;
}

export const useHoldersStats = (holders, token) => {
  const context = useWeb3React();
  const chainId = useChainId();
  const { address: account } = useAccount();
  let mc = '';
  let web3 = ''
  let tokenContract = ''
  let callData = ''
  let totalSupply = ''
  useEffect(() => {
    if (chainId && token) {
      mc = MulticallContractWeb3(chainId);
      web3 = getWeb3(chainId);
      tokenContract = new web3.eth.Contract(tokenAbi, token);
      callData = holders.map((holder) => (
        tokenContract.methods.balanceOf(
          holder
        )
      ));
      totalSupply = tokenContract.methods.totalSupply()
    }

  }, [chainId, token])

  const [stats, setStats] = useState([]);

  const setHoldersData = useCallback(async () => {
    if (mc && account && chainId) {
      const holdersData = await mc.aggregate([...callData, totalSupply]);
      let modifiedHolders = holders.map((holder, i) => {
        let oneHolder = { holder: holder, holding: (Number(holdersData[i]) * 100 / Number(holdersData[holdersData.length - 1])).toFixed(4) }
        return oneHolder;
      });
      setStats(modifiedHolders);
    }

  }, [holders, account, chainId])
  useEffect(() => {
    setHoldersData();
  }, [account, chainId, holders])
  // console.log("holdersData", holdersData);
  return stats;
}