import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import { usePoolListStats } from "./helper/useStats";
import ProjectCard from "../../../component/ProjectCard";
import BondingCard from "../../../component/BondingCard";

const Favorites = () => {
  const [updater] = useState({
    page: 0,
    pageSize: 30,
    loading: true,
  });
  
  const statsAll = usePoolListStats(updater);
  const stats = statsAll[0];
  const bondingStats = statsAll[1];
  const [fairSaleFavorites, setFairSaleFavorites] = useState([]);
  const [pumpSaleFavorites, setPumpSaleFavorites] = useState([]);

  useEffect(() => {
    const fairSaleAddresses = JSON.parse(localStorage.getItem("favorites")) || [];
    const pumpSaleAddresses = JSON.parse(localStorage.getItem("pumpFavorites")) || [];

    if (!stats.loading && stats.poolList.length > 0) {
      const fairSaleProjects = stats.poolList.filter((project) =>
        fairSaleAddresses.includes(project.poolAddress)
      );
      setFairSaleFavorites(fairSaleProjects);
    }

    if (!bondingStats.loading && bondingStats.poolList.length > 0) {
      const pumpSaleProjects = bondingStats.poolList.filter((project) =>
        pumpSaleAddresses.includes(project.poolAddress)
      );
      setPumpSaleFavorites(pumpSaleProjects);
    }
  }, [stats.loading, stats.poolList, bondingStats.loading, bondingStats.poolList]);

  const formatEndDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("en-GB", {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  if (stats.loading || bondingStats.loading) {
    return (
      <div className="container project-list-container">
        <div className="col-md-12">
          <HashLoader
            size="100"
            color="#fff"
            cssOverride={{
              left: "50%",
              textAlign: "center",
              top: "50%",
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="container project-list-container">
      {/* Fair Launch Section */}
      <h2 style={{
        color: '#6EE7B7',
        fontSize: '24px',
        fontWeight: '500',
        padding: '15px 0',
        marginBottom: '20px'
      }}>
        🔥 Fair Launch Favorites
      </h2>
      <div className="row project-cards-container">
        {fairSaleFavorites.length > 0 ? (
          fairSaleFavorites.map((rowdata, index) => (
            <ProjectCard
              chainId={stats.chainId}
              rowdata={{ ...rowdata, formattedEndDate: formatEndDate(rowdata.endTime) }}
              index={index}
              key={rowdata.poolAddress + rowdata.token}
              className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 item explore-item mb-2 mt-2"
            />
          ))
        ) : (
          <div className="col-12 item explore-item mt-5">
            <p className="text-center" style={{ color: '#7B91B0' }}>No Fair Launch Favorites Found</p>
          </div>
        )}
      </div>

      {/* Pump Sale Section */}
      <h2 style={{
        color: '#6EE7B7',
        fontSize: '24px',
        fontWeight: '500',
        padding: '15px 0',
        marginTop: '30px',
        marginBottom: '20px'
      }}>
        ⭐️ Pump Sale Favorites
      </h2>
      <div className="row project-cards-container">
        {pumpSaleFavorites.length > 0 ? (
          pumpSaleFavorites.map((rowdata, index) => (
            <BondingCard
              chainId={stats.chainId}
              rowdata={rowdata}
              index={index}
              key={rowdata.poolAddress + rowdata.token}
              className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 item explore-item mb-2 mt-2"
            />
          ))
        ) : (
          <div className="col-12 item explore-item mt-5">
            <p className="text-center" style={{ color: '#7B91B0' }}>No Pump Sale Favorites Found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Favorites;