import React from "react";
import Connect from "./Connect";

export default function Header({ setOpen }) {
  return (
    <React.Fragment>
      <header id="header">
        <Connect setOpen={setOpen} />
      </header>
    </React.Fragment>
  );
}
