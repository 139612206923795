import React, { useState, useContext, useEffect } from "react";
import "../launchpadApply/index.css";
import StandardToken from "./StandardToken";
import AdvancedToken from "./AdvancedToken";
import Context from "./context/Context";
import { useWeb3React } from "@web3-react/core";
import { defaultValue } from "./context/defaults";
import CreateTokenIcon from '../../images/CreateTokenIcon.png';
import launchpadIcon2 from "../../images/LaunchpadIcon2.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useAccount, useChainId } from "wagmi";

export default function MainToken() {
  let { tokenType } = useParams();
  const [createFee, setCreateFee] = useState(2 * 10 ** 15);
  const [taxCreateFee, setTaxCreateFee] = useState(4 * 10 ** 15);
  const [advancedCreateFee, setAdvancedCreateFee] = useState(8 * 10 ** 15);
  const appContext = useContext(Context);
  const [context, setContext] = useState(appContext);
  const chainId = useChainId();
  const { address: account } = useAccount();
  const navigate = useNavigate();
  const [wobble, setWobble] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const setValue = (value) => {
    setContext({ ...context, value });
  };

  useEffect(() => {
    if (chainId && (chainId === '56' || chainId === '97')) {
      setCreateFee(15 * 10 ** 15);
      setTaxCreateFee(3 * 10 ** 16);
    } else {
      setCreateFee(2 * 10 ** 15);
      setTaxCreateFee(4 * 10 ** 15);
    }
    setContext({ ...context, value: { ...defaultValue } });
  }, [chainId, account]);

  useEffect(() => {
    const interval = setInterval(() => {
      setWobble(true);
      setTimeout(() => setWobble(false), 500); // Wobble duration
    }, 3500); // Interval between wobbles

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const state = {
    ...context,
    setValue: setValue,
  };

  const containerStyle = {
    width: '100%',
    padding: '20px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  };

  const contentStyle = {
    width: '100%',
    maxWidth: '1400px',
    background: '#0B0D21',
    borderRadius: '8px',
    padding: '20px',
  };

  const headerContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
  };

  const logoStyle = {
    width: '35px',
    height: '35px',
    marginRight: '15px',
    marginLeft: '20px',
    marginBottom: '0px',
  };

  const headerTextStyle = {
    display: 'flex',
    flexDirection: 'column',
  };

  const headerStyle = {
    color: '#fff',
    fontSize: '20px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '600',
    textAlign: 'left',
  };

  const subHeaderStyle = {
    textAlign: 'left',
    color: '#7B91B0',
    fontSize: '16px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '400',
    marginBottom: '0px',
  };

  const fairLaunchStyle = {
    marginTop: '20px',
    marginLeft: '20px',
    padding: '20px',
    background: '#111432',
    borderRadius: '8px',
    border: '1px solid #075985',
    fontSize: '14px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '400',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '97%',
    maxWidth: '1400px',
  };

  const fairLaunchIconStyle = {
    width: '40px',
    height: '40px',
    marginRight: '10px',
    marginLeft: '10px',
  };

  const fairLaunchTextStyle = {
    color: '#7B91B0',
    fontSize: '14px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '400',
    lineHeight: '25px',
    wordWrap: 'break-word',
  };

  const fairLaunchHighlightStyle = {
    color: '#6EE7B7',
    fontSize: '15px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '400',
    lineHeight: '25px',
    wordWrap: 'break-word',
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
  };

  const buttonContainerStyle = {
    marginLeft: 'auto', // Pushes the button to the right side
  };

  const buttonStyle = {
    width: '100%',
    height: '100%',
    paddingLeft: 45.33,
    paddingRight: 45.33,
    paddingTop: 11.33,
    paddingBottom: 11.33,
    background: 'linear-gradient(90deg, #6700FF 0%, #21A595 100%)', // Updated gradient colors
    boxShadow: '1.9710144996643066px 18.724637985229492px 30.550724029541016px rgba(0, 0, 0, 0.50)',
    borderRadius: 12.32,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 4.93,
    display: 'inline-flex',
    cursor: 'pointer',
  };

  const buttonTextStyle = {
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 1)', // Changed text color to white
    fontSize: 11.83,
    fontFamily: 'Quicksand',
    fontWeight: '700',
    wordWrap: 'break-word',
  };

  const createButtonStyle = {
    width: '400px', 
    height: '50px',
    background: '#2F6EFF',
    borderRadius: '8px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: '600',
    border: 'none',
    cursor: 'pointer',
    transition: 'background 0.2s',
    marginTop: '24px',
    padding: '0 32px', 
    display: 'block', 
    margin: '24px auto 0', 
  };

  // Mobile-specific styles
  const mobileContainerStyle = {
    ...containerStyle,
    padding: '10px',
  };

  const mobileContentStyle = {
    ...contentStyle,
    padding: '10px',
  };

  const mobileHeaderContainerStyle = {
    ...headerContainerStyle,
    flexDirection: 'column',
    textAlign: 'left',
    alignItems: 'flex-start',
  };

  const mobileHeaderTextStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const mobileHeaderStyle = {
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
  };

  const mobileSubHeaderStyle = {
    fontSize: '14px',
    marginTop: '5px',
    color: '#7B91B0',
    textAlign: 'left',
  };

  const mobileLogoStyle = {
    marginRight: '10px',
    width: '25px',
    height: '25px',
  };

  const mobileFairLaunchStyle = {
    ...fairLaunchStyle,
    padding: '10px',
    width: '100%',
  };

  const mobileFairLaunchTextStyle = {
    fontSize: '12px',
  };

  const mobileButtonStyle = {
    ...buttonStyle,
    padding: '10px',
    fontSize: '10px',
  };

  const mobileCreateButtonStyle = {
    ...createButtonStyle,
    width: '100%',
    height: 'auto',
  };

  return (
    <Context.Provider value={state}>
      <React.Fragment>
        <div style={isMobile ? mobileContainerStyle : containerStyle}>
          <div style={isMobile ? mobileContentStyle : contentStyle}>
            <div style={isMobile ? mobileHeaderContainerStyle : headerContainerStyle}>
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'row' : 'row' }}>
                <img src={CreateTokenIcon} alt="Coin Logo" style={isMobile ? mobileLogoStyle : logoStyle} />
                <div style={isMobile ? mobileHeaderTextStyle : headerTextStyle}>
                  <div style={isMobile ? mobileHeaderStyle : headerStyle}>Create a Token</div>
                  {!isMobile && (
                    <div style={subHeaderStyle}>Zero Code Knowledge Required. Complete in 60 Seconds!</div>
                  )}
                </div>
              </div>
              {isMobile && (
                <div style={mobileSubHeaderStyle}>Zero Code Knowledge. Complete in 60 Seconds!</div>
              )}
              {!isMobile && (
                <div style={buttonContainerStyle} onClick={() => navigate('/bonding-token-sale')}>
                  <div className={wobble ? "wobble-animation" : ""} style={buttonStyle}>
                    <div style={buttonTextStyle}>CREATE A PUMP SALE HERE</div>
                  </div>
                </div>
              )}
            </div>
            <div className="tab-content mt-3" id="myTabContent">
              <div className={`tab-pane fade ${tokenType === "Standard" ? 'show active' : ''}`} id="standard" role="tabpanel" aria-labelledby="standard-tab">
                {tokenType === "Standard" && <StandardToken createFee={createFee} taxCreateFee={taxCreateFee} tokenType={tokenType} />}
              </div>
              <div className={`tab-pane fade ${tokenType === "Tax" ? 'show active' : ''}`} id="tax" role="tabpanel" aria-labelledby="tax-tab">
                {tokenType === "Tax" && <StandardToken createFee={createFee} taxCreateFee={taxCreateFee} tokenType={tokenType} />}
              </div>
              <div className={`tab-pane fade ${tokenType === "Advanced" ? 'show active' : ''}`} id="advanced" role="tabpanel" aria-labelledby="tax-tab">
                {tokenType === "Advanced" && <AdvancedToken createFee={advancedCreateFee} />}
              </div>
            </div>
            {!isMobile && (
              <div style={fairLaunchStyle}>
                <img src={launchpadIcon2} alt="Launchpad Icon" style={fairLaunchIconStyle} />
                <div style={fairLaunchTextStyle}>
                  After creating your token you can use our&nbsp;
                  <span style={fairLaunchHighlightStyle} onClick={() => navigate('/fairlaunch')}>
                    Fair Launch app
                  </span>
                  &nbsp;to fund your liquidity pool and project. The automated, decentralized process ensures safety and efficiency. Add & lock liquidity, list on a V2 or V3 DEX, view charts, and start trading. All from just $7.
                </div>
                <img src={launchpadIcon2} alt="Launchpad Icon" style={fairLaunchIconStyle} />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    </Context.Provider>
  );
}