import React from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { useEffect, useState } from "react";
import { AiOutlineMenu } from 'react-icons/ai';
import { injected, walletconnect, coinbaseWallet } from "../hooks/connectors";
import Modal from "react-bootstrap/Modal";
import { trimAddress } from "../hooks/constant";
import { supportNetwork, netOrder } from "../hooks/network";
import useEagerConnect from "../hooks/useWeb3";
import { toast } from "react-toastify";
//import solanaLogo from '../images/solanalogo.png';
import base from '../images/base.png';
import bnb from '../images/bnb.png';
import linea from '../images/linea.png';
import defiLaunchLogo from '../images/logo.png';
import networkButtonsImage from '../images/Networkbuttons.svg';
import DiamondIcon from "../images/DiamondIconBottom.svg";
import ETHprice from "../images/ETHprice.svg";
import { useNavigate } from "react-router-dom";

import BuyDefiButton from './BuyDefiButton';

const NetworkTooltip = ({ children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleTouchStart = (e) => {
    e.preventDefault();
    setShowTooltip(true);
  };

  const handleTouchEnd = () => {
    setTimeout(() => {
      setShowTooltip(false);
    }, 2000);
  };

  return (
    <div 
      className="tooltip-container"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      style={{ display: 'inline-block', position: 'relative' }}
    >
      {children}
      {showTooltip && (
  <div style={{
    position: 'absolute',
    top: '100%', // Position it directly below
    left: '-20px', // Adjust left position
    backgroundColor: '#161A42',
    color: 'white',
    padding: '8px 12px',
    borderRadius: '8px',
    fontSize: '12px',
    width: '220px', // Increased width to fit text
    textAlign: 'center',
    zIndex: 1000,
    border: '1px solid #4DFFFFFF',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    pointerEvents: 'none',
    marginTop: '5px' // Add some spacing from the button
  }}>
    Change L2 Networks by clicking your Wallet Button or select Solana in the Main Menu.
  </div>
)}
    </div>
  );
};

export const Connect = function ({ setOpen }) {
  const context = useWeb3React();
  const { connector, account, activate, deactivate, chainId, active, error } = context;
  const [show, setShow] = useState(false);
  const [networkshow, setNetworkshow] = useState(false);
  const [activatingConnector, setActivatingConnector] = useState();
  const [ethPrice, setEthPrice] = useState(null);
  const [bnbPrice, setBnbPrice] = useState(null);
  useEagerConnect();
  const navigate = useNavigate();

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, chainId]);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=ethereum,binancecoin&vs_currencies=usd");
        const data = await response.json();
        setEthPrice(data.ethereum.usd);
        setBnbPrice(data.binancecoin.usd);
      } catch (error) {
        console.error("Error fetching prices:", error);
      }
    };

    fetchPrices();
    const interval = setInterval(fetchPrices, 60000); // Fetch prices every 60 seconds

    return () => clearInterval(interval);
  }, []);

  function getErrorMessage(error) {
    if (error instanceof NoEthereumProviderError) {
      return "Metamask not detected";
    }
    if (error instanceof UnsupportedChainIdError) {
      return (
        <span
          className="btn-text"
          onClick={() => switchNetwork(supportNetwork["default"].chainId)}
        >
          Wrong Network
        </span>
      );
    }

    deactivate(injected);
  }

  const activating = (connection) => connection === activatingConnector;
  const connected = (connection) => connection === connector;

  const switchNetwork = (networkid) => {
    try {
      window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${networkid.toString(16)}` }],
      }).catch((e) => {
        console.log("errr", e);
        toast.error(e.message + ' to wallet');
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getLogosInOrder = () => {
    const logos = [
      { src: linea, alt: 'Linea Logo', id: 'linea' },
      { src: bnb, alt: 'BNB', id: 'bnb', height: '28px', width: '28px' },
      { src: base, alt: 'Base Coin', id: 'base' }
    ];

    if (chainId) {
      const selectedNetwork = supportNetwork[chainId];
      const selectedIndex = logos.findIndex(logo => logo.id === selectedNetwork.id);
      if (selectedIndex > -1) {
        const selectedLogo = logos.splice(selectedIndex, 1)[0];
        logos.unshift(selectedLogo);
      }
    }

    return logos;
  };

  const handleFavoritesClick = () => {
    if (window.innerWidth > 768) { // Only on desktop
      navigate("/favorites");
    }
  };

  return (
    <React.Fragment>
      <div className="connect-wrapper" style={{ marginBottom: '5px' }}>
        <div className="left-group">
          <div className="hamburger-menu">
            <AiOutlineMenu onClick={() => setOpen(true)} />
          </div>
          <div className="networks-container">
            <NetworkTooltip>
              <img src={networkButtonsImage} alt="Network Buttons" />
            </NetworkTooltip>

            <BuyDefiButton /> 


            <div className="diamond-icon-container">
              <img src={DiamondIcon} alt="Favorites" className="diamond-icon" onClick={handleFavoritesClick} />
            </div>
          </div>
        </div>
        <div className="logo-container">
          <img src={defiLaunchLogo} alt="Defi Launch" className="defi-launch-logo" />
        </div>
        <div className="d-flex align-items-center connect-button-container">
          <w3m-button />
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)} size="ms" aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton={true}>
          <Modal.Title>Connect to a wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <button className="btn btn-dark modal-btn-connect" onClick={() => { activate(injected); setShow(false); }}>
                  <div className="div-modal-btn">
                    <img src={require("../images/metamask.svg").default} alt="Meta-mask-Im" className="modal-img" />
                    <div className="text-modal-line">Metamask</div>
                  </div>
                </button>
              </div>
              <div className="col-12">
                <button className="btn btn-dark modal-btn-connect" onClick={() => { activate(walletconnect); setShow(false); }}>
                  <div className="div-modal-btn">
                    <img src={require("../images/walletconnect.svg").default} alt="walletConnect" className="modal-img" />
                    <div className="text-modal-line">WalletConnect</div>
                  </div>
                </button>
              </div>
              <div className="col-12">
                <button className="btn btn-dark modal-btn-connect" onClick={() => { activate(coinbaseWallet); setShow(false); }}>
                  <div className="div-modal-btn">
                    <img src={require("../images/coinbase.svg").default} alt="coinbase" className="modal-img" />
                    <div className="text-modal-line">Coinbase</div>
                  </div>
                </button>
              </div>
              <div className="col-12">
                <button className="btn btn-dark modal-btn-connect" onClick={() => { activate(injected); setShow(false); }}>
                  <div className="div-modal-btn">
                    <img src={require("../images/trustwallet.svg").default} alt="coinbase" className="modal-img" />
                    <div className="text-modal-line">TrustWallet</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={networkshow} onHide={() => setNetworkshow(false)} aria-labelledby="example-modal-sizes-title-lg" size="ms">
        <Modal.Header closeButton>
          <Modal.Title>Select a Network</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              {netOrder.map(function (key) {
                if (key === "default") return <React.Fragment key={key}></React.Fragment>;
                return (
                  <div className="col-12" key={key}>
                    <button className="btn btn-dark modal-btn-connect" onClick={() => { switchNetwork(supportNetwork[key].chainId); setNetworkshow(false); }}>
                      <div className="div-modal-btn">
                        <img src={supportNetwork[key].image} alt="Meta-mask-Im" className="modal-img" />
                        <div className="text-modal-line">{supportNetwork[key].name}</div>
                      </div>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <style jsx>{`


.networks-container img[alt="Network Buttons"] {
  height: 35px; /* Default size for desktop */
  width: auto;
}

.connect-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  position: relative;
  margin-bottom: 5px;
}

.left-group {
  display: flex;
  align-items: center;
}

.hamburger-menu {
  cursor: pointer;
  font-size: 24px;
  margin-right: 10px;
  display: none; /* Hidden by default for desktop */
}

.diamond-icon-container {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-left: 15px;
}

.diamond-icon {
  width: 23px;
  height: 25px;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 1px;
}

.networks-container {
  display: flex;
  align-items: center;
}

.solana-button {
  background: linear-gradient(180deg, #161A42 0%, #161A42 0%);
  border-radius: 10px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  cursor: pointer;
  padding: 5px;
  margin-left: 15px;
}

.solana-button img {
  width: 20px;
  height: 20px;
}

.logos-container {
  background: linear-gradient(180deg, #161A42 0%, #161A42 0%);
  border-radius: 10px;
  height: 40px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
  overflow: hidden;
  margin-right: 20px;
}

.overlap-group {
  position: relative;
  height: 30px;
  width: 60px;
  display: flex;
  align-items: center;
}

.overlap-group img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
  width: 30px;
}

.logo-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  z-index: 1;
}

.defi-launch-logo {
  height: 40px;
}

.connect-button-container {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  z-index: 2;
}

.connect-button {
  background-color: #161A42;
  color: #FFFFFF;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: normal;
  border-radius: 8px;
  height: 45px;
  box-shadow: 0 0 5px 5px rgba(140, 213, 105, 0.5);
  border: 1.5px solid #4DFFFFFF;
  margin-right: 1px;
}

@media (max-width: 768px) {
  .connect-wrapper {
    justify-content: space-between;
  }

  .hamburger-menu {
    display: block; /* Show hamburger on mobile */
    font-size: 20px;
  }

    .networks-container {
    transform: scale(1); /* Makes the network button 1.8 times larger */
    transform-origin: left center; /* Keeps it aligned from the left */
    margin-left: 0px; /* Add some spacing after the hamburger menu */
  }

  .networks-container img[alt="Network Buttons"] {
    height: 45px;
    width: auto;
    display: block;
  }

  .diamond-icon-container {
    display: none;
  }

  .solana-button, .logos-container {
    height: 35px;
  }

  .logos-container {
    width: 60px;
  }

  .connect-button-container {
    justify-content: flex-end;
    flex-grow: 1;
    padding-right: 0;
  }

  .connect-button {
    padding: 8px 12px;
    font-size: 12px;
    margin-right: 0;
  }

  .logo-container {
    position: static;
    transform: none;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .defi-launch-logo {
    height: 30px;
  }

  .solana-button, .logos-container {
    display: none;
  }

  .network-buttons-mobile {
    display: block;
  }

  .diamond-icon {
    display: none;
  }

  .price-widget {
    display: none;
  }
}

@media (min-width: 769px) {
  .hamburger-menu {
    display: none;
  }

  .connect-button {
    padding: 10px 14px;
  }

  .logo-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .network-buttons-mobile {
    display: none;
  }

  .diamond-icon {
    display: block;
  }

  .price-widget {
    display: flex;
  }
}
      `}</style>
    </React.Fragment>
  );
};

export default Connect;