// This file holds the initial, default values for context
// Note: it's good practice to specify defaults here,
//  but in our case they are overwritten by the values
//  within AppContextProvider

export const defaultValue = {
  name: "",
  symbol: "",
  decimals: "",
  buyFee: "0",
  sellFee: "0",
  supply: "",
  rewardAddr: "",
  minDividends: "",
  marketingWallet: "",
  marketingFee: "",
  rewardFee: "",
  liquidityFee: "",
  buybackFee: "",
  reflectionFee: "",
  yieldFee: "",
  charityAddr: "",
  charityFee: "",
  buyTax: "0",
  sellTax: "0",
  taxReceiver: "",
  maxTransaction: "0",
  maxWallet: "0",
  buyBurnFee: "0",
  sellBurnFee: "0",
  liquidityBuyFee: "0",
  liquiditySellFee: "0",
  buyRewards: "0",
  sellRewards: "0"
};

const defaultContext = {
  value: defaultValue,
  setValue: () => { },
  handleInput: () => { },
};

export default defaultContext;
