import React, { useState, useContext, useEffect } from "react";
import Lock from "./Lock";
import Context from "./context/Context";
import { defaultValue } from "./context/defaults";
import { useAccount, useChainId } from "wagmi";
import LockIcon from '../../images/LockIcon.svg';  // You'll need to add this icon

export default function MainLock() {
  const appContext = useContext(Context);
  const [context, setContext] = useState(appContext);
  const chainId = useChainId();
  const { address: account } = useAccount();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const setValue = (value) => {
    setContext({ ...context, value });
  };

  useEffect(() => {
    setContext({ ...context, value: { ...defaultValue } });
  }, [chainId, account]);

  const state = {
    ...context,
    setValue: setValue,
  };

  const containerStyle = {
    width: '100%',
    maxWidth: '1300px',
    margin: '0 auto',
    padding: '20px',
  };

  const headerContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
    marginTop: '24px',
  };

  const leftHeaderStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  };

  const logoStyle = {
    width: '35px',
    height: '35px',
  };

  const headerStyle = {
    color: '#fff',
    fontSize: '24px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '600',
    margin: '0',
  };

  const subHeaderStyle = {
    color: '#7B91B0',
    fontSize: '14px',
    fontFamily: 'Quicksand, sans-serif',
    marginTop: '4px',
    margin: '0',
  };

  const contentStyle = {
    background: '#111432',
    border: '1px solid #075985',
    borderRadius: '8px',
    overflow: 'hidden',
  };

  // Mobile styles
  const mobileContainerStyle = {
    width: '100%',
    padding: '16px',
  };

  const mobileHeaderContainerStyle = {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    marginBottom: '20px',
  };

  const mobileHeaderStyle = {
    fontSize: '20px',
  };

  const mobileSubHeaderStyle = {
    fontSize: '12px',
    marginTop: '4px',
  };

  const mobileContentStyle = {
    padding: 0,
  };

  return (
    <Context.Provider value={state}>
      <div style={isMobile ? mobileContainerStyle : containerStyle}>
        <div style={isMobile ? mobileHeaderContainerStyle : headerContainerStyle}>
          <div style={leftHeaderStyle}>
            <img src={LockIcon} alt="Lock Icon" style={logoStyle} />
            <div>
              <h1 style={isMobile ? { ...headerStyle, ...mobileHeaderStyle } : headerStyle}>
                Create Lock
              </h1>
              <p style={isMobile ? { ...subHeaderStyle, ...mobileSubHeaderStyle } : subHeaderStyle}>
                Lock your tokens or LP tokens with our time-release vault system
              </p>
            </div>
          </div>
        </div>

        <div style={isMobile ? { ...contentStyle, ...mobileContentStyle } : contentStyle}>
          <Lock />
        </div>
      </div>
    </Context.Provider>
  );
}