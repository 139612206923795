import React from 'react';
import { useNavigate } from 'react-router-dom';

const StakingPoolCard = ({ pool }) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundColor: '#161A42',
        borderRadius: '12px',
        padding: '16px',
        color: '#FFFFFF',
        cursor: 'pointer',
        transition: 'transform 0.2s ease',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
      onClick={() => navigate(`/staking/${pool.id}`)} // Navigates to the detailed staking page
    >
      {/* Banner Image */}
      <div
        style={{
          width: '100%',
          height: '100px',
          backgroundImage: `url(${pool.bannerUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
        }}
      >
        {/* Logo Image - Positioned over the banner */}
        <div
          style={{
            width: '50px',
            height: '50px',
            backgroundImage: `url(${pool.logoUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '50%',
            border: '2px solid #FFFFFF',
            position: 'relative',
            top: '70px',
            left: '16px',
            zIndex: 1,
          }}
        />
      </div>

      {/* Card Content */}
      <div style={{ paddingTop: '36px' }}> {/* Adjust padding for logo overlap */}
        {/* Live Status and Time Remaining */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <span style={{ color: '#22C55E', fontSize: '0.9rem' }}>{pool.liveStatus ? 'LIVE' : 'INACTIVE'}</span>
          <span style={{ color: '#7B91B0', fontSize: '0.9rem' }}>{pool.timeRemaining}</span>
        </div>

        {/* Pool Name and Description */}
        <h2 style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '8px' }}>{pool.name}</h2>
        <p style={{ color: '#7B91B0', fontSize: '0.9rem', marginBottom: '12px' }}>
          Stake {pool.tokenSymbol} to Earn {pool.tokenSymbol}
        </p>

        {/* Pool Statistics */}
        <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.9rem', marginBottom: '8px' }}>
          <div>
            <span>Total Staked</span>
            <div style={{ fontWeight: 'bold' }}>{pool.totalStaked}</div>
          </div>
          <div>
            <span>Hardcap</span>
            <div style={{ fontWeight: 'bold' }}>{pool.hardCap}</div>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.9rem' }}>
          <div>
            <span>APY</span>
            <div style={{ fontWeight: 'bold', color: '#22C55E' }}>{pool.apy}</div>
          </div>
          <div>
            <span>Total Stakers</span>
            <div style={{ fontWeight: 'bold' }}>{pool.totalStakers}</div>
          </div>
        </div>

        {/* Footer Icons */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
          <div>
            <button style={{ background: 'none', border: 'none', color: '#7B91B0', cursor: 'pointer' }}>🌐</button>
            <button style={{ background: 'none', border: 'none', color: '#7B91B0', cursor: 'pointer', marginLeft: '8px' }}>💬</button>
          </div>
          <div>
            <button style={{ background: 'none', border: 'none', color: '#7B91B0', cursor: 'pointer' }}>🤍</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingPoolCard;
