import React, { useState } from 'react';
import LoaderComponent from './LoaderComponent';
import { formatPrice, getWeb3Contract } from '../hooks/contractHelper';
import tokenAbi from '../json/token.json';
import LPAbi from '../json/lpabi.json';
import { getWeb3 } from '../hooks/connectors';
import { useWeb3React } from "@web3-react/core";
import { contract } from '../hooks/constant';
import { MulticallContractWeb3 } from '../hooks/useContracts';

export default function LockInput(props) {
    const context = useWeb3React();
    const { chainId, account } = context;
    let { setValue, value } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const styles = {
        container: {
            marginBottom: '24px',
        },
        label: {
            color: '#34D399',
            fontSize: '14px',
            fontWeight: '400',
            marginBottom: '8px',
            display: 'flex',
            alignItems: 'center',
        },
        input: {
            width: '100%',
            height: '50px',
            background: '#161A42',
            borderRadius: '8px',
            border: 'none',
            padding: '0 16px',
            color: '#F1F8FD',
            fontSize: '14px',
            outline: 'none',
        },
        asterisk: {
            color: '#EF4444',
            marginLeft: '6px',
            fontSize: '14px',
        },
        error: {
            color: '#EF4444',
            fontSize: '12px',
            marginTop: '4px',
        },
        infoRow: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '12px 0',
            borderBottom: '1px solid rgba(128, 128, 128, 0.3)',
        },
        infoLabel: {
            color: '#98A7B5',
            fontSize: '14px',
            fontWeight: '400',
        },
        infoValue: {
            color: '#F1F8FD',
            fontSize: '14px',
            fontWeight: '400',
        }
    };

    const handleInput = async (e) => {
        try {
            // e.preventDefault();
            let web3 = getWeb3(chainId);
            let checkAddress = await web3.utils.isAddress(e.target.value);
            if (checkAddress) {
                let checkSumaddress = await web3.utils.toChecksumAddress(e.target.value)
                let isCode = await web3.eth.getCode(e.target.value);
                if (!checkSumaddress || isCode === '0x') {
                    setError('Please enter valid address !');
                    return false;
                }
                let lpContract = await getWeb3Contract(LPAbi, e.target.value, chainId);
                let token0 = await lpContract.methods.token0().call();
                let token1 = await lpContract.methods.token1().call();

                let token0Contract = await getWeb3Contract(tokenAbi, token0, chainId);
                let token1Contract = await getWeb3Contract(tokenAbi, token1, chainId);

                const mc = MulticallContractWeb3(chainId);
                const data = await mc.aggregate([
                    token0Contract.methods.symbol(),
                    token1Contract.methods.symbol(),
                    lpContract.methods.balanceOf(account),
                    lpContract.methods.decimals(),
                    lpContract.methods.allowance(
                        account,
                        contract[chainId] ? contract[chainId].lockAddress : contract['default'].lockAddress
                    )
                ]);

                if (data[0] !== '' && data[1] !== '') {
                    setValue({ ...value, "tokenAddress": e.target.value, tokenName: "", "islp": 1, Pair: `${data[0]}/${data[1]}`, "balance": data[2] / Math.pow(10, data[3]), tokenDecimal: data[3], tokenSymbol: "", isApprove: (parseFloat(data[4] / Math.pow(10, data[3])) > 100000000000) ? true : false });
                    setError('');
                }
                else {
                    setValue({ ...value, "tokenAddress": e.target.value, tokenName: "", "islp": 2, tokenDecimal: "", tokenSymbol: "", balance: 0, isApprove: false });
                    setError('Please enter valid address !');
                }

            }
            else {
                setError('Please enter valid address!!')
                setValue({ ...value, "tokenAddress": e.target.value, tokenName: "", "islp": 2, tokenDecimal: "", tokenSymbol: "", balance: 0, isApprove: false });
            }
        }
        catch (err) {
            console.log(err.message);
            try {
                let tokenContract = await getWeb3Contract(tokenAbi, e.target.value, chainId);
                const mc = MulticallContractWeb3(chainId);
                const tokendata = await mc.aggregate([
                    tokenContract.methods.name(),
                    tokenContract.methods.decimals(),
                    tokenContract.methods.symbol(),
                    tokenContract.methods.balanceOf(account),
                    tokenContract.methods.allowance(
                        account,
                        contract[chainId] ? contract[chainId].lockAddress : contract['default'].lockAddress
                    )
                ]);

                if (tokendata[1] > 0) {
                    let isApprove = tokendata[4] ? (tokendata[4] / Math.pow(10, tokendata[1]) > 10000000000000000000) ? true : false : false;
                    setValue({ ...value, "tokenAddress": e.target.value, "islp": 2, "balance": tokendata[3] / Math.pow(10, tokendata[1]), tokenName: tokendata[0], tokenDecimal: tokendata[1], tokenSymbol: tokendata[2], isApprove });
                    setError('');
                }
                else {
                    setValue({ ...value, "tokenAddress": e.target.value, tokenName: "", "islp": 2, tokenDecimal: "", tokenSymbol: "", isApprove: false, balance: 0 });
                    setError('Please enter valid address !');
                }
            }
            catch (err) {
                setValue({ ...value, "tokenAddress": e.target.value, tokenName: "", "islp": 2, tokenDecimal: "", tokenSymbol: "", balance: 0, isApprove: false });
                setError('Please enter valid address !');
                console.log(err.message);
            }

        }
        setIsLoading((prev) => !prev);
        return false;
    }

    return (
        <div style={styles.container}>
            <div>
                <label style={styles.label}>
                    Token or LP Token address
                    <span style={styles.asterisk}>*</span>
                </label>
                <input 
                    style={styles.input}
                    type="text"
                    placeholder="Enter token or lp address"
                    onChange={(e) => { handleInput(e); setIsLoading(true); }}
                />
                {error && <div style={styles.error}>{error}</div>}
            </div>

            {isLoading ? (
                <LoaderComponent status={isLoading} />
            ) : (
                value.islp === 1 ? (
                    <>
                        <div style={styles.infoRow}>
                            <span style={styles.infoLabel}>Pair</span>
                            <span style={styles.infoValue}>{value.Pair}</span>
                        </div>
                        <div style={styles.infoRow}>
                            <span style={styles.infoLabel}>Balance</span>
                            <span style={styles.infoValue}>{formatPrice(value.balance)}</span>
                        </div>
                    </>
                ) : (
                    value.tokenName !== '' && value.tokenDecimal && value.tokenSymbol && value.tokenAddress && (
                        <>
                            <div style={styles.infoRow}>
                                <span style={styles.infoLabel}>Name</span>
                                <span style={styles.infoValue}>{value.tokenName}</span>
                            </div>
                            <div style={styles.infoRow}>
                                <span style={styles.infoLabel}>Symbol</span>
                                <span style={styles.infoValue}>{value.tokenSymbol}</span>
                            </div>
                            <div style={styles.infoRow}>
                                <span style={styles.infoLabel}>Decimal</span>
                                <span style={styles.infoValue}>{value.tokenDecimal}</span>
                            </div>
                            <div style={styles.infoRow}>
                                <span style={styles.infoLabel}>Balance</span>
                                <span style={styles.infoValue}>{formatPrice(value.balance)}</span>
                            </div>
                        </>
                    )
                )
            )}
        </div>
    );
}