import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useAccount } from 'wagmi';
import styled from 'styled-components';
import EmojiPicker from 'emoji-picker-react';

// Import Degen images
import Degen1 from '../images/Degen1.png';
import Degen2 from '../images/Degen2.png';
import Degen3 from '../images/Degen3.png';
import Degen4 from '../images/Degen4.png';
import Degen5 from '../images/Degen5.png';
import Degen6 from '../images/Degen6.png';
import Degen7 from '../images/Degen7.png';
import Degen8 from '../images/Degen8.png';
import Degen9 from '../images/Degen9.png';
import Degen10 from '../images/Degen10.png';
import Degen11 from '../images/Degen11.png';
import Degen12 from '../images/Degen12.png';
import Degen13 from '../images/Degen13.png';
import Degen14 from '../images/Degen14.png';
import Degen15 from '../images/Degen15.png';
import Degen16 from '../images/Degen16.png';
import Degen17 from '../images/Degen17.png';
import Degen18 from '../images/Degen18.png';
import Degen19 from '../images/Degen19.png';
import Degen20 from '../images/Degen20.png';

const degenImages = [
  Degen1, Degen2, Degen3, Degen4, Degen5,
  Degen6, Degen7, Degen8, Degen9, Degen10,
  Degen11, Degen12, Degen13, Degen14, Degen15,
  Degen16, Degen17, Degen18, Degen19, Degen20
];

const adminAddresses = [
  '0x7308BD2ea2bc85B44e44c64B30c14C189F75531d'.toLowerCase(),
  '0x7384e9D49283D1195D1e8FD6515D72d622e65a70'.toLowerCase()
];

const trimAddress = (address) => `${address.slice(0, 6)}...${address.slice(-4)}`;

const hashCode = (str = '') => {
  return str.split('').reduce((prevHash, currVal) =>
    ((prevHash << 5) - prevHash) + currVal.charCodeAt(0), 0);
};

const getAvatarForAddress = (address = '') => {
  const hash = Math.abs(hashCode(address));
  const imageIndex = hash % degenImages.length;
  const dgnNumber = `DGN${(hash % 1000).toString().padStart(3, '0')}`;
  return { avatar: degenImages[imageIndex], dgnNumber };
};

const WalletAddress = styled.span`
  font-size: 10px;  
  color: #888;  
  font-weight: bold;
  margin-bottom: 1px; /* Add space below the wallet address */

`; 

const ChatContainer = styled.div`
  border: 2px solid #075985;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  position: relative;
  background-color: #0B0D21;
  padding-right: 10px; /* Added padding to the right */
  margin-right: 5px; /* Add this line to give margin on the right side */
  margin-left: 5px; /* Add this line to give margin on the right side */


`;

const ChatHeader = styled.div`
  background-color: #0B0D21; 
  background: linear-gradient(90deg, #1FA2FF 0%, #12D8FA 50%, #A6FFCB 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent; 
  text-align: center;
  padding: 10px; 
  font-size: 14px;
  font-weight: bold;
  border-bottom: 2px solid #075985;
  border-radius: 15px 15px 0 0; /* Updated to have rounded corners on both left and right sides */
  margin-bottom: 15px;
`;

const ChatWrapper = styled.div`
  background-color: #0B0D21;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  
`;

const ChatMessages = styled.div`
  flex-grow: 1;
  padding: 10px;
  padding-bottom: 70px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px) { /* Apply styles for mobile devices only */
    padding-bottom: 140px; /* Adjust padding to ensure all messages are visible above the input area */
  }
`;

const ChatInputContainer = styled.div`
  padding: 10px;
  background-color: #0B0D21;
  border-top: 1px solid #1a1f40;
  display: flex;
  align-items: center;
  gap: 10px;
  position: sticky;
  bottom: 0;
  z-index: 10;
  border-radius: 0 0 15px 15px; /* Updated to have rounded corners on both left and right sides */

  @media (max-width: 768px) { /* Apply styles for mobile devices only */
    padding-bottom: 75px; /* Adjust padding to ensure the input is above the navigation bar */
    margin-bottom: -60px; /* Add margin to push the input up above the navigation bar */
  }
`;

const ChatInput = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 8px;
  background-color: #161A42;
  color: white;
`;

const SendButton = styled.button`
  padding: 2px 15px;
  background-color: #3461FF;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

const EmojiButton = styled.button`
  padding: 10px;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
`;

const EmojiPickerWrapper = styled.div`
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
`;

const Message = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding-left: 10px; /* Add padding to the left */
`;

const AvatarContainer = styled.div`
  flex-shrink: 0;
  position: relative;
  margin-top: 8px;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid #F2C94C;
`;

const DgnNumber = styled.div`
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #F2C94C;
  color: #000;
  padding: 2px 4px;
  border-radius: 10px;
  font-size: 10px;
  font-weight: bold;
  white-space: nowrap;
`;

const MessageContent = styled.div`
  flex-grow: 1;
  max-width: calc(100% - 60px); /* Adjusted to allow more space for the chat bubbles */
  display: flex;
  flex-direction: column;
`;

const MessageBubble = styled.div`
  background-color: #1a1f40;
  color: white;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  box-sizing: border-box;
  position: relative;
`;

const DeleteButton = styled.button`
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 0.3px 2px;  
  font-size: 7px;   
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 7px;
  display: ${(props) => (props.isAdmin ? 'block' : 'none')};
`;

const SystemMessage = styled.div`
  text-align: center;
  color: #ff0000;
  font-size: 12px;
  margin-top: 10px;
`;

const Chat = () => {
  const { address, isConnected } = useAccount();
  const isAdmin = adminAddresses.includes(address?.toLowerCase());
  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem('chatMessages');
    const loadedMessages = savedMessages ? JSON.parse(savedMessages) : [];

    return loadedMessages.map(msg => ({
      ...msg,
      ...getAvatarForAddress(msg.address || ''),
    }));
  });
  
  const [newMessage, setNewMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [systemMessage, setSystemMessage] = useState(null); 
  const chatMessagesRef = useRef(null);

  const { avatar, dgnNumber } = useMemo(() => getAvatarForAddress(address || ''), [address]);

  useEffect(() => {
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    chatMessagesRef.current?.scrollTo(0, chatMessagesRef.current.scrollHeight);
  }, [messages, systemMessage]);

  const blockedWords = ['badword1', 'badword2', 'badword3']; 
  const containsBlockedWords = (message) => {
    return blockedWords.some(word => message.toLowerCase().includes(word));
  };

  const [lastMessageTime, setLastMessageTime] = useState(null);

  const handleSendMessage = (e) => {
    e.preventDefault();
    const now = Date.now();

    if (lastMessageTime && now - lastMessageTime < 5000) { 
      setSystemMessage("You're sending messages too fast!");
      return;
    }

    if (containsBlockedWords(newMessage)) {
      setSystemMessage('Your message contains inappropriate content.');
      setNewMessage(''); 
      return;
    }

    if (newMessage.trim()) {
      setMessages([...messages, { address, message: newMessage, avatar, dgnNumber }]);
      setNewMessage('');
      setShowEmojiPicker(false);
      setLastMessageTime(now);
      setSystemMessage(null); 
    }
  };

  const handleEmojiSelect = (emojiObject) => {
    setNewMessage((prevMessage) => prevMessage + emojiObject.emoji);
  };

  const handleDeleteMessage = (index) => {
    setMessages(messages.filter((_, i) => i !== index));
  };

  return (
    <ChatContainer>
      <ChatHeader>
        Degen Chat
      </ChatHeader>
      <ChatWrapper>
        <ChatMessages ref={chatMessagesRef}>
          {messages.map((msg, idx) => (
            <Message key={idx}>
              <AvatarContainer>
                <Avatar src={msg.avatar} alt="avatar" />
                <DgnNumber>{msg.dgnNumber}</DgnNumber>
              </AvatarContainer>
              <MessageContent>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <WalletAddress>
                    {adminAddresses.includes(msg.address.toLowerCase()) ? "Mod" : trimAddress(msg.address)}
                  </WalletAddress>
                </div>
                <MessageBubble>
                  {msg.message}
                  {isAdmin && (
                    <DeleteButton
                      isAdmin={isAdmin}
                      onClick={() => handleDeleteMessage(idx)}
                    >
                      X
                    </DeleteButton>
                  )}
                </MessageBubble>
              </MessageContent>
            </Message>
          ))}
          {systemMessage && <SystemMessage>{systemMessage}</SystemMessage>}
        </ChatMessages>
        {isConnected && (
          <ChatInputContainer>
            <form onSubmit={handleSendMessage} style={{ display: 'flex', width: '100%' }}>
              <ChatInput
                type="text"
                value={newMessage}
                maxLength={200}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Your Message"
              />
              <EmojiButton type="button" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>😊</EmojiButton>
              <SendButton type="submit">Send</SendButton>
            </form>
            {showEmojiPicker && (
              <EmojiPickerWrapper>
                <EmojiPicker
                  theme="dark"
                  onEmojiClick={handleEmojiSelect}
                />
              </EmojiPickerWrapper>
            )}
          </ChatInputContainer>
        )}
      </ChatWrapper>
    </ChatContainer>
  );
};

export default Chat;
