import { useHoldersStats } from './helper/useStats';
import { trimAddress } from '../../../hooks/constant';

export default function Holders(props) {
  const holdersStats = useHoldersStats(props.holders, props.token);
  const devWalletAddress = props.governance ? props.governance.toLowerCase() : ''; // Use governance as the developer wallet address and ensure it's lowercase

  // Sort holders by holding percentage in descending order
  const sortedHoldersStats = holdersStats.sort((a, b) => b.holding - a.holding);

  return (
    <div className="custom-holders-list" style={{ marginTop: "40px", backgroundColor: '#0c0e17', borderRadius: '8px', overflow: 'hidden' }}>
      <div className="custom-holders-scroll" style={{
        height: '218px', // Height for 5 items
        overflowY: 'auto'
      }}>
        {sortedHoldersStats.map((holder, index) => (
          <div key={index} className="custom-holder-item" style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            padding: '10px 15px', 
            backgroundColor: index % 2 === 0 ? '#212760' : '#131638', 
            borderRadius: '4px', 
            marginBottom: '5px',
            height: '48px'
          }}>
            <span className="custom-holder-user" style={{ color: '#c7cbd6', fontFamily: 'Quicksand, Helvetica', fontWeight: '500', fontSize: '14px', flex: 1 }}>
              {holder.holder && holder.holder.toLowerCase() === devWalletAddress ? (
                <>
                  {trimAddress(holder.holder)} 
                  <span className="dev-wallet-label" style={{ 
                    color: '#ff6ec7', 
                    fontFamily: 'Quicksand, Helvetica', 
                    fontSize: '14px', 
                    fontWeight: '500', 
                    backgroundColor: '#2d133d', 
                    padding: '2px 6px', 
                    borderRadius: '4px', 
                    marginLeft: '8px'
                  }}>
                    DEV
                  </span>
                </>
              ) : (
                trimAddress(holder.holder)
              )}
            </span>
            <span className="custom-holder-holding" style={{ color: '#ffffff', fontFamily: 'Quicksand, Helvetica', fontWeight: '500', fontSize: '14px', flex: 1, textAlign: 'right' }}>
              {holder.holding}%
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

<style>
  {`
    .custom-holders-list {
      border: 1px solid #29314f;
      border-radius: 8px;
      background-color: #0c0e17;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
      margin-top: 20px;
    }
    .custom-holders-scroll {
      scrollbar-width: thin;
      scrollbar-color: #6604FD transparent;
    }
    .custom-holders-scroll::-webkit-scrollbar {
      width: 6px;
    }
    .custom-holders-scroll::-webkit-scrollbar-track {
      background: transparent;
    }
    .custom-holders-scroll::-webkit-scrollbar-thumb {
      background-color: #6604FD;
      border-radius: 3px;
    }
    .custom-holder-item {
      transition: background-color 0.3s ease;
    }
    .custom-holder-item:hover {
      background-color: rgba(41, 49, 79, 0.7);
    }
    .custom-holder-user,
    .custom-holder-holding {
      font-size: 14px;
      font-family: 'Quicksand, Helvetica';
      font-weight: 500;
    }
    .dev-wallet-label {
      color: #ff6ec7;
      background-color: #2d133d;
      padding: 2px 6px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      font-family: 'Quicksand, Helvetica';
    }
  `}
</style>