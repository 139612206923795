import React, { useContext, useState } from "react";
import { formatPrice, mulDecimal } from "../../../hooks/contractHelper";
import Context from "./context/Context";
import Button from "react-bootstrap-button-loader";
import { contract } from "../../../hooks/constant";
import poolFactoryAbi from "../../../json/poolfactory.json";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { parseEther } from "ethers/lib/utils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copyIcon from "../../../images/icon.png";
import { coinArray } from "../../../hooks/constant";
import { useAccount, useChainId } from "wagmi";
import { config } from "../../../config";
import { writeContract, waitForTransactionReceipt } from "@wagmi/core";
import { feesSetting } from "./context/defaults"; 
import dateFormat from "dateformat";


export default function Step5() {
  const { value, btnPrevStep } = useContext(Context);
  const chainId = useChainId();
  const { address: account } = useAccount();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Styles remain the same...
  const styles = {
    container: {
      padding: '32px',
      background: '#0D1021',
      borderRadius: '16px',
      border: '1px solid rgba(255, 255, 255, 0.1)',
    },
    header: {
      fontSize: '24px',
      color: '#fff',
      fontWeight: '600',
      marginBottom: '32px',
      textAlign: 'center',
    },
    section: {
      marginBottom: '24px',
    },
    sectionTitle: {
      fontSize: '18px',
      color: '#34D399',
      fontWeight: '500',
      marginBottom: '16px',
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '12px 0',
      borderBottom: '1px solid rgba(128, 128, 128, 0.3)',
    },
    label: {
      fontSize: '14px',
      fontWeight: 400,
      color: '#98A7B5',
      margin: 0,
      lineHeight: '1.2',
    },
    value: {
      fontSize: '14px',
      fontWeight: 400,
      color: '#F1F8FD',
      margin: 0,
      lineHeight: '1.2',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: '16px',
      marginTop: '32px',
    },
    button: {
      height: '50px',
      padding: '0 32px',
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: '600',
      border: 'none',
      cursor: 'pointer',
      minWidth: '150px',
      background: '#2F6EFF',
      color: '#fff',
    },
    backButton: {
      background: '#374151',
    },
    importantNote: {
      color: '#FFB000',
      fontSize: '14px',
      marginTop: '24px',
      lineHeight: '1.6',
      padding: '16px',
      background: 'rgba(255, 176, 0, 0.1)',
      borderRadius: '8px',
    },
    copyIcon: {
      cursor: 'pointer',
      width: '16px',
      height: '16px',
      marginLeft: '8px',
      verticalAlign: 'middle',
    },
  };

  const handleCreateSale = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (account) {
        if (chainId) {
          let para = [
            [
              value.tokenAddress,
              value.routerVersion === '3'
                ? contract[chainId]?.positionManagerV3addr
                : value.routerVersion === '1'
                ? contract[chainId]?.uniswapV3PositionManager
                : contract[chainId]?.routeraddress,
              account,
              value.currencyAddress,
            ],
            [
              mulDecimal(value.softcap, 18).toString(),
              mulDecimal(value.saletoken, value.tokenDecimal).toString(),
            ],
            [
              Math.floor(new Date(value.starttime).getTime() / 1000.0),
              Math.floor(new Date(value.endtime).getTime() / 1000.0),
              value.llockup * 60 * 60 * 24,
            ],
            [feesSetting[value.feesType].token, feesSetting[value.feesType].bnb],
            [
              value.audit === true ? "1" : "2",
              value.kyc === true ? "1" : "2",
              value.routerVersion,
            ],
            [value.liquidity, value.refund],
            `${value.logourl}$#$${value.bannerurl}$#$${value.website}$#$$#$${value.facebook}$#$${value.twitter}$#$${value.github}$#$${value.telegram}$#$${value.instagram}$#$${value.discord}$#$${value.reddit}$#$${value.youtube}$#$${value.brief}`,
            [value.usermail, value.auditlink, value.kyclink],
          ];

          let poolfactoryAddress = contract[chainId]?.poolfactory || contract["default"].poolfactory;

          let feesCal = parseFloat(value.totalCost);
          if (value.routerVersion === 3 && (chainId === 56 || chainId === 97)) {
            feesCal = 0.03;
          } else if (value.routerVersion === 2 && (chainId === 56 || chainId === 97)) {
            feesCal = 0.015;
          }

          try {
            const result = await writeContract(config, {
              abi: poolFactoryAbi,
              address: poolfactoryAddress,
              functionName: 'createFairSale',
              args: para,
              value: parseEther(feesCal.toFixed(8).toString()),
            });

            toast.promise(new Promise(resolve => setTimeout(resolve, 10000)), {
              pending: "Hold on tight Ser! The blockchain wizards are processing your Fair Launch! 🚀",
            });

            const interval = setInterval(async () => {
              const response = await waitForTransactionReceipt(config, { hash: result });
              if (response) {
                clearInterval(interval);
                if (response.status === 'success') {
                  toast.success("Congratulations you filthy Degen! Your Fair Launch is live! 🎉");
                  navigate(`/fairlaunch-details/${response.logs[0]?.address}?chainid=${chainId}`);
                } else {
                  toast.error("Transaction failed. Please try again.");
                }
                setLoading(false);
              }
            }, 5000);
          } catch (err) {
            toast.error('Error creating Fair Launch. Try again.');
            setLoading(false);
          }
        } else {
          toast.error("Wrong network selected. Switch networks and try again.");
          setLoading(false);
        }
      } else {
        toast.error("Connect your wallet.");
        setLoading(false);
      }
    } catch (err) {
      toast.error(err.reason || err.message);
      setLoading(false);
    }
  };

  return (
    <div style={styles.container} role="tabpanel" id="step5">
      <h4 style={styles.header}>Review Your Information</h4>

      {/* Token Information Section */}
      <div style={styles.section}>
        <label style={styles.sectionTitle}>
          Token Info
          <span style={styles.asterisk}>*</span>
        </label>
        <div style={styles.row}>
          <p style={styles.label}>Name</p>
          <p style={styles.value}>{value.tokenName}</p>
        </div>
        <div style={styles.row}>
          <p style={styles.label}>Symbol</p>
          <p style={styles.value}>{value.tokenSymbol}</p>
        </div>
        <div style={styles.row}>
          <p style={styles.label}>Decimal</p>
          <p style={styles.value}>{value.tokenDecimal}</p>
        </div>

      </div>

      {/* Sale Information Section */}
      <div style={styles.section}>
        <label style={styles.sectionTitle}>
          Sale Info
          <span style={styles.asterisk}>*</span>
        </label>
        <div style={styles.row}>
          <p style={styles.label}>Listing</p>
          <p style={styles.value}>
            {value.routerVersion == "3" ? "PancakeSwap V3" : value.routerVersion == '1' ? "UniSwap V3" : 'PancakeSwap V2'}
          </p>
        </div>
        <div style={styles.row}>
          <p style={styles.label}>Tokens Selling</p>
          <p style={styles.value}>
            {value.saletoken} {value.tokenSymbol}
          </p>
        </div>
        <div style={styles.row}>
          <p style={styles.label}>Tokens + LP</p>
          <p style={styles.value}>
            {formatPrice(value.totaltoken)} {value.tokenSymbol}
          </p>
        </div>
        <div style={styles.row}>
          <p style={styles.label}>Softcap</p>
          <p style={styles.value}>
            {value.softcap} {coinArray[chainId]}
          </p>
        </div>
        <div style={styles.row}>
          <p style={styles.label}>Unsold</p>
          <p style={styles.value}>
            {value.refund === "0" ? "Refund" : "Burn"}
          </p>
        </div>
        <div style={styles.row}>
          <p style={styles.label}>Liquidity</p>
          <p style={styles.value}>{value.liquidity}%</p>
        </div>
        <div style={styles.row}>
          <p style={styles.label}>Start</p>
          <p style={styles.value}>
            {dateFormat(value.starttime, "yyyy-mm-dd HH:MM")}
          </p>
        </div>
        <div style={styles.row}>
          <p style={styles.label}>End</p>
          <p style={styles.value}>
            {dateFormat(value.endtime, "yyyy-mm-dd HH:MM")}
          </p>
        </div>
        <div style={styles.row}>
          <p style={styles.label}>LP Unlock</p>
          <p style={styles.value}>{value.llockup} days</p>
        </div>
      </div>

      {/* Action Buttons */}
      <div style={styles.buttonContainer}>
        <button
          type="button"
          style={{ ...styles.button, ...styles.backButton }}
          onClick={btnPrevStep}
        >
          Back
        </button>
        <Button
          loading={loading}
          variant="none"
          type="button"
          style={styles.button}
          onClick={handleCreateSale}
        >
          Submit
        </Button>
      </div>

      {/* Important Note */}
      <div style={styles.importantNote}>
        IMPORTANT: Please exclude our launchpad contract address{" "}
        {contract[chainId]?.poolfactory || contract["default"].poolfactory}
        <CopyToClipboard
          text={contract[chainId]?.poolfactory || contract["default"].poolfactory}
          onCopy={() => toast.success("Contract address copied!")}
        >
          <img 
            src={copyIcon} 
            alt="Copy" 
            style={styles.copyIcon}
          />
        </CopyToClipboard>{" "}
        from fees, rewards, and max tx amount. If not, the sale will not finalize or go live.
      </div>
    </div>
  );
}

