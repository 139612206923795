import React, { useContext, useState, useEffect } from "react";
import TokenInput from "../../../component/TokenInput";
import Context from "./context/Context";
import { toast } from "react-toastify";
import { contract } from "../../../hooks/constant";
import tokenAbi from "../../../json/token.json";
import { parseEther } from "@ethersproject/units";
import { currencies } from "../../../hooks/currencies";
import { coinArray } from "../../../hooks/constant";
import { useAccount, useChainId } from "wagmi";
import { config } from "../../../config";
import { writeContract, waitForTransactionReceipt } from "@wagmi/core";
import { ethers } from "ethers";

const Step1 = () => {
  const chainId = useChainId();
  const { address: account } = useAccount();
  const { value, btnNextStep, setValue } = useContext(Context);
  const appContext = useContext(Context);
  const [context, setContext] = useState(appContext);
  const [loading, setLoading] = useState(false);
  const [symbol, setSymbol] = useState(value.currencyTSymbol);
  const [totalCost, setTotalCost] = useState(0.008);
  const [showTooltip, setShowTooltip] = useState("");
  const [isLoadingToken, setIsLoadingToken] = useState(false);

  useEffect(() => {
    setValue({ ...value, routerVersion: "2" });
  }, []);

  useEffect(() => {
    if (chainId) {
      updateTotalCost();
    }
  }, [chainId, value.routerVersion]);

  useEffect(() => {
    if (value.tokenAddress) {
      fetchTokenData();
    }
  }, [value.tokenAddress]);

  const updateTotalCost = () => {
    const costs = {
      56: { 2: 0.015, 3: 0.03 },
      97: { 2: 0.015, 3: 0.03 },
      default: { 1: 0.009, 2: 0.003, 3: 0.008 }
    };

    const chainCosts = costs[chainId] || costs.default;
    const newCost = chainCosts[value.routerVersion] || 0.008;
    
    setContext({ ...context, value: { ...value, totalCost: newCost } });
    setValue({ ...value, totalCost: newCost });
    setTotalCost(newCost);
  };

  const fetchTokenData = async () => {
    try {
      setIsLoadingToken(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const tokenContract = new ethers.Contract(value.tokenAddress, tokenAbi, provider);

      const tokenName = await tokenContract.name();
      const tokenSymbol = await tokenContract.symbol();
      const tokenDecimals = await tokenContract.decimals();

      setValue({
        ...value,
        tokenName,
        tokenSymbol,
        tokenDecimal: tokenDecimals,
      });
    } catch (error) {
      toast.error("Invalid token address or unable to fetch token data");
      setValue({
        ...value,
        tokenName: "",
        tokenSymbol: "",
        tokenDecimal: "",
      });
    } finally {
      setIsLoadingToken(false);
    }
  };

  const currencyList = currencies[chainId] || currencies.default;

  const firstStepSubmit = (e) => {
    if (
      value.tokenName !== "" ||
      value.tokenDecimal !== "" ||
      value.tokenSymbol !== ""
    ) {
      btnNextStep(e);
    } else {
      toast.error("Please enter a valid token address");
    }
  };

  const handleApprove = async (e) => {
    e.preventDefault();
    if (!account) {
      toast.error("Bro, connect your wallet! 👍");
      return;
    }

    if (!chainId) {
      toast.error("Please select Smart Chain Network!");
      return;
    }

    try {
      if (!value.tokenAddress) {
        toast.error("Come on ser! Enter valid token address!");
        return;
      }

      setLoading(true);
      const poolfactoryAddress = contract[chainId]?.poolfactory || contract.default.poolfactory;
      const amount = parseEther("1000000000000000000000000000").toString();

      const result = await writeContract(config, {
        abi: tokenAbi,
        address: value.tokenAddress,
        functionName: 'approve',
        args: [poolfactoryAddress, amount]
      });

      toast.promise(new Promise(resolve => setTimeout(resolve, 10000)), {
        pending: "Degenerates Assemble! Just a Moment for Contract Blessings..."
      });

      const response = await waitForTransactionReceipt(config, { hash: result });
      
      if (response?.status === 'success') {
        toast.success("All Clear! Contract Confirmed – Head to the Next Step!");
        setValue({ ...value, isApprove: true });
      } else {
        toast.error("Oops! Something went wrong, ser!");
      }
    } catch (err) {
      toast.error(err.reason || err.message);
    } finally {
      setLoading(false);
    }
  };

  const tooltipStyle = {
    position: 'relative',
    display: 'inline-block',
    marginLeft: '8px',
  };

  const tooltipTextStyle = {
    visibility: 'hidden',
    width: '240px',
    backgroundColor: '#1F2937',
    color: '#7B91B0',
    textAlign: 'left',
    padding: '8px 12px',
    borderRadius: '6px',
    position: 'absolute',
    zIndex: 1,
    bottom: '150%',
    left: '50%',
    transform: 'translateX(-50%)',
    opacity: 0,
    transition: 'all 0.2s ease',
    fontSize: '12px',
    fontFamily: 'Quicksand, sans-serif',
    border: '1px solid #374151',
  };

  const inputContainerStyle = {
    marginBottom: '24px',
  };

  const labelStyle = {
    color: '#34D399',
    fontSize: '14px',
    fontWeight: '400',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
  };

  const inputStyle = {
    width: '100%',
    height: '50px',
    background: '#161A42',
    borderRadius: '8px',
    border: 'none',
    padding: '0 16px',
    color: '#fff',
    fontSize: '14px',
    outline: 'none',
  };

  const selectStyle = {
    width: '100%',
    height: '50px',
    background: '#161A42',
    borderRadius: '8px',
    border: 'none',
    padding: '0 16px',
    color: '#fff',
    fontSize: '14px',
    outline: 'none',
    appearance: 'none',
    backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'16\' height=\'16\' viewBox=\'0 0 16 16\'%3E%3Cpath fill=\'%23ffffff\' d=\'M4 6l4 4 4-4\'/%3E%3C/svg%3E")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 16px center',
  };

  const radioContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '12px',
  };

  const radioStyle = {
    accentColor: '#34D399',
    width: '16px',
    height: '16px',
    marginRight: '8px',
  };

  const radioLabelStyle = {
    color: '#fff',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  };

  const buttonStyle = {
    width: '400px', 
    height: '50px',
    background: '#2F6EFF',
    borderRadius: '8px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: '600',
    border: 'none',
    cursor: 'pointer',
    transition: 'background 0.2s',
    marginTop: '24px',
    padding: '0 32px', 
    display: 'block', 
    margin: '0px auto 0', 
  };

  const InfoIcon = ({ tooltip }) => (
    <div 
      style={tooltipStyle} 
      onMouseEnter={() => setShowTooltip(tooltip)}
      onMouseLeave={() => setShowTooltip("")}
    >
      <span style={{ color: '#7B91B0', cursor: 'pointer', fontSize: '14px' }}>ⓘ</span>
      <div style={{
        ...tooltipTextStyle,
        visibility: showTooltip === tooltip ? 'visible' : 'hidden',
        opacity: showTooltip === tooltip ? 1 : 0,
      }}>
        {tooltip}
      </div>
    </div>
  );

  return (
    <div style={{ padding: '32px' }}>
      <div style={inputContainerStyle}>
        <label style={labelStyle}>
          Token Address
          <span style={{ color: '#EF4444', marginLeft: '6px' }}>*</span>
        </label>
        <input
          style={inputStyle}
          type="text"
          placeholder="Ex: 0xCE035af21b4697101Aaf12C261bc6C79b0e38272"
          value={value.tokenAddress}
          onChange={(e) => setValue({ ...value, tokenAddress: e.target.value })}
        />
        
        {/* Token Data Display */}
        {value.tokenAddress && (
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '16px',
            marginTop: '16px',
            marginBottom: '24px'
          }}>
            <div style={{
              background: '#1F2937',
              padding: '12px',
              borderRadius: '8px',
              border: '1px solid #374151'
            }}>
              <div style={{
                color: '#7B91B0',
                fontSize: '12px',
                marginBottom: '4px'
              }}>Name</div>
              <div style={{
                color: '#fff',
                fontSize: '14px',
                fontWeight: '500'
              }}>
                {isLoadingToken ? 'Loading...' : value.tokenName || 'N/A'}
              </div>
            </div>
            <div style={{
              background: '#1F2937',
              padding: '12px',
              borderRadius: '8px',
              border: '1px solid #374151'
            }}>
              <div style={{
                color: '#7B91B0',
                fontSize: '12px',
                marginBottom: '4px'
              }}>Symbol</div>
              <div style={{
                color: '#fff',
                fontSize: '14px',
                fontWeight: '500'
              }}>
                {isLoadingToken ? 'Loading...' : value.tokenSymbol || 'N/A'}
              </div>
            </div>
            <div style={{
              background: '#1F2937',
              padding: '12px',
              borderRadius: '8px',
              border: '1px solid #374151'
            }}>
              <div style={{
                color: '#7B91B0',
                fontSize: '12px',
                marginBottom: '4px'
              }}>Decimals</div>
              <div style={{
                color: '#fff',
                fontSize: '14px',
                fontWeight: '500'
              }}>
                {isLoadingToken ? 'Loading...' : value.tokenDecimal?.toString() || 'N/A'}
              </div>
            </div>
          </div>
        )}
      </div>

      <div style={inputContainerStyle}>
        <label style={labelStyle}>
          Select DEX
          <InfoIcon tooltip="Select the DEX you want your Token launched on. V3 is the latest DEX upgrades with more liquidity and cheaper fees for users." />
        </label>
        <select
          style={selectStyle}
          value={value.routerVersion}
          onChange={(e) => setValue({ ...value, routerVersion: e.target.value })}
        >
          {chainId === 8453 && <option value="1">Uniswap V3</option>}
          <option value="2">PancakeSwap V2</option>
          <option value="3">PancakeSwap V3</option>
        </select>
      </div>

      <div style={inputContainerStyle}>
        <label style={labelStyle}>Currency</label>
        <div>
          {currencyList.map((currency) => (
            <div key={currency.address} style={radioContainerStyle}>
              <input
                type="radio"
                style={radioStyle}
                name="currency"
                value={currency.address}
                checked={value.currencyAddress === currency.address}
                onChange={(e) => {
                  setValue({
                    ...value,
                    currencyTSymbol: currency.symbol,
                    currencyAddress: currency.address,
                  });
                  setSymbol(currency.symbol);
                }}
              />
              <span style={radioLabelStyle}>{currency.symbol}</span>
            </div>
          ))}
        </div>
      </div>

      <div style={inputContainerStyle}>
        <label style={labelStyle}>
          Fee
          <InfoIcon tooltip="+ 5% raised from sale" />
        </label>
        <div style={radioContainerStyle}>
          <input
            type="radio"
            style={radioStyle}
            name="fees"
            value="1"
            checked={value.feesType === "1"}
            onChange={(e) => setValue({ ...value, feesType: e.target.value })}
          />
          <span style={radioLabelStyle}>{context.value.totalCost} {coinArray[chainId]}</span>
        </div>
      </div>

      <button
        style={buttonStyle}
        onClick={value.isApprove ? btnNextStep : handleApprove}
        disabled={loading || value.ispoolExist}
      >
        {loading ? 'Processing...' : value.isApprove ? 'Continue to next step' : 'Approve'}
      </button>

      {value.ispoolExist && (
        <div style={{ textAlign: 'center', color: '#EF4444', marginTop: '16px' }}>
          This pool already exists, ser.
        </div>
      )}
    </div>
  );
};

  export default Step1;


