import React, { useState } from "react";
import { HashLoader } from "react-spinners";
import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";
import { useMyTokenLockStats } from "./helper/useStats";
import { useChainId } from "wagmi";
import { ArrowLeft, ArrowRight } from 'lucide-react';
import templateImg from '../../images/template.jpg'; // Make sure this path matches your project structure

export default function TokenLockList() {
  const [updater, setUpdater] = useState({
    page: 0,
    pageSize: 10,
    loading: true,
  });
  const stats = useMyTokenLockStats(updater);
  const context = useWeb3React();
  const chainId = useChainId();
  
  // State for managing failed image loads
  const [failedImages, setFailedImages] = useState({});

  const handleImageError = (tokenAddress) => {
    setFailedImages(prev => ({
      ...prev,
      [tokenAddress]: true
    }));
  };

  return (
    <div className="container mt-3">
      <div style={{ 
        backgroundColor: 'transparent', 
        border: 'none', 
        boxShadow: 'none', 
        marginBottom: '20px' 
      }}>
        <div style={{ textAlign: 'center', paddingBottom: '0' }}>
          <h2 style={{ 
            fontSize: '24px', 
            color: '#fff', 
            fontWeight: '600',
            marginBottom: '32px'
          }}>
            My Locked Token List
          </h2>
        </div>
        <div>
          {/* Navigation Buttons */}
          <div style={{ 
            display: 'flex', 
            gap: '10px', 
            justifyContent: 'center',
            marginBottom: '32px'
          }}>
            <Link
              to="/token-locked"
              style={{
                background: 'rgba(255, 255, 255, 0.1)',
                padding: '12px 24px',
                borderRadius: '8px',
                color: '#98A7B5',
                textDecoration: 'none',
                fontSize: '16px',
                fontWeight: '500',
                transition: 'all 0.3s'
              }}
            >
              Token Lock List
            </Link>
            <Link
              to="/my-token-lock"
              style={{
                background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
                padding: '12px 24px',
                borderRadius: '8px',
                color: '#FFFFFF',
                textDecoration: 'none',
                fontSize: '16px',
                fontWeight: '500',
                transition: 'opacity 0.3s'
              }}
            >
              My Token Lock List
            </Link>
          </div>

          {/* Main Content Card */}
          <div style={{ 
            padding: "2px", 
            borderRadius: "12px", 
            background: "linear-gradient(90deg, rgba(54, 97, 255, 0.5) 0%, rgba(132, 84, 235, 0.5) 100%)" 
          }}>
            <div style={{ 
              backgroundColor: "var(--bcgame-sk-woodsmoke, #111415)", 
              borderRadius: "12px", 
              padding: '20px' 
            }}>
              <div className="table-responsive">
                <table style={{ 
                  width: '100%', 
                  borderCollapse: 'separate', 
                  borderSpacing: '0 8px' 
                }}>
                  <thead>
                    <tr>
                      <th style={{ 
                        color: '#98A7B5', 
                        padding: '12px', 
                        fontSize: '14px', 
                        fontWeight: '500',
                        textAlign: 'left' 
                      }}>No</th>
                      <th style={{ 
                        color: '#98A7B5', 
                        padding: '12px', 
                        fontSize: '14px', 
                        fontWeight: '500',
                        textAlign: 'left' 
                      }}>Token</th>
                      <th style={{ 
                        color: '#98A7B5', 
                        padding: '12px', 
                        fontSize: '14px', 
                        fontWeight: '500',
                        textAlign: 'left' 
                      }}>Amount</th>
                      <th style={{ 
                        color: '#98A7B5', 
                        padding: '12px', 
                        fontSize: '14px', 
                        fontWeight: '500',
                        textAlign: 'left' 
                      }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stats.loading ? (
                      <tr>
                        <td colSpan="4" style={{ textAlign: 'center', padding: '40px 0' }}>
                          <HashLoader
                            size="50"
                            color="#3461FF"
                            cssOverride={{ display: 'inline-block' }}
                          />
                        </td>
                      </tr>
                    ) : stats.tokenList.length > 0 ? (
                      stats.tokenList
                        .slice(0)
                        .reverse()
                        .map((rowdata, index) => (
                          <tr key={index} style={{
                            background: 'rgba(255, 255, 255, 0.05)',
                            borderRadius: '8px',
                            transition: 'all 0.3s'
                          }}>
                            <td style={{
                              padding: '16px',
                              color: '#F1F8FD',
                              fontSize: '14px',
                              borderRadius: '8px 0 0 8px'
                            }}>
                              {stats.page > 0
                                ? stats.page * stats.pageSize + 1 + index
                                : index + 1}
                            </td>
                            <td style={{
                              padding: '16px',
                              color: '#F1F8FD',
                              fontSize: '14px'
                            }}>
                              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                <div style={{
                                  position: 'relative',
                                  width: '36px',
                                  height: '36px',
                                  padding: '2px',
                                  display: 'inline-block',
                                  borderRadius: '50%',
                                  background: 'linear-gradient(180deg, #6604FD 0%, #01F4A4 100%)',
                                  boxSizing: 'border-box'
                                }}>
                                  <div style={{
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'var(--bcgame-sk-woodsmoke, #111415)',
                                  }}>
                                    <img
                                      src={failedImages[rowdata.token] ? templateImg : rowdata.logourl || templateImg}
                                      onError={() => handleImageError(rowdata.token)}
                                      alt={rowdata.name}
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        borderRadius: '50%',
                                      }}
                                    />
                                  </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <span style={{ fontWeight: '500' }}>{rowdata.name}</span>
                                  <span style={{ color: '#98A7B5', fontSize: '12px' }}>
                                    {rowdata.symbol}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td style={{
                              padding: '16px',
                              color: '#F1F8FD',
                              fontSize: '14px'
                            }}>
                              {rowdata.amount
                                ? (rowdata.amount / Math.pow(10, rowdata.decimals)).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 6
                                  })
                                : "0"}{" "}
                              {rowdata.symbol}
                            </td>
                            <td style={{
                              padding: '16px',
                              borderRadius: '0 8px 8px 0'
                            }}>
                              <Link
                                to={`/lock-details/${rowdata.token}${chainId ? `?chainid=${chainId}` : ""}`}
                                style={{
                                  background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
                                  padding: '8px 16px',
                                  borderRadius: '6px',
                                  color: '#FFFFFF',
                                  textDecoration: 'none',
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  display: 'inline-block',
                                  transition: 'opacity 0.3s'
                                }}
                                onMouseEnter={(e) => e.target.style.opacity = '0.9'}
                                onMouseLeave={(e) => e.target.style.opacity = '1'}
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td
                          colSpan="4"
                          style={{
                            textAlign: 'center',
                            padding: '40px',
                            color: '#98A7B5',
                            fontSize: '14px'
                          }}
                        >
                          No Record Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Pagination */}
              {(stats.page > 0 || Math.floor(stats.allNormalTokenLockedCount / stats.pageSize) > parseFloat(stats.page)) && (
                <div style={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  gap: '16px',
                  marginTop: '24px',
                  padding: '16px 0'
                }}>
                  {stats.page > 0 && (
                    <button
                      onClick={() => setUpdater({
                        page: stats.page - 1,
                        pageSize: stats.pageSize,
                        loading: true,
                      })}
                      style={{
                        background: 'rgba(255, 255, 255, 0.1)',
                        border: 'none',
                        padding: '8px 16px',
                        borderRadius: '6px',
                        color: '#FFFFFF',
                        fontSize: '14px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        transition: 'all 0.3s'
                      }}
                      onMouseEnter={(e) => e.target.style.background = 'rgba(255, 255, 255, 0.15)'}
                      onMouseLeave={(e) => e.target.style.background = 'rgba(255, 255, 255, 0.1)'}
                    >
                      <ArrowLeft size={16} />
                      Previous
                    </button>
                  )}
                  
                  {Math.floor(stats.allNormalTokenLockedCount / stats.pageSize) > parseFloat(stats.page) && (
                    <button
                      onClick={() => setUpdater({
                        page: stats.page + 1,
                        pageSize: stats.pageSize,
                        loading: true,
                      })}
                      style={{
                        background: 'rgba(255, 255, 255, 0.1)',
                        border: 'none',
                        padding: '8px 16px',
                        borderRadius: '6px',
                        color: '#FFFFFF',
                        fontSize: '14px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        transition: 'all 0.3s'
                      }}
                      onMouseEnter={(e) => e.target.style.background = 'rgba(255, 255, 255, 0.15)'}
                      onMouseLeave={(e) => e.target.style.background = 'rgba(255, 255, 255, 0.1)'}
                    >
                      Next
                      <ArrowRight size={16} />
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <style>
        {`
          @media (max-width: 768px) {
            .container {
              padding-left: 16px;
              padding-right: 16px;
            }
            
            table {
              font-size: 14px;
            }
            
            td, th {
              padding: 12px 8px;
            }
          }
          
          .table-responsive {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
          }
          
          tr:hover {
            background: rgba(255, 255, 255, 0.08) !important;
          }
        `}
      </style>
    </div>
  );
}