export const trimAddress = (addr) => {
  if (addr){
      return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
    }
  else {
    return addr
    }
};

export const coinArray = {
  56: "BNB",
  97: "TBNB",
  8453: "ETH",
  84532: "ETH",
  59144: "ETH",
  324: "ETH",
  300: "ETH",
};

export const convertDay = (mins) => {
    if (parseInt(mins) < 60)
        return mins + " mins";
    else if (parseInt(mins) < 1440)
        return Math.floor(mins/60) + " hrs " + (mins % 60) + " mins";
    else if (parseInt(mins) < 1440) {
        let days = Math.floor(mins/1440);
        let hours = Math.floor((mins - days * 1440) / 60);
        let minutes = mins % 60;
        return days + " days " + hours + " hours " +  minutes + " mins";
    }
}

export const contract = {

  //Update Contracts and Router on 324 aka zkSync. Currently copied from 8453 aka Base
  //BSC Mainnet chain
  56: {
    poolfactory: "0x9f61089F4816a83c1806792526bd11E7412c7AA2",
    poolmanager: "0x62b2E5e292a026bc796db1DFfD3B2Ff67F1468d8",
    fairmaster: '0x3f6b720FA409b623e66A70cBdBF507b1ce697112',
    bondingToken: "0x6AB6a2828B2BcBfa01323De702b2C453bA95f9f9",
    bondingPool: "0xb38a5B0D4bef977D0fdF7F7c2e425f6EB8B5f7C3",
    targetEthAmount: "4", //23 bnb
    marketCap: "69000",
    routeraddress: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    ethPriceFeed: "0x137924d7c36816e0dcaf016eb617cc2c92c05782",
    positionManagerV3addr: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364', // same with router address in our pool contract
    multicallAddress: "0x411Ed75d63bF5D9E15b942E7ceF5F897E9B63b5b",
    lockAddress: "0x28917d39DB0637c3a661c4271C74528e8a1cB0C7",
    routername: "PancakeSwap",
    feeReceiver: "0x7308BD2ea2bc85B44e44c64B30c14C189F75531d",
    dividendTracker: "0xA9d956d92432460a0FA8d2A27BE33859830705ec",
    airdrop: "0x61983Fb320eD69e004e8182Cf999f8a88f45Cc6d"
  },
  300: {
    poolfactory: "0xF82212918E3C5C893a91CbdF4aB7fE032Fb2c762",
    poolmanager: "0x7C0D0C99c9d83aEB96e9c214b88BC04A7D8C00A3",
    routeraddress: "0x8cFe327CEc66d1C090Dd72bd0FF11d690C33a2Eb",
    multicallAddress: "0x519d319dfe534bc91977c754f740604a284e3bca",
    lockAddress: "0x9F5FC3Ab7D9F930d66519F4C7d5632202e5E655B",
    routername: "PancakeSwap",
    feeReceiver: "0x7308BD2ea2bc85B44e44c64B30c14C189F75531d",
    dividendTracker: "0xA9d956d92432460a0FA8d2A27BE33859830705ec",
    airdrop: "0x401D52430e82B4635c51AB4b5667921BaF4EA4dA"
  },
  //zksync mainnet
  //weth address: 0x5aea5775959fbc2557cc8789bc1bf90a239d9a91
  //usdc/weth pool addr: 0x63edb9338d81c6d8219856548251bacf2a9b1830
  324: {
    poolfactory: "0xa899bB283fceB30CdeE577a84DCaF76B484E5eeD",
    poolmanager: "0x5a0d9Ea9793baaF8043ff00e524f6C15Da267954",
    fairmaster: '0x945415e2c6ABafCE19BA5510884ff835d95FE2b4',
    routeraddress: "0x5aEaF2883FBf30f3D62471154eDa3C0c1b05942d",
    multicallAddress: "0x739b419D81A075e6010e998d613C5B51500331bE",
    lockAddress: "0xe2a923C6F4e10BcFd72873a80eA4BAa13C95B18B",
    routername: "PancakeSwap",
    feeReceiver: "0x7308BD2ea2bc85B44e44c64B30c14C189F75531d",
    dividendTracker: "0xA9d956d92432460a0FA8d2A27BE33859830705ec",
    airdrop: "0x401D52430e82B4635c51AB4b5667921BaF4EA4dA"
  },

  // Linea Test
  59140: {
    poolfactory: "0x155f22A39B711d1A8b069BB5582bDD32d058bf2D",
    poolmanager: "0x0dFc4342FfD744EBB18aa86d34Ce105afeECB05f",
    multicallAddress: "0x915c2CA177de432dcAC06BDFf5566230528F8bA0",
    routername: "PancakeSwap",
    routeraddress: "0x8cFe327CEc66d1C090Dd72bd0FF11d690C33a2Eb",
    feeReceiver: "0x7308BD2ea2bc85B44e44c64B30c14C189F75531d",
    airdrop: "0x197DfDEB18F5Deb8DA86467078819a41D1726861"
      

  },
  // Linea Mainnet
  59144: {
    poolfactory: "0x327537E1833a123c256Bce8f6C6c707832873c90",
    poolmanager: "0xD083A3CfF134D75cEf854dB09b9398585ef29CB6",
    fairmaster: "0xcCF5Ba82cBe24A5713b14de2adC6acDFD4E6Ee18",
    bondingToken: "0x952937dBE51Cb6C5940709d0c2c102e8ca942D15",
    bondingPool: "0x0337ac82aF6FD6619Fa14551a5ad6814408D85f0",
    targetEthAmount: "1", //23 bnb
    marketCap: "69000",
    multicallAddress: "0x008e140dfb5d3ab380942068f14414ec98e060c4",
    lockAddress: "0x6693d9c281D69Df3d33E9071c78c587ba3944603",
    routername: "PancakeSwap",
    routeraddress: "0x8cFe327CEc66d1C090Dd72bd0FF11d690C33a2Eb",
    ethPriceFeed: "0x3c6Cd9Cc7c7a4c2Cf5a82734CD249D7D593354dA",
    positionManagerV3addr: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364', // same with router address in our pool contract
    feeReceiver: "0x7308BD2ea2bc85B44e44c64B30c14C189F75531d",
    airdrop: "0xb59459035d6B67Ecf2BBc623a79a13e4FE4870Da"
  },
  // Base Mainnet
  8453: {
    poolfactory: "0xe17E3F43a3D6D403aA56882992eA5FDd4222f22E",
    poolmanager: "0x36c5C0812D0e4D4aB4CFF740173A74Ad200d3E8b",
    fairmaster: '0xc14Ac5aC3e3809d7cB61C98f3171A3a21eF1Dbb3',
    bondingToken: "0x437F5b08411d36793033DBD8CB045E5eC7ddC57C",
    bondingPool: "0x598B973C4E0e695C20ED01258c089fB3c408A034",
    targetEthAmount: "1", 
    marketCap: "69000",
    routeraddress: "0x8cFe327CEc66d1C090Dd72bd0FF11d690C33a2Eb",
    ethPriceFeed: "0x71041dddad3595F9CEd3DcCFBe3D1F4b0a16Bb70",
    positionManagerV3addr: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364', // same with router address in our pool contract
    uniswapV3PositionManager: "0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1",
    multicallAddress: "0x519d319dfe534bc91977c754f740604a284e3bca",
    lockAddress: "0xe2a923C6F4e10BcFd72873a80eA4BAa13C95B18B",
    routername: "PancakeSwap",
    feeReceiver: "0x7308BD2ea2bc85B44e44c64B30c14C189F75531d",
    dividendTracker: "0xA9d956d92432460a0FA8d2A27BE33859830705ec",
    airdrop: "0x991E7de68699988136378Ba26D612216B8d8e72e"
  },
  // Base Sepolia testnet
  84532: {
    poolfactory: "0xc8A1431AAAb5395EF51a440b62719E1250114963",
    poolmanager: "0xBdCe60B7dEA2BBC4169BbDa872cCbd135B184b0e",
    fairmaster: "0x96F12571CDc084028a69732A93Aa4b7Ad00113D8",
    routeraddress: "0x8cFe327CEc66d1C090Dd72bd0FF11d690C33a2Eb",
    positionManagerV3addr: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    multicallAddress: "0xC6dcA82e0532968fA71a71a94dD275cE39F1C55B",
    lockAddress: "0x1611C1660cC0A59c6a7567Cc50f67A25C0b00bb8",
    routername: "Pancakeswap",
    feeReceiver: "0xb29a63Bbe11A6a8C9753fB54506D0d0696Ade46e",
    dividendTracker: "0xb29a63Bbe11A6a8C9753fB54506D0d0696Ade46e",
    airdrop: "0x9E3858e1af331111546360490f49e620Dc1FFC7a"
  },
  
  // bsc testnet
  97: {
    poolfactory: "0x155f22A39B711d1A8b069BB5582bDD32d058bf2D",
    poolmanager: "0x6270ac50aC0b3C63a9f8B1f152791e368B5e6984",
    fairmaster: "0x72332ce6F07201149f2C154520855C1dD65482B5",
    bondingToken: "0x13195eD8fA82dd05B9b5C343EaDf96959D74dc23",
    bondingPool: "0x78cD7BAe6b6d38206A2F9edED5307872Dc25c171",
    targetEthAmount: "4", //23 bnb
    marketCap: "69000", //69k
    routeraddress: "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3",
    ethPriceFeed: "0x1A26d803C2e796601794f8C5609549643832702C",
    positionManagerV3addr: "0x427bF5b37357632377eCbEC9de3626C71A5396c1",
    multicallAddress: "0x0dFc4342FfD744EBB18aa86d34Ce105afeECB05f",
    lockAddress: "0x915c2CA177de432dcAC06BDFf5566230528F8bA0",
    routername: "Pancakeswap",
    feeReceiver: "0x7308BD2ea2bc85B44e44c64B30c14C189F75531d",
    dividendTracker: "0x5c7f05a5fb26adeb304b8a8695aabc9b6e7ca037",
    airdrop: "0xA0a7f588865E995D5e57A40df1f4D02c2EDaB65A"
  },
  default: {
    poolfactory: "0x9f61089F4816a83c1806792526bd11E7412c7AA2",
    poolmanager: "0x62b2E5e292a026bc796db1DFfD3B2Ff67F1468d8",
    fairmaster: '0x3f6b720FA409b623e66A70cBdBF507b1ce697112',
    bondingToken: "0x6AB6a2828B2BcBfa01323De702b2C453bA95f9f9",
    bondingPool: "0xb38a5B0D4bef977D0fdF7F7c2e425f6EB8B5f7C3",
    targetEthAmount: "4", //23 bnb
    marketCap: "69000",
    routeraddress: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    ethPriceFeed: "0x137924d7c36816e0dcaf016eb617cc2c92c05782",
    positionManagerV3addr: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364', // same with router address in our pool contract
    multicallAddress: "0x411Ed75d63bF5D9E15b942E7ceF5F897E9B63b5b",
    lockAddress: "0x28917d39DB0637c3a661c4271C74528e8a1cB0C7",
    routername: "PancakeSwap",
    feeReceiver: "0x7308BD2ea2bc85B44e44c64B30c14C189F75531d",
    dividendTracker: "0xA9d956d92432460a0FA8d2A27BE33859830705ec",
    airdrop: "0x61983Fb320eD69e004e8182Cf999f8a88f45Cc6d"
   },
};

// BSC
// MultiCall:       0x6e5bb1a5ad6f68a8d7d6a5e47750ec15773d6042
// BabyToken:       0x5c7f05a5fb26adeb304b8a8695aabc9b6e7ca037
// PresalePool:     0x1b03d864c4a93ef2ae2748bc8949017825809d64
// PrivatePool:     0x368a017dcfa89f7a1db1c37b145791caf40cfd53  

// FairPool:        0x474959a744be23595de1d05648a71d5815472a8d
// TokenLocker:     0x654a68baf4a4dbab4af5548bbbed910af40078ad

// ProxyAdmin:      0xb0d5f7bdf5aec4f953cf956a09432cfd004e0e25

// PoolManager Im:  0xf912e9FdB436C25ABE3B400435990F992f677527     
  // Param: 0xae13d989dac2f0debff460ac112a837c89baa7cd, 0x337610d27c682E347C9cD60BD4b3b107C9d34dDd
  // 0x485cc955000000000000000000000000ae13d989dac2f0debff460ac112a837c89baa7cd000000000000000000000000337610d27c682e347c9cd60bd4b3b107c9d34ddd
// Upgradable:      0xc5027079d74def9644f605fe4b65adc5340585ef

// PoolFactory Im:  0x8dd81cfd8dee5e109804d196a722b9c018d9569a
  // Param:         
  // 0x1b03d864c4a93ef2ae2748bc8949017825809d64 
  // 0x368a017dcfa89f7a1db1c37b145791caf40cfd53 
  // 0xc5027079d74def9644f605fe4b65adc5340585ef
  // 0x474959a744be23595de1d05648a71d5815472a8d
  // 1
  // 1000000000000000
  // 2000000000000000
  // 1000000000000000
  // 1000000000000000
  // 3000000000000000
  // 1000
  // true

// Upgradable:      0xe90321bf32a9c1ee284cdd849ed4c37da940d54c

// 0x035a1d4b0000000000000000000000001b03d864c4a93ef2ae2748bc8949017825809d64000000000000000000000000368a017dcfa89f7a1db1c37b145791caf40cfd53000000000000000000000000c5027079d74def9644f605fe4b65adc5340585ef000000000000000000000000474959a744be23595de1d05648a71d5815472a8d000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000000000000000000000000038d7ea4c6800000000000000000000000000000000000000000000000000000071afd498d000000000000000000000000000000000000000000000000000000038d7ea4c6800000000000000000000000000000000000000000000000000000038d7ea4c68000000000000000000000000000000000000000000000000000000aa87bee53800000000000000000000000000000000000000000000000000000000000000003e80000000000000000000000000000000000000000000000000000000000000001

// @zappingpentester