import React, { useContext, useState } from "react";
import LockInput from "../../component/LockInput";
import Context from "./context/Context";
import { getWeb3 } from "../../hooks/connectors";
import { toast } from "react-toastify";
import { contract } from "../../hooks/constant";
import { getContract, mulDecimal } from "../../hooks/contractHelper";
import tokenAbi from "../../json/token.json";
import lockAbi from "../../json/lockabi.json";
import { parseEther } from "@ethersproject/units";
import Button from "react-bootstrap-button-loader";
import DatePicker from "react-datepicker";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copyIcon from "../../images/icon.png";
import { useAccount, useChainId } from "wagmi";
import { config } from "../../config";
import { writeContract, waitForTransactionReceipt } from "@wagmi/core";

export default function Lock() {
  const chainId = useChainId();
  const { address: account } = useAccount();
  const { value, setValue } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [lockloading, setLockLoading] = useState(false);
  const [error, setError] = useState({
    owner: "",
    title: "",
    amount: "",
    TGEDate: "",
    TGEPercent: "",
    Cycle: "",
    ReleasePercent: "",
  });

  // Updated styles to match Step1 and Step5
  const styles = {
    container: {
      padding: '32px',
      background: '#0B0E1F', // Darker background
      borderRadius: '16px',
      border: '1px solid rgba(255, 255, 255, 0.1)',
    },
    header: {
      fontSize: '24px',
      color: '#fff',
      fontWeight: '600',
      marginBottom: '32px',
      textAlign: 'center',
    },
    inputContainer: {
      marginBottom: '24px',
    },
    label: {
      color: '#34D399',
      fontSize: '14px',
      fontWeight: '400',
      marginBottom: '8px',
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      width: '100%',
      height: '50px',
      background: '#161A42',
      borderRadius: '8px',
      border: 'none',
      padding: '0 16px',
      color: '#F1F8FD',
      fontSize: '14px',
      outline: 'none',
    },
    checkbox: {
      accentColor: '#34D399',
      width: '16px',
      height: '16px',
      marginRight: '8px',
      cursor: 'pointer',
    },
    checkboxLabel: {
      color: '#98A7B5',
      fontSize: '14px',
      marginLeft: '8px',
      cursor: 'pointer',
    },
  
    asterisk: {
      color: '#EF4444',
      marginLeft: '6px',
      fontSize: '14px',
    },
    note: {
      marginTop: '24px',
      padding: '16px',
      background: 'rgba(34, 30, 25, 0.75)',
      borderRadius: '8px',
      border: '1px solid rgba(255, 176, 0, 0.2)',
    },
    noteText: {
      color: '#FFB000',
      fontSize: '14px',
      lineHeight: '1.6',
    },
    button: {
      height: '50px',
      padding: '0 32px',
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: '600',
      border: 'none',
      cursor: 'pointer',
      minWidth: '150px',
      background: '#2F6EFF',
      color: '#fff',
      width: '200px',
      margin: '0 auto',
      display: 'block',
    },
    tokenInputContainer: {
      marginBottom: '24px',
    },
    tokenInputLabel: {
      color: '#34D399',
      fontSize: '14px',
      fontWeight: '400',
      marginBottom: '8px',
      display: 'flex',
      alignItems: 'center',
    },
    tokenInput: {
      width: '100%',
      height: '50px',
      background: '#161A42',
      borderRadius: '8px',
      border: 'none',
      padding: '0 16px',
      color: '#F1F8FD',
      fontSize: '14px',
      outline: 'none',
    },
  };

  const checkValidation = (input, inputValue) => {
    let terror = 0;
    let message = "";
    var reg;
    switch (input) {
      case "amount":
        inputValue = parseFloat(inputValue);
        reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
        if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Please Enter Valid Amount!";
        } else if (parseFloat(inputValue) > value.balance) {
          terror += 1;
          message = "amount must be less than or equal to!";
        } else {
          message = "";
        }
        break;

      case "TGEDate":
        if (inputValue === "" || inputValue === null) {
          terror += 1;
          message = "Please enter valid date";
        } else if (inputValue < new Date()) {
          terror += 1;
          message = "Start Time must be after current time";
        } else {
          message = "";
        }
        break;
      case "TGEPercent":
      case "ReleasePercent":
        reg = new RegExp(/^\d+$/);
        if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Please Enter Valid Number!";
        } else if (
          (value.isvesting && !reg.test(inputValue)) ||
          parseFloat(inputValue) > 100
        ) {
          terror += 1;
          message = "percentage must be less than 100%!";
        } else {
          message = "";
        }
        break;
      case "Cycle":
        reg = new RegExp(/^\d+$/);
        if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Please Enter Valid Number!";
        } else {
          message = "";
        }
        break;
      default:
        terror += 0;
        break;
    }

    if (terror > 0) {
      setError({ ...error, [input]: message });
      return false;
    } else {
      setError({ ...error, [input]: "" });
      return true;
    }
  };

  const checkAllValidation = () => {
    let terror = 0;
    var reg;
    Object.keys(value).map((key) => {
      switch (key) {
        case "title":
          if (value[key] === "") {
            terror += 1;
          }
          break;
        case "owner":
          if (value.isDiffOwner && value[key] === "") {
            terror += 1;
          }
          break;
        case "amount":
          reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
          if (!reg.test(value[key]) || parseFloat(value[key]) <= 0) {
            terror += 1;
          } else if (parseFloat(value[key]) > value.balance) {
            terror += 1;
          }
          break;
        case "TGEDate":
          if (value.isvesting && (value[key] === "" || value[key] === null)) {
            terror += 1;
          } else if (value.isvesting && value[key] < new Date()) {
            terror += 1;
          }
          break;
        case "TGEPercent":
        case "ReleasePercent":
          reg = new RegExp(/^\d+$/);
          if (
            value.isvesting &&
            (!reg.test(value[key]) || parseFloat(value[key]) <= 0)
          ) {
            terror += 1;
          } else if (
            value.isvesting &&
            (!reg.test(value[key]) || parseFloat(value[key]) > 100)
          ) {
            terror += 1;
          }
          break;
        case "Cycle":
          reg = new RegExp(/^\d+$/);
          if (
            value.isvesting &&
            (!reg.test(value[key]) || parseFloat(value[key]) <= 0)
          ) {
            terror += 1;
          }
          break;
        default:
          terror += 0;
          break;
      }
      return true;
    });
    return terror === 0;
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    checkValidation(e.target.name, e.target.value);
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const onDiffOwner = (e) => {
    setValue({ ...value, isDiffOwner: e.target.checked });
  };

  const onChangeVesting = (e) => {
    setValue({ ...value, isvesting: e.target.checked });
  };

  const handleEndTimeChange = (date) => {
    checkValidation("TGEDate", date);
    setValue({ ...value, TGEDate: date });
  };

  const onOwnerAddress = (e) => {
    let web3 = getWeb3(chainId);
    let check = web3.utils.isAddress(e.target.value);
    if (!check) {
      setError({ ...error, [e.target.name]: "please enter valid address" });
    } else {
      setError({ ...error, [e.target.name]: "" });
    }
    setValue({ ...value, owner: e.target.value });
  };

  const handleApprove = async (e) => {
    e.preventDefault();
    if (!account) {
      toast.error("Please Connect Wallet!");
      return;
    }

    if (!chainId) {
      toast.error("Please select Smart Chain Network!");
      return;
    }

    try {
      if (!value.tokenAddress) {
        toast.error("Please enter valid token address!");
        return;
      }

      setLoading(true);
      let poolfactoryAddress = contract[chainId]?.lockAddress || contract["default"].lockAddress;
      let amount = parseEther("1000000000000000000000000000").toString();

      const result = await writeContract(config, {
        abi: tokenAbi,
        address: value.tokenAddress,
        functionName: 'approve',
        args: [poolfactoryAddress, amount]
      });

      toast.promise(new Promise(resolve => setTimeout(resolve, 5000)), {
        pending: "Waiting for confirmation 👌",
      });

      const interval = setInterval(async () => {
        const response = await waitForTransactionReceipt(config, { hash: result });
        if (response && response.status) {
          clearInterval(interval);
          if (response.status === 'success') {
            toast.success("Success! Your transaction is confirmed 👍");
            setValue({ ...value, isApprove: true });
          } else {
            toast.error("Transaction failed. Please try again.");
          }
          setLoading(false);
        }
      }, 5000);
    } catch (err) {
      toast.error(err.reason || err.message);
      setLoading(false);
    }
  };
  const handleLockToken = async (e) => {
    e.preventDefault();
    let check = checkAllValidation();

    if (!check) {
      toast.error("Please enter valid details!");
      setLockLoading(false);
      return;
    }

    try {
      let web3 = getWeb3(chainId);
      setLockLoading(true);
      let lockAddress = contract[chainId]?.lockAddress || contract["default"].lockAddress;

      if (value.isvesting) {
        const result = await writeContract(config, {
          abi: lockAbi,
          address: lockAddress,
          functionName: 'vestingLock',
          args: [
            value.owner ? value.owner : account,
            value.tokenAddress,
            value.islp === 1,
            mulDecimal(value.amount, value.tokenDecimal),
            Math.floor(new Date(value.TGEDate).getTime() / 1000.0),
            value.TGEPercent * 100,
            value.Cycle * 60 * 60,
            value.ReleasePercent * 100,
            value.title
          ]
        });

        toast.promise(new Promise(resolve => setTimeout(resolve, 5000)), {
          pending: "Processing your lock request... 🔒",
        });

        const interval = setInterval(async () => {
          const response = await waitForTransactionReceipt(config, { hash: result });
          if (response && response.status) {
            clearInterval(interval);
            if (response.status === 'success') {
              toast.success("Token lock successful! 🎉");
            } else {
              toast.error("Lock transaction failed. Please try again.");
            }
            setLockLoading(false);
          }
        }, 5000);
      } else {
        const result = await writeContract(config, {
          abi: lockAbi,
          address: lockAddress,
          functionName: 'lock',
          args: [
            value.isDiffOwner ? value.owner : account,
            value.tokenAddress,
            value.islp === 1,
            mulDecimal(value.amount, value.tokenDecimal),
            web3.utils.toHex(
              Math.floor(new Date(value.TGEDate).getTime() / 1000.0)
            ),
            value.title
          ]
        });

        toast.promise(new Promise(resolve => setTimeout(resolve, 5000)), {
          pending: "Processing your lock request... 🔒",
        });

        const interval = setInterval(async () => {
          const response = await waitForTransactionReceipt(config, { hash: result });
          if (response && response.status) {
            clearInterval(interval);
            if (response.status === 'success') {
              toast.success("Token lock successful! 🎉");
            } else {
              toast.error("Lock transaction failed. Please try again.");
            }
            setLockLoading(false);
          }
        }, 5000);
      }
    } catch (err) {
      toast.error(err.reason || err.message);
      setLockLoading(false);
    }
  };

  return (
    <div style={styles.container} role="tabpanel" id="step1">
    
      
      <div style={{ marginBottom: '32px' }}>
        <LockInput value={value} setValue={setValue} />
      </div>

      {/* Different Owner Section */}
      <div style={styles.inputContainer}>
        <label style={styles.label}>
          <input
            type="checkbox"
            style={styles.checkbox}
            onChange={onDiffOwner}
            id="differentOwner"
          />
          <span style={styles.checkboxLabel}>Use different owner address?</span>
        </label>
      </div>

      {/* Owner Address Input */}
      {value.isDiffOwner && (
        <div style={styles.inputContainer}>
          <label style={styles.label}>
            Owner
            <span style={styles.asterisk}>*</span>
          </label>
          <input
            style={styles.input}
            value={value.owner}
            onChange={onOwnerAddress}
            type="text"
            name="owner"
            placeholder="Enter Owner Address"
          />
          {error.owner && <div style={styles.error}>{error.owner}</div>}
        </div>
      )}

      {/* Title and Amount Row */}
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '24px' }}>
        {/* Title Input */}
        <div style={styles.inputContainer}>
          <label style={styles.label}>
            Title
            <span style={styles.asterisk}>*</span>
          </label>
          <input
            style={styles.input}
            onChange={onChangeInput}
            value={value.title}
            type="text"
            name="title"
            placeholder="Enter lock title"
          />
          {error.title && <div style={styles.error}>{error.title}</div>}
        </div>

        {/* Amount Input */}
        <div style={styles.inputContainer}>
          <label style={styles.label}>
            Amount
            <span style={styles.asterisk}>*</span>
          </label>
          <input
            style={styles.input}
            onChange={onChangeInput}
            value={value.amount}
            type="text"
            name="amount"
            placeholder="Enter amount to lock"
          />
          {error.amount && <div style={styles.error}>{error.amount}</div>}
        </div>
      </div>
      {/* Vesting Checkbox */}
      <div style={styles.inputContainer}>
        <label style={styles.label}>
          <input
            type="checkbox"
            style={styles.checkbox}
            onChange={onChangeVesting}
            id="useVesting"
          />
          <span style={styles.checkboxLabel}>Use vesting schedule?</span>
        </label>
      </div>

      {/* Vesting Fields */}
      {value.isvesting ? (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '24px' }}>
          {/* TGE Date */}
          <div style={styles.inputContainer}>
            <label style={styles.label}>
              TGE Date
              <span style={styles.asterisk}>*</span>
            </label>
            <DatePicker
              selected={value.TGEDate}
              onChange={handleEndTimeChange}
              isClearable
              placeholderText="Select TGE Date"
              minDate={new Date()}
              showDisabledMonthNavigation
              showTimeSelect
              dateFormat="yyyy-MM-dd HH:mm"
              className="custom-datepicker"
              customInput={
                <input
                  style={styles.input}
                />
              }
            />
            {error.TGEDate && <div style={styles.error}>{error.TGEDate}</div>}
          </div>

          {/* TGE Percent */}
          <div style={styles.inputContainer}>
            <label style={styles.label}>
              TGE Percent
              <span style={styles.asterisk}>*</span>
            </label>
            <input
              style={styles.input}
              value={value.TGEPercent}
              onChange={onChangeInput}
              type="text"
              name="TGEPercent"
              placeholder="e.g. 20"
            />
            {error.TGEPercent && <div style={styles.error}>{error.TGEPercent}</div>}
          </div>

          {/* Cycle Hours */}
          <div style={styles.inputContainer}>
            <label style={styles.label}>
              Cycle (Hours)
              <span style={styles.asterisk}>*</span>
            </label>
            <input
              style={styles.input}
              value={value.Cycle}
              onChange={onChangeInput}
              type="text"
              name="Cycle"
              placeholder="e.g. 10"
            />
            {error.Cycle && <div style={styles.error}>{error.Cycle}</div>}
          </div>

          {/* Release Percent */}
          <div style={styles.inputContainer}>
            <label style={styles.label}>
              Cycle Release Percent
              <span style={styles.asterisk}>*</span>
            </label>
            <input
              style={styles.input}
              value={value.ReleasePercent}
              onChange={onChangeInput}
              type="text"
              name="ReleasePercent"
              placeholder="e.g. 20"
            />
            {error.ReleasePercent && <div style={styles.error}>{error.ReleasePercent}</div>}
          </div>
        </div>
      ) : (
        // Lock Until Date for non-vesting
        <div style={styles.inputContainer}>
          <label style={styles.label}>
            Lock until
            <span style={styles.asterisk}>*</span>
          </label>
          <DatePicker
            selected={value.TGEDate}
            onChange={handleEndTimeChange}
            isClearable
            placeholderText="Select lock end date"
            minDate={new Date()}
            showDisabledMonthNavigation
            showTimeSelect
            dateFormat="yyyy-MM-dd HH:mm"
            className="custom-datepicker"
            customInput={
              <input
                style={styles.input}
              />
            }
          />
          {error.TGEDate && <div style={styles.error}>{error.TGEDate}</div>}
        </div>
      )}

      {/* Action Buttons */}
      <div style={styles.buttonContainer}>
        <Button
          loading={value.isApprove ? lockloading : loading}
          variant="none"
          type="button"
          style={styles.button}
          onClick={value.isApprove ? handleLockToken : handleApprove}
        >
          {value.isApprove ? 'Lock Tokens' : 'Approve'}
        </Button>
      </div>

      {/* Important Note */}
      <div style={styles.note}>
        <div style={{ marginBottom: '12px' }}>
          IMPORTANT: Please exclude our Contract address{" "}
          <span style={{ color: '#fff', wordBreak: 'break-all' }}>
            {contract[chainId]?.lockAddress || contract["default"].lockAddress}
          </span>
          <CopyToClipboard
            text={contract[chainId]?.lockAddress || contract["default"].lockAddress}
            onCopy={() => toast.success("Contract address copied!")}
          >
            <img 
              src={copyIcon} 
              alt="Copy"
              style={styles.copyIcon}
            />
          </CopyToClipboard>
          {" "}from fees, rewards & max tx amount.
        </div>
        <div style={{ color: '#FFB000' }}>
        </div>
      </div>

      {/* Custom DatePicker Styles */}
      <style>
        {`
          .custom-datepicker {
            width: 100%;
            height: 50px;
            background: #161A42;
            border-radius: 8px;
            border: none;
            padding: 0 16px;
            color: #fff;
            font-size: 14px;
            outline: none;
          }
          .react-datepicker {
            background: #161A42;
            border: 1px solid rgba(255, 255, 255, 0.1);
          }
          .react-datepicker__header {
            background: #1F2937;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
          .react-datepicker__current-month,
          .react-datepicker__day-name,
          .react-datepicker__day {
            color: #fff;
          }
          .react-datepicker__day:hover {
            background: #34D399;
          }
          .react-datepicker__time-container {
            border-left: 1px solid rgba(255, 255, 255, 0.1);
          }
          .react-datepicker__time-box {
            background: #161A42;
          }
          .react-datepicker__time-list-item:hover {
            background: #34D399 !important;
          }
          .react-datepicker__time-list-item--selected {
            background: #34D399 !important;
          }
        `}
      </style>
    </div>
  );
}

