import { trimAddress } from '../../../hooks/constant';

export default function Transaction(props) {
  // Sort transactions by date in descending order (latest first)
  const sortedTransactions = [...props.txStats].sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <div className="custom-transaction-list" style={{ 
      marginTop: "20px", 
      borderRadius: '8px', 
      overflow: 'hidden', 
      padding: '10px',
    }}>
      <div className="custom-transaction-scroll" style={{
        maxHeight: '240px', // Height for 5 items (adjust based on your item height)
        overflowY: 'auto',
        overflowX: 'hidden'
      }}>
        <div style={{ minWidth: '600px' }}> {/* Container for minimum width */}
          {sortedTransactions.map((tx, index) => (
            <div key={index} className="custom-transaction-item" style={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              padding: '10px 15px', 
              backgroundColor: index % 2 === 0 ? '#212760' : '#131638',
              borderRadius: '4px', 
              marginBottom: '5px',
            }}>
              <span className="custom-transaction-user" style={{ 
                color: '#c7cbd6', 
                fontFamily: 'Quicksand, Helvetica', 
                fontWeight: '500', 
                fontSize: '14px', 
                flex: 1 
              }}>
                {trimAddress(tx.account)}
              </span>
              <span className="custom-transaction-type" style={{ 
                color: tx.type === 'Buy' ? '#22c55e' : '#ff4d4d', 
                fontWeight: '600', 
                fontSize: '14px', 
                flex: 1, 
                textAlign: 'center' 
              }}>
                {tx.type}
              </span>
              <span className="custom-transaction-amount" style={{ 
                color: tx.type === 'Buy' ? '#22c55e' : '#ff4d4d', 
                fontWeight: '500', 
                fontSize: '14px', 
                flex: 1, 
                textAlign: 'center' 
              }}>
                {tx.amount} {tx.symbol}
              </span>
              <span className="custom-transaction-date" style={{ 
                color: '#98A7B5', 
                fontFamily: 'Quicksand, Helvetica', 
                fontWeight: '500', 
                fontSize: '14px', 
                flex: 1, 
                textAlign: 'right' 
              }}>
                {new Date(tx.date).toLocaleString('en-GB', { 
                  day: 'numeric', 
                  month: 'short', 
                  hour: '2-digit', 
                  minute: '2-digit' 
                }).replace(',', ' :')}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

<style>
  {`
    .custom-transaction-list {
      border: none;
      background-color: transparent;
      box-shadow: none;
      margin-top: 20px;
      padding: 10px;
    }

    .custom-transaction-scroll {
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: thin;
      scrollbar-color: #6604FD transparent;
    }

    .custom-transaction-scroll::-webkit-scrollbar {
      width: 6px;
    }

    .custom-transaction-scroll::-webkit-scrollbar-track {
      background: transparent;
    }

    .custom-transaction-scroll::-webkit-scrollbar-thumb {
      background-color: #6604FD;
      border-radius: 3px;
      border: 2px solid transparent;
    }

    .custom-transaction-item {
      transition: background-color 0.3s ease;
      border-radius: 4px;
      margin-bottom: 5px;
      display: flex;
    }

    .custom-transaction-item:nth-child(even) {
      background-color: #212760;
    }

    .custom-transaction-item:nth-child(odd) {
      background-color: #131638;
    }

    .custom-transaction-item:hover {
      background-color: rgba(41, 49, 79, 0.7);
    }

    .custom-transaction-user,
    .custom-transaction-type,
    .custom-transaction-amount,
    .custom-transaction-date {
      font-size: 14px;
      font-family: 'Quicksand, Helvetica';
      font-weight: 500;
      flex: 1;
    }

    /* Media Query for Mobile */
    @media (max-width: 768px) {
      .custom-transaction-scroll {
        overflow-x: auto;
      }
      
      .custom-transaction-item {
        min-width: 600px;
      }
    }
  `}
</style>