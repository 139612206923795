import axios from "axios"
import { SERVER_URL } from "../../../../hooks/backend";
// import https from 'https'

// const agent = new https.Agent({
//   rejectUnauthorized: false // This will ignore self-signed certificate errors
// });


export const saveBondTxdata = (data) => {
  axios
    .post(`${SERVER_URL}/record/tx/bond`, data)
    .then((response) => console.log("response", response))
    .catch((err) => console.log("error", err));
}

export const readBondTxdata = async (data) => {
  if (!data.chainId || !data.poolAddress) {
    return [];
  }
  let result = await axios
    .get(`${SERVER_URL}/record/tx/bond/${data.chainId}/${data.poolAddress}`)
    .then((response) => {
      console.log("response.data", response.data)
      return response.data
    })
    .catch((err) => {
      console.log("error getting bond Tx", err)
      return []
    });
  return result
}