import React, { useEffect, useState } from "react";
import RocketIcon from '../../../images/launch.svg'; // Adjust the path if needed

const getRandomInterval = () => Math.floor(Math.random() * 30000) + 15000; // Random interval between 15 and 45 seconds

const NewBanner = ({ upcomingSales = [], zeroRaisedSales = [], endedSales = [] }) => {
  const [currentMessage, setCurrentMessage] = useState("");
  const [wobble, setWobble] = useState(false);

  useEffect(() => {
    const combinedSales = [
      ...upcomingSales.filter((sale) => sale.poolState !== "2" && sale.poolState !== "1"), // Exclude canceled and ended sales
      ...zeroRaisedSales.filter((sale) => sale.poolState === "0"), // Only include active sales with 0 ETH raised
      ...endedSales, // Include ended sales with a delay
    ];

    if (combinedSales.length > 0) {
      const updateMessage = () => {
        const randomSale = combinedSales[Math.floor(Math.random() * combinedSales.length)];
        const message = randomSale.poolState === "0"
          ? `NEW LAUNCH ADDED: $${randomSale.symbol}`
          : `UPCOMING LAUNCH: $${randomSale.symbol}`;
        setCurrentMessage(message);
        setWobble(true);
        setTimeout(() => setWobble(false), 2000); // End wobble after 2 seconds
      };

      const updateAtRandomIntervals = () => {
        updateMessage();
        const interval = getRandomInterval();
        return setTimeout(updateAtRandomIntervals, interval);
      };

      const timeoutId = updateAtRandomIntervals();

      return () => clearTimeout(timeoutId);
    }
  }, [upcomingSales, zeroRaisedSales, endedSales]);

  return (
    <div className={`new-banner ${wobble ? "wobble" : ""}`} style={bannerStyle}>
      <div style={innerDivStyle}>
        <img src={RocketIcon} alt="Rocket" className="icon" style={iconStyle} />
        <div style={textStyle}>{currentMessage}</div>
      </div>
    </div>
  );
};

const bannerStyle = {
  height: '45px', // Set height to 45px
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#161A42', // Background color matching app's theme
  borderRadius: '8px', // Consistent rounded corners
  padding: '5px 15px', // Adjusted padding
  width: '100%',
  margin: '0 auto', // Center align
  boxSizing: 'border-box', // Include padding in element's total width and height
};

const innerDivStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px', // Adjust the gap between the icon and text
};

const iconStyle = {
  width: '20px', // Adjust the width of the icon
  height: '20px', // Adjust the height of the icon
};

const textStyle = {
  fontSize: '14px', // Match font size with app's text
  fontFamily: "'Quicksand', sans-serif", // Ensure consistent font family
  fontWeight: '600', // Match font weight
  color: '#FFFFFF', // Text color matching app theme
  textAlign: 'center',
  lineHeight: '20px', // Ensure proper text alignment and spacing
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap', // Prevent text wrapping
};

export default NewBanner;
