import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import poolFactoryAbi from "../../json/poolfactory.json";
import poolAbi from "../../json/BondingPool.json";
import { useNavigate } from "react-router-dom";
import { contract } from "../../hooks/constant";
import { getContract, mulDecimal } from "../../hooks/contractHelper";
import { parseEther } from "ethers/lib/utils";
import { getWeb3 } from "../../hooks/connectors";
import Button from "react-bootstrap-button-loader";
import { saveBondTxdata } from "../launchpadApply/bondingsaleview/helper/backend";
import coinLogo from "../../images/PumpIcon.svg";

import { useAccount, useChainId } from "wagmi";
import { config } from "../../config";
import { writeContract, waitForTransactionReceipt } from "@wagmi/core";



export default function BondingToken() {
  const context = useWeb3React();
  const chainId = useChainId();
  const { address: account } = useAccount();
  const navigate = useNavigate();
  const [error, setError] = useState({
    name: '',
    symbol: '',
    logourl: "",
    bannerurl: "",
    website: "",
    facebook: "",
    twitter: "",
    github: "",
    telegram: "",
    instagram: "",
    discord: "",
    reddit: "",
    youtube: "",
    brief: "",
    blockstart: "",
  });

  const [value, setValue] = useState({
    name: '',
    symbol: '',
    logourl: "",
    bannerurl: "",
    website: "",
    facebook: "",
    twitter: "",
    github: "",
    telegram: "",
    instagram: "",
    discord: "",
    reddit: "",
    youtube: "",
    brief: "",
    blockstart: "",
    usermail: "info@defilaunch.app",
    auditlink: "",
    kyclink: "",
  });
  const [loading, setLoading] = useState(false);
  const [fee, setFee] = useState(0.00005); //0.005
  const [creationFee0, setCreationFee0] = useState(20000000000000); //to admin wallet 2000000000000000
  const [creationFee1, setCreationFee1] = useState(30000000000000); //to admin wallet 3000000000000000
  const [showSocials, setShowSocials] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isTextExpanded, setIsTextExpanded] = useState(false);

  useEffect(() => {
    if (chainId && (chainId == 56 || chainId == 97)) {
      setFee(0.0003); //0.03
      setCreationFee0(100000000000000n); //10000000000000000n
      setCreationFee1(200000000000000n); //20000000000000000n
    } else {
      setFee(0.00005); //0.005
    }
  }, [chainId]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleTextVisibility = () => {
    setIsTextExpanded(!isTextExpanded);
  };

  const toggleSocialsVisibility = () => {
    setShowSocials(!showSocials);
  };

  const checkValidation = (input, inputValue) => {
    let terror = 0;
    let message = "";
    var reg;
    switch (input) {
      case "name":
        if (inputValue === "") {
          terror += 1;
          message = "Please Input Token Name!";
        } else {
          message = "";
        }
        break;
      case "symbol":
        if (inputValue === "") {
          terror += 1;
          message = "Please Input Token Symbol!";
        } else {
          message = "";
        }
        break;
      case "facebook":
      case "twitter":
      case "github":
      case "telegram":
      case "instagram":
      case "discord":
      case "reddit":
      case "youtube":
      case "blockstart":
        reg = new RegExp(
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
        );
        if (inputValue !== "" && !reg.test(inputValue)) {
          terror += 1;
          message = "Please Enter a Valid URL Ser!";
        } else {
          message = "";
        }
        break;

      case "logourl":
      case "bannerurl":
      case "website":
        reg = new RegExp(
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
        );
        if (!reg.test(inputValue)) {
          terror += 1;
          message = "Please Enter a Valid URL bro!";
        } else {
          message = "";
        }
        break;
      default:
        terror += 0;
        break;
    }

    if (terror > 0) {
      setError({ ...error, [input]: message });
      return false;
    } else {
      setError({ ...error, [input]: "" });
      return true;
    }
  };

  const checkAllValidation = () => {
    let terror = 0;
    var reg;
    Object.keys(value).map((key, index) => {
      switch (key) {
        case "name":
          if (value[key] === "") {
            terror += 1;
          }
          break;
        case "symbol":
          if (value[key] === "") {
            terror += 1;
          }
          break;
        case "logourl":
        case "bannerurl":
        case "website":
          reg = new RegExp(
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
          );
          if (!reg.test(value[key])) {
            terror += 1;
          }
          break;
        default:
          terror += 0;
          break;
      }
      return true;
    });

    if (terror > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    checkValidation(e.target.name, e.target.value);
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handleCreateSale = async (e) => {
    e.preventDefault();
    //here all template
    let tokenAddress = "0x0000000000000000000000000000000000000000"// template token address
    // let currencyAddress = "0x0000000000000000000000000000000000000000" //eth or bnb

    try {
      setLoading(true);
      if (account) {
        if (chainId) {
          let targetEthAmount = parseEther(contract[chainId].targetEthAmount);
          let marketCap = Number(contract[chainId].marketCap);
          let para = [
            [
              tokenAddress,
              contract[chainId].positionManagerV3addr,
              account,
              // currencyAddress,
              contract[chainId].ethPriceFeed
            ], // 1 
            [
              0,
              5, //5% of raised to adminWallet
            ], //2
            [
              1, //1% of buy fee
              1, //1% of sell fee
              marketCap, //69k of market cap,
              targetEthAmount
            ], //3
            [
              creationFee0,
              creationFee1
            ], //4
            `${value.logourl}$#$${value.bannerurl}$#$${value.website}$#$$#$${value.facebook}$#$${value.twitter}$#$${value.github}$#$${value.telegram}$#$${value.instagram}$#$${value.discord}$#$${value.reddit}$#$${value.youtube}$#$${value.brief}`,
            [value.name, value.symbol] //5
          ];

          let poolfactoryAddress = contract[chainId]
            ? contract[chainId].poolfactory
            : contract["default"].poolfactory;
          try {
            const result = await writeContract(config, {
              abi: poolFactoryAbi,
              address: poolfactoryAddress,
              functionName: 'createBondingToken',
              args: [
                para[0],
                para[1],
                para[2],
                para[3],
                para[4],
                para[5]
              ],
              value: parseEther(fee.toFixed(8).toString())
            });
            console.log("result", result)
            const resolveAfter3Sec = new Promise((resolve) =>
              setTimeout(resolve, 10000)
            );
            toast.promise(resolveAfter3Sec, {
              pending: "Blockchain Wizards in Action! Your Pump Launch is Being Forged..",
            });
            var interval = setInterval(async function () {
              let web3 = getWeb3(chainId);
              const response = await waitForTransactionReceipt(config, { hash: result });
              if (response != null) {
                clearInterval(interval);
                if (response && response.status && response.status == 'success') {
                  toast.success("Pump Time, Degen! Your Sale Is Live and Ready to Wreak Havoc! 🎉");

                  setLoading(false);
                  if (typeof response.logs[3] !== "undefined") {
                    // console.log("resoponse.logs[0", response.logs[0]);

                    let poolContract = new web3.eth.Contract(poolAbi, response.logs[3].address);
                    let tokenPrice = await poolContract.methods.getTokenPrice().call();
                    tokenPrice = Number(tokenPrice) / 10 ** 18
                    const symbol = value.symbol;
                    const type = 'Create';
                    const date = new Date();
                    try {
                      saveBondTxdata({ account, chainId: chainId.toString(), type, amount: "1,000,000,000", poolAddress: response.logs[3].address, tokenPrice, symbol, date });

                    } catch (err) {
                      console.log("error while creating bonding and save to db", err);
                    }
                    navigate(
                      // `/fairlaunch-details/${response.logs[0].address}`

                      `/bonding-details/${response.logs[3].address}??chainid=${chainId}`
                      // `/sale-list`
                    );
                  } else {
                    toast.error("Uh-oh, something went wrong! Ser Please try again.");
                    navigate("/");
                  }
                } else if (response.status === false) {
                  toast.error("Yikes! Your transaction failed. Please try again, bro.");
                  setLoading(false);
                } else {
                  toast.error("Uh-oh, something went wrong! Please try again Ser.");
                  setLoading(false);
                }
              }
            }, 5000);
          } catch (err) {
            console.log("err", err);
            // toast.error('Oh no Ser! Something went wrong while creating the Fair Launch!');
            toast.error('Please unlock your wallet first!');
            setLoading(false);
          }
        } else {
          toast.error("Wrong network selected Ser! Switch networks and try again.");
          setLoading(false);
        }
      } else {
        toast.error("Bro, connect your wallet first! 🙈 ");
        setLoading(false);
      }
    } catch (err) {
      toast.error(err.reason ? err.reason : err.message);
      setLoading(false);
    }
  };

  const containerStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    marginTop: '0px',
  };

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    maxWidth: '1400px',
    background: '#111432',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)',
    border: '1px solid #075985',
  };

  const headerContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    marginTop: isMobile ? '20px' : '50px',
  };

  const logoStyle = {
    width: '35px',
    height: '35px',
    marginRight: '15px',
    marginLeft: '20px',
    marginBottom: isMobile ? '10px' : '30px', // Added marginBottom for mobile
  };

  const headerTextStyle = {
    display: 'flex',
    flexDirection: 'column',
  };

  const headerStyle = {
    color: '#fff',
    fontSize: '20px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '600',
    textAlign: 'left',
  };

  const subHeaderStyle = {
    textAlign: 'left',
    color: '#7B91B0',
    fontSize: '16px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '400',
    marginBottom: '0px',
  };

  const labelStyle = {
    color: '#6EE7B7',
    fontSize: '14px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  };

  const tokenTypeLabelStyle = {
    color: '#2F80ED',
    fontSize: '14px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  };

  const dropdownStyle = {
    width: '90%',
    height: '50px',
    background: 'linear-gradient(162deg, #161A42 0%, rgba(22, 26, 66, 0) 100%)',
    borderRadius: '8px',
    border: '2.57px #212554 solid',
    padding: '0 10px',
    color: '#fff',
    fontSize: '16px',
    appearance: 'none',
    backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'16\' height=\'16\' viewBox=\'0 0 16 16\'%3E%3Cpath fill=\'%23ffffff\' d=\'M4 6l4 4 4-4\'/%3E%3C/svg%3E")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 10px center',
  };

  const createButtonStyle = {
    width: '400px', 
    height: '50px',
    background: '#2F6EFF',
    borderRadius: '8px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: '600',
    border: 'none',
    cursor: 'pointer',
    transition: 'background 0.2s',
    marginTop: '24px',
    padding: '0 32px', 
    display: 'block', 
    margin: '0px auto 0', 
  };

  const inputStyle = {
    width: '90%',
    height: '50px',
    background: 'linear-gradient(162deg, #161A42 0%, rgba(22, 26, 66, 0) 100%)',
    borderRadius: '8px',
    border: '2.57px #212554 solid',
    padding: '0 10px',
    color: '#fff',
    fontSize: '14px',
    marginBottom: '20px', // Added marginBottom
  };

  const descriptionContainerStyle = {
    flex: isMobile ? '1 1 100%' : '1 1 50%',
    maxWidth: isMobile ? '100%' : '45%', // Ensures the description box is 50% width on desktop
  };

  const descriptionInputStyle = {
    ...inputStyle,
    height: '100px', // Added height for the textarea
    width: '100%',
    paddingTop: '10px', // Added padding above the placeholder text
    paddingBottom: '10px', // Added padding above the placeholder text

  };

  const customCheckboxContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px', // Added padding above the placeholder text

  };

  const customCheckboxStyle = {
    width: '20px',
    height: '20px',
    background: 'linear-gradient(162deg, #161A42 0%, rgba(22, 26, 66, 0) 100%)',
    borderRadius: '4px',
    border: '2.57px #212554 solid',
    appearance: 'none',
    outline: 'none',
    cursor: 'pointer',
    position: 'relative',
  };

  const customCheckboxCheckedStyle = {
    ...customCheckboxStyle,
    background: '#6EE7B7',
  };

  const checkmarkStyle = {
    color: 'white',
    fontSize: '16px',
    position: 'absolute',
    top: '-3px',
    left: '3px',
  };

  const feeInfoContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    alignItems: 'center',
    width: '400px',
    margin: '00px auto',
  };

  const mobileFeeInfoTextStyle = {
    fontSize: '0.8rem',
    color: 'white',
    textAlign: 'center',
    margin: '10px 0',
    marginTop: '20px',
  };

  const feeInfoTextStyle = {
    fontSize: '0.9rem',
    color: 'white',
  };

  const hrStyle = {
    borderColor: '#212554',
    margin: '0',
  };

  const addSocialsContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '10px',
  };

  const checkboxLabelStyle = {
    color: '#6EE7B7',
    fontSize: '14px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    marginLeft: isMobile ? '-20px' : '0px',
  };

  // Mobile-specific styles
  const mobileContainerStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    marginTop: '0px',
  };

  const mobileFormStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    background: '#111432',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)',
    border: '1px solid #075985',
  };

  const mobileHeaderContainerStyle = {
    ...headerContainerStyle,
    flexDirection: 'column',
    textAlign: 'left',
    alignItems: 'flex-start',
    marginTop: '25px',
    paddingLeft: '10px',
    paddingRight: '10px',
    marginLeft: '0px',
    marginRight: '0px',
  };

  const mobileHeaderTextStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const mobileHeaderStyle = {
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
    margin: '2px', // Ensure no margins for header text
    paddingBottom: '10px', // Add padding below header text
  };

  const mobileSubHeaderStyle = {
    fontSize: '14px',
    marginTop: '5px',
    color: '#7B91B0',
    textAlign: 'left',
    padding: '0px 10px', // Add padding for subheader
    margin: '0px', // Ensure no margins for subheader text
  };

  const mobileLogoStyle = {
    marginRight: '10px',
    marginLeft: '10px',
    width: '25px',
    height: '25px',
    marginBottom: '10px', // Added marginBottom for mobile
  };

  const mobileLabelStyle = {
    ...labelStyle,
    fontSize: '12px',
    marginBottom: '5px',
  };

  const mobileInputStyle = {
    ...inputStyle,
    width: '100%',
    marginBottom: '20px', // Added marginBottom
  };

  const mobileCreateButtonStyle = {
    ...createButtonStyle,
    width: '100%',
    height: 'auto',
  };

  return (
    <>
      <div style={isMobile ? mobileHeaderContainerStyle : headerContainerStyle}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'row' : 'row' }}>
          <img src={coinLogo} alt="Coin Logo" style={isMobile ? mobileLogoStyle : logoStyle} />
          <div style={isMobile ? mobileHeaderTextStyle : headerTextStyle}>
            <div style={isMobile ? mobileHeaderStyle : headerStyle}>Pump Launch</div>
            {!isMobile && (
              <div style={subHeaderStyle}>
                Create & Launch a token in one transaction for {fee} {chainId==97||chainId==56? 'BNB': 'ETH'}. Fixed supply of 1 billion tokens with 1% to your Dev wallet.
                <br />Users can trade & withdraw on the bonding curve until the market cap is reached. Auto-listed on a V3 DEX at $69k with LP burned.
              </div>
            )}
          </div>
        </div>
        {isMobile && (
          <div style={mobileSubHeaderStyle}>
            Create & Launch a token in one transaction for {fee} {chainId==97||chainId==56? 'BNB': 'ETH'}. Auto-listed on a V3 DEX at $69k with LP burned.
            {isTextExpanded ? (
              <>
                Fixed supply of 1 billion tokens with 1% to your Dev wallet. Users can trade & withdraw on the bonding curve until the market cap is reached.
                <span style={{ color: 'white', cursor: 'pointer' }} onClick={toggleTextVisibility}> Show less</span>
              </>
            ) : (
              <>
                <span style={{ color: 'white', cursor: 'pointer' }} onClick={toggleTextVisibility}> Read more...</span>
              </>
            )}
          </div>
        )}
      </div>
      <div style={isMobile ? mobileContainerStyle : containerStyle}>
        <div style={isMobile ? mobileFormStyle : formStyle}>
          <div className="tab-content" id="main_form">
            <div className="row">
              <div style={{ flex: isMobile ? '1 1 100%' : '1 1 38%' }}>
                <label style={isMobile ? mobileLabelStyle : labelStyle}>
                  Name<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  style={isMobile ? mobileInputStyle : inputStyle}
                  onChange={(e) => onChangeInput(e)}
                  value={value.name}
                  type="text"
                  name="name"
                  placeholder="Eg: Degen Coin"
                />
                <small className="text-danger">{error.name}</small>
              </div>
              <div style={{ flex: isMobile ? '1 1 100%' : '1 1 38%' }}>
                <label style={isMobile ? mobileLabelStyle : labelStyle}>
                  Symbol<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  style={isMobile ? mobileInputStyle : inputStyle}
                  onChange={(e) => onChangeInput(e)}
                  value={value.symbol}
                  type="text"
                  name="symbol"
                  placeholder="Eg: DGN"
                />
                <small className="text-danger">{error.symbol}</small>
              </div>
              <div style={{ flex: isMobile ? '1 1 100%' : '1 1 38%' }}>
                <label style={isMobile ? mobileLabelStyle : labelStyle}>
                  Logo URL<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  style={isMobile ? mobileInputStyle : inputStyle}
                  onChange={(e) => onChangeInput(e)}
                  type="text"
                  name="logourl"
                  placeholder="e.g. https://i.postimg.cc/my_logo.png"
                />
                <small className="text-danger">{error.logourl}</small>
              </div>
              <div style={{ flex: isMobile ? '1 1 100%' : '1 1 38%' }}>
                <label style={isMobile ? mobileLabelStyle : labelStyle}>
                  Banner URL<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  style={isMobile ? mobileInputStyle : inputStyle}
                  onChange={(e) => onChangeInput(e)}
                  type="text"
                  name="bannerurl"
                  placeholder="e.g. https://i.postimg.cc/my_banner.png"
                />
                <small className="text-danger">{error.bannerurl}</small>
              </div>
              <div style={{ flex: isMobile ? '1 1 100%' : '1 1 38%' }}>
                <label style={isMobile ? mobileLabelStyle : labelStyle}>
                  Website<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  style={isMobile ? mobileInputStyle : inputStyle}
                  onChange={(e) => onChangeInput(e)}
                  type="text"
                  name="website"
                  placeholder="e.g. https://defilaunch.app"
                />
                <small className="text-danger">{error.website}</small>
              </div>
              <div style={{ flex: isMobile ? '1 1 100%' : '1 1 38%' }}>
                <label style={isMobile ? mobileLabelStyle : labelStyle}>
                  Add Socials<span className=""></span>
                </label>
                <div style={customCheckboxContainerStyle}>
                  <input
                    type="checkbox"
                    style={showSocials ? customCheckboxCheckedStyle : customCheckboxStyle}
                    onChange={toggleSocialsVisibility}
                    checked={showSocials}
                  />
                  {showSocials && <span style={checkmarkStyle}>✔️</span>}
                </div>
              </div>
              {showSocials && (
                <>
                  <div style={{ flex: isMobile ? '1 1 100%' : '1 1 38%' }}>
                    <label style={isMobile ? mobileLabelStyle : labelStyle}>
                      X/Twitter
                    </label>
                    <input
                      className="form-control"
                      style={isMobile ? mobileInputStyle : inputStyle}
                      onChange={(e) => onChangeInput(e)}
                      type="text"
                      name="twitter"
                      placeholder="e.g. https://twitter.com/"
                    />
                    <small className="text-danger">{error.twitter}</small>
                  </div>
                  <div style={{ flex: isMobile ? '1 1 100%' : '1 1 38%' }}>
                    <label style={isMobile ? mobileLabelStyle : labelStyle}>
                      Telegram
                    </label>
                    <input
                      className="form-control"
                      style={isMobile ? mobileInputStyle : inputStyle}
                      onChange={(e) => onChangeInput(e)}
                      type="text"
                      name="telegram"
                      placeholder="e.g. https://t.me/defilaunchapp"
                    />
                    <small className="text-danger">{error.telegram}</small>
                  </div>
                  <div style={{ flex: isMobile ? '1 1 100%' : '1 1 38%' }}>
                    <label style={isMobile ? mobileLabelStyle : labelStyle}>
                      Discord
                    </label>
                    <input
                      className="form-control"
                      style={isMobile ? mobileInputStyle : inputStyle}
                      onChange={(e) => onChangeInput(e)}
                      type="text"
                      name="discord"
                      placeholder="e.g. https://discord.com/"
                    />
                    <small className="text-danger">{error.discord}</small>
                  </div>
                  <div style={{ flex: isMobile ? '1 1 100%' : '1 1 38%' }}>
                    <label style={isMobile ? mobileLabelStyle : labelStyle}>
                      Youtube Video
                    </label>
                    <input
                      className="form-control"
                      style={isMobile ? mobileInputStyle : inputStyle}
                      onChange={(e) => onChangeInput(e)}
                      type="text"
                      name="youtube"
                      placeholder="e.g. https://youtube.com/watch"
                    />
                    <small className="text-danger">{error.youtube}</small>
                  </div>
                </>
              )}
              <div style={descriptionContainerStyle}>
                <label style={isMobile ? mobileLabelStyle : labelStyle}>
                  Description
                </label>
                <textarea
                  type="text"
                  name="brief"
                  onChange={(e) => onChangeInput(e)}
                  className="brief"
                  style={descriptionInputStyle}
                  placeholder="Eg: The new Degenerate meme coin. Etc.."
                >
                  {value.brief}
                </textarea>
                <small className="text-danger">{error.brief}</small>
              </div>
            </div>
           
           
            <ul className="list-inline text-center">
  {/* Fee Information - Displayed with flex layout */}
  <li style={{ width: '100%', marginBottom: '10px' }}>
    <div style={isMobile ? mobileFeeInfoTextStyle : feeInfoContainerStyle}>
      <span style={feeInfoTextStyle}>Total cost:</span>
      <span style={feeInfoTextStyle}>0.0005 ETH</span>
    </div>
  </li>
  
  {/* CREATE PUMP Button */}
  <li style={{ width: '100%' }}>
    <Button
      type="button"
      className="default-btn"
      loading={loading}
      onClick={handleCreateSale}
      style={isMobile ? mobileCreateButtonStyle : createButtonStyle}
    >
      CREATE PUMP
    </Button>
  </li>
</ul>


          </div>
        </div>
      </div>
    </>
  );
}

