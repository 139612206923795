import React, { useEffect, useState } from "react";
import { useCommonStats, useAccountStats } from "./helper/useStats";
import Countdown, { zeroPad } from "react-countdown";
import { useWeb3React } from "@web3-react/core";
import dateFormat from "dateformat";
import { supportNetwork } from "../../../hooks/network";
import Button from "react-bootstrap-button-loader";
import { formatPrice } from "../../../hooks/contractHelper";
import poolAbi from "../../../json/FairPool.json";
import ERC20Abi from "../../../json/ERC20.json";
import { parseEther } from "ethers/lib/utils";
import { toast } from "react-toastify";
import { getWeb3 } from "../../../hooks/connectors";
import { getContract } from "../../../hooks/contractHelper";
import ReactPlayer from "react-player/youtube";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import { contract, convertDay, coinArray, trimAddress } from "../../../hooks/constant";
import { AiFillEdit } from 'react-icons/ai';
import iconImage from "../../../images/icon.png";
import templateImg from '../../../images/template.jpg';
import bannerTemplate from "../../../images/template_banner.png";
import { useAccount, useChainId } from "wagmi";
import { config } from "../../../config";
import { writeContract, waitForTransactionReceipt } from "@wagmi/core";
import discordIcon from "../../../images/discord.svg";
import telegramIcon from "../../../images/telegram.svg";
import websiteIcon from "../../../images/website.svg";
import twitterIcon from "../../../images/x.svg";

export default function ProjectDetails() {
  // State variables for managing project data and UI state
  const [updater, setUpdater] = useState(1);
  const stats = useCommonStats(updater);
  const accStats = useAccountStats(updater);
  const [currentTime] = useState(Math.floor(new Date().getTime() / 1000.0));
  const [social, setSocial] = useState({});
  const { library } = useWeb3React();
  const chainId = useChainId();
  const { address: account } = useAccount();
  const [amount, setAmount] = useState(0);
  const [allowance, setAllowance] = useState(0);
  const [btndisabled, setBtndisabled] = useState(false);
  const [error_msg, setError_msg] = useState("");
  const [loading, setLoading] = useState(false);
  const [finalLoading, setFinalLoading] = useState(false);
  const [wcLoading, setWcLoading] = useState(false);
  const [ctLoading, setCtLoading] = useState(false);
  const [locklLoading, setLocklLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [refcopy1, setRefcopy1] = useState(false);
  const [refcopy2, setRefcopy2] = useState(false);
  const [editmodalShow, setEditmodalShow] = useState(false);
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get("chainid");
  const [activeTab, setActiveTab] = useState('Sale Details');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isBriefExpanded, setIsBriefExpanded] = useState(false);

  const [error, setError] = useState({
    logourl: "",
    bannerurl: "",
    website: "",
    facebook: "",
    twitter: "",
    github: "",
    telegram: "",
    instagram: "",
    discord: "",
    reddit: "",
    youtube: "",
    brief: "",
    blockstart: "",
  });

  const [editSocial, setEditSocial] = useState({
    logourl: "",
    bannerurl: "",
    website: "",
    blockstart: "",
    facebook: "",
    twitter: "",
    github: "",
    telegram: "",
    instagram: "",
    discord: "",
    reddit: "",
    youtube: "",
    brief: "",
  });

  // Function to shorten the address for mobile view
  const shortenAddress = (address) => {
    return address ? `${address.substring(0, 6)}...${address.substring(address.length - 4)}` : '';
  };

  const [imgageURL, setImageURL] = useState("");
  const [bannerURL, setBannerURL] = useState("");
  const [tokenAddr, setTokenAddr] = useState('');
  const [poolAddr, setPoolAddr] = useState('');
  const [status, setStatus] = useState("Active");

  useEffect(() => {
    function getDetails() {
      let details = stats.poolDetails.toString().split("$#$");
      const object = {
        logourl: details[0],
        bannerurl: details[1],
        website: details[2],
        blockstar: details[3],
        facebook: details[4],
        twitter: details[5],
        github: details[6],
        telegram: details[7],
        instagram: details[8],
        discord: details[9],
        reddit: details[10],
        youtube: details[11],
        brief: details[12],
      };
      if (stats.startTime - stats.endTime == 1000) {
        setStatus("Bonding");
      }
      setSocial(object);
      setEditSocial(object);
      setImageURL(object.logourl);
      setBannerURL(object.bannerurl);
      setTokenAddr(stats.token);
      setPoolAddr(stats.poolAddress);
    }
    if (stats) {
      getDetails();
    }
  }, [stats]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      try {
        // Use shortenAddress for mobile view, full address for larger screens
        setPoolAddr(window.innerWidth < 768 ? shortenAddress(stats.poolAddress) : stats.poolAddress);
        setTokenAddr(window.innerWidth < 768 ? shortenAddress(stats.token) : stats.token);
      } catch {
        console.log("error")
      }
    };

    // Initial call to set addresses based on current window size
    handleResize();

    // Event listener for window resizing
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [stats]);

  const setimage = () => {
    setImageURL(templateImg);
  }

  const setBannerImage = () => {
    setBannerURL(bannerTemplate)
  }

  const startTime = new Date(stats.startTime * 1000);
  const endTime = new Date(stats.endTime * 1000);

  const checkValidation = (input, inputValue) => {
    let terror = 0;
    let message = "";
    var reg;
    switch (input) {
      case "facebook":
      case "twitter":
      case "github":
      case "telegram":
      case "instagram":
      case "discord":
      case "reddit":
      case "youtube":
      case "blockstart":
        reg = new RegExp(
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
        );
        if (inputValue !== "" && !reg.test(inputValue)) {
          terror += 1;
          message = "Please Enter Valid url!";
        } else {
          message = "";
        }
        break;

      case "logourl":
      case "bannerurl":
      case "website":
        reg = new RegExp(
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
        );
        if (!reg.test(inputValue)) {
          terror += 1;
          message = "Please Enter Valid url!";
        } else {
          message = "";
        }
        break;
      default:
        terror += 0;
        break;
    }

    if (terror > 0) {
      setError({ ...error, [input]: message });
      return false;
    } else {
      setError({ ...error, [input]: "" });
      return true;
    }
  };

  const checkAllValidation = () => {
    let terror = 0;
    var reg;
    Object.keys(editSocial).map((key) => {
      switch (key) {
        case "facebook":
        case "twitter":
        case "github":
        case "telegram":
        case "instagram":
        case "discord":
        case "reddit":
        case "youtube":
        case "blockstart":
          reg = new RegExp(
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
          );
          if (editSocial[key] !== "" && !reg.test(editSocial[key])) {
            terror += 1;
          }
          break;

        case "logourl":
        case "bannerurl":
        case "website":
          reg = new RegExp(
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
          );
          if (!reg.test(editSocial[key])) {
            terror += 1;
          }
          break;
        default:
          terror += 0;
          break;
      }
      return true;
    });

    if (terror > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    checkValidation(e.target.name, e.target.value);
    setEditSocial({ ...editSocial, [e.target.name]: e.target.value });
  };

  const handleEditSubmit = async (e) => {
    let check = checkAllValidation();
    if (check) {
      e.preventDefault();
      setEditLoading(true);
      try {
        if (account) {
          let poolContract = getContract(poolAbi, stats.poolAddress, library);
          let detailsset = `${editSocial.logourl}$#$${editSocial.bannerurl}$#$${editSocial.website}$#$${editSocial.blockstart}$#$${editSocial.facebook}$#$${editSocial.twitter}$#$${editSocial.github}$#$${editSocial.telegram}$#$${editSocial.instagram}$#$${editSocial.discord}$#$${editSocial.reddit}$#$${editSocial.youtube}$#$${editSocial.brief}`;

          const result = await writeContract(config, {
            abi: poolAbi,
            address: stats.poolAddress,
            functionName: 'updatePoolDetails',
            args: [detailsset],
          });

          const resolveAfter3Sec = new Promise((resolve) =>
            setTimeout(resolve, 5000)
          );
          toast.promise(resolveAfter3Sec, {
            pending: "Waiting for confirmation",
          });

          var interval = setInterval(async function () {
            const response = await waitForTransactionReceipt(config, { hash: result });
            if (response != null) {
              clearInterval(interval);
              if (response && response.status && response.status == 'success') {
                toast.success("success ! your last transaction is success");
                setUpdater(new Date());
                setEditLoading(false);
                setEditmodalShow(false);
              } else if (response.status === false) {
                toast.error("error ! Your last transaction is failed.");
                setUpdater(new Date());
                setEditLoading(false);
              } else {
                toast.error("error ! something went wrong.");
                setUpdater(new Date());
                setEditLoading(false);
              }
            }
          }, 5000);
        } else {
          toast.error("Please Connect to wallet !");
          setEditLoading(false);
        }
      } catch (err) {
        toast.error(err.reason ? err.reason : err.message);
        setEditLoading(false);
      }
    } else {
      toast.error(
        "Required all field or Enter wrong value  ! please check again"
      );
    }
  };

  // Custom renderer function for flip-clock style countdown
  const flipClockRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <div className="flip-clock" style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
          <div className="flip-unit">
            <span className="flip-value">{zeroPad(0, 2)}</span>
            <span className="flip-label">Days</span>
          </div>
          <div className="flip-unit">
            <span className="flip-value">{zeroPad(0, 2)}</span>
            <span className="flip-label">Hours</span>
          </div>
          <div className="flip-unit">
            <span className="flip-value">{zeroPad(0, 2)}</span>
            <span className="flip-label">Minutes</span>
          </div>
          <div className="flip-unit">
            <span className="flip-value">{zeroPad(0, 2)}</span>
            <span className="flip-label">Seconds</span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flip-clock" style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
          <div className="flip-unit">
            <span className="flip-value">{zeroPad(days)}</span>
            <span className="flip-label">Days</span>
          </div>
          <div className="flip-unit">
            <span className="flip-value">{zeroPad(hours)}</span>
            <span className="flip-label">Hours</span>
          </div>
          <div className="flip-unit">
            <span className="flip-value">{zeroPad(minutes)}</span>
            <span className="flip-label">Minutes</span>
          </div>
          <div className="flip-unit">
            <span className="flip-value">{zeroPad(seconds)}</span>
            <span className="flip-label">Seconds</span>
          </div>
        </div>
      );
    }
  };

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
    setBtndisabled(true);

    if (isNaN(e.target.value)) {
      setError_msg("please enter valid amount");
      setBtndisabled(true);
    } else if (parseFloat(e.target.value) === 0 || e.target.value === "") {
      setError_msg("amount must be greater than zero");
      setBtndisabled(true);
    } else {
      setError_msg("");
      setBtndisabled(false);
    }
    return;
  };

  const handleMaxAmount = (e) => {
    e.preventDefault();
    let maxamount =
      stats.currencyAddress === "0x0000000000000000000000000000000000000000"
        ? parseFloat(accStats.balance) - parseFloat(0.01)
        : parseFloat(accStats.balance);
    if (
      parseFloat(maxamount) < parseFloat(stats.minContribution) ||
      parseFloat(maxamount) > parseFloat(stats.maxContribution)
    ) {
      setError_msg(
        `amount must be between ${stats.minContribution} and ${stats.maxContribution}`
      );
      setBtndisabled(true);
    }
    setAmount(maxamount.toFixed(4).toString());
  };

  const handleSubmitContribution = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (amount > 0) {
        if (account) {
          if (chainId) {
            if (parseFloat(accStats.balance) >= parseFloat(amount)) {
              let tokenAmount =
                stats.currencyAddress ===
                  "0x0000000000000000000000000000000000000000"
                  ? 0
                  : parseEther(amount);
              let ethAmount =
                stats.currencyAddress ===
                  "0x0000000000000000000000000000000000000000"
                  ? parseEther(amount)
                  : 0;
              console.log("tokenAmount", tokenAmount)
              const result = await writeContract(config, {
                abi: poolAbi,
                address: stats.poolAddress,
                functionName: 'contribute',
                args: [tokenAmount],
                value: ethAmount
              });
              console.log("Come here?1")
              const resolveAfter3Sec = new Promise((resolve) =>
                setTimeout(resolve, 5000)
              );
              toast.promise(resolveAfter3Sec, {
                pending: "Waiting for confirmation 👌",
              });

              var interval = setInterval(async function () {
                const response = await waitForTransactionReceipt(config, { hash: result });
                if (response != null) {
                  clearInterval(interval);
                  if (response && response.status && response.status == 'success') {
                    toast.success(
                      "success ! your last transaction is success 👍"
                    );
                    setUpdater(new Date());
                    setLoading(false);
                  } else if (response.status === false) {
                    toast.error("error ! Your last transaction is failed.");
                    setUpdater(new Date());
                    setLoading(false);
                  } else {
                    toast.error("error ! something went wrong.");
                    setUpdater(new Date());
                    setLoading(false);
                  }
                }
              }, 5000);
            } else {
              toast.error("you don't have enough balance !");
              setLoading(false);
            }
          } else {
            toast.error("Please select Smart Chain Network !");
            setLoading(false);
          }
        } else {
          toast.error("Please Connect Wallet!");
          setLoading(false);
        }
      } else {
        toast.error("Please Enter Valid Amount !");
        setLoading(false);
      }
    } catch (err) {
      toast.error(err.reason);
      setLoading(false);
    }
  };

  const handleApprove = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (account) {
        if (chainId) {
          let currencyContract = getContract(
            ERC20Abi,
            stats.currencyAddress,
            library
          );

          let amount = parseEther("1000000000000000000000000000").toString();
          let tx = await currencyContract.approve(stats.poolAddress, amount, {
            from: account,
          });
          const resolveAfter3Sec = new Promise((resolve) =>
            setTimeout(resolve, 5000)
          );
          toast.promise(resolveAfter3Sec, {
            pending: "Waiting for confirmation 👌",
          });

          var interval = setInterval(async function () {
            let web3 = getWeb3(chainId);
            var response = await web3.eth.getTransactionReceipt(tx.hash);
            if (response != null) {
              clearInterval(interval);
              if (response.status === true) {
                toast.success("success ! your last transaction is success 👍");
                setUpdater(new Date());
                setLoading(false);
                accStats.allowance = "1000000000000000000000000000";
                setAllowance(accStats.allowance);
              } else if (response.status === false) {
                toast.error("error ! Your last transaction is failed.");
                setUpdater(new Date());
                setLoading(false);
              } else {
                toast.error("error ! something went wrong.");
                setUpdater(new Date());
                setLoading(false);
              }
            }
          }, 5000);
        } else {
          toast.error("Please select Smart Chain Network !");
          setLoading(false);
        }
      } else {
        toast.error("Please Connect Wallet!");
        setLoading(false);
      }
    } catch (err) {
      toast.error(err.reason);
      setLoading(false);
    }
  };

  const handleFinalize = async (e) => {
    e.preventDefault();
    setFinalLoading(true);
    try {
      if (account) {

        const result = await writeContract(config, {
          abi: poolAbi,
          address: stats.poolAddress,
          functionName: 'finalize',
        });

        const resolveAfter3Sec = new Promise((resolve) =>
          setTimeout(resolve, 5000)
        );
        toast.promise(resolveAfter3Sec, {
          pending: "Waiting for confirmation",
        });

        var interval = setInterval(async function () {
          const response = await waitForTransactionReceipt(config, { hash: result });
          if (response != null) {
            clearInterval(interval);
            if (response && response.status && response.status == 'success') {
              toast.success("success ! your last transaction is success");
              setUpdater(new Date());
              setFinalLoading(false);
            } else if (response.status === false) {
              toast.error("error ! Your last transaction is failed.");
              setUpdater(new Date());
              setFinalLoading(false);
            } else {
              toast.error("error ! something went wrong.");
              setUpdater(new Date());
              setFinalLoading(false);
            }
          }
        }, 5000);
      } else {
        toast.error("Please Connect to wallet !");
        setFinalLoading(false);
      }
    } catch (err) {
      console.log("err", err)
      toast.error(err.reason ? err.reason : err.message);
      setFinalLoading(false);
    }
  };

  const handleWithdrawContribution = async (e) => {
    e.preventDefault();
    setWcLoading(true);
    try {
      if (account) {

        const result = await writeContract(config, {
          abi: poolAbi,
          address: stats.poolAddress,
          functionName: 'withdrawContribution',
        });

        const resolveAfter3Sec = new Promise((resolve) =>
          setTimeout(resolve, 5000)
        );
        toast.promise(resolveAfter3Sec, {
          pending: "Waiting for confirmation",
        });

        var interval = setInterval(async function () {
          const response = await waitForTransactionReceipt(config, { hash: result });
          if (response != null) {
            clearInterval(interval);
            if (response && response.status && response.status == 'success') {
              toast.success("success ! your last transaction is success");
              setUpdater(new Date());
              setWcLoading(false);
            } else if (response.status === false) {
              toast.error("error ! Your last transaction is failed.");
              setUpdater(new Date());
              setWcLoading(false);
            } else {
              toast.error("error ! something went wrong.");
              setWcLoading(false);
            }
          }
        }, 5000);
      } else {
        toast.error("Please Connect to wallet !");
        setWcLoading(false);
      }
    } catch (err) {
      toast.error(err.reason ? err.reason : err.message);
      setWcLoading(false);
    }
  };

  const handleClaimToken = async (e) => {
    e.preventDefault();
    setCtLoading(true);
    try {
      if (account) {
        const result = await writeContract(config, {
          abi: poolAbi,
          address: stats.poolAddress,
          functionName: 'claim',
        });

        const resolveAfter3Sec = new Promise((resolve) =>
          setTimeout(resolve, 5000)
        );
        toast.promise(resolveAfter3Sec, {
          pending: "Waiting for confirmation",
        });

        var interval = setInterval(async function () {
          const response = await waitForTransactionReceipt(config, { hash: result });
          if (response != null) {
            clearInterval(interval);
            if (response && response.status && response.status == 'success') {
              toast.success("success ! your last transaction is success");
              setUpdater(new Date());
              setCtLoading(false);
            } else if (response.status === false) {
              toast.error("error ! Your last transaction is failed.");
              setUpdater(new Date());
              setCtLoading(false);
            } else {
              toast.error("error ! something went wrong.");
              setUpdater(new Date());
              setCtLoading(false);
            }
          }
        }, 5000);
      } else {
        toast.error("Please Connect to wallet !");
        setCtLoading(false);
      }
    } catch (err) {
      toast.error(err.reason ? err.reason : err.message);
      setCtLoading(false);
    }
  };

  const handleWithdrawLiquidity = async (e) => {
    e.preventDefault();
    setLocklLoading(true);
    try {
      if (account) {
        const result = await writeContract(config, {
          abi: poolAbi,
          address: stats.poolAddress,
          functionName: 'withdrawLiquidity',
        });

        const resolveAfter3Sec = new Promise((resolve) =>
          setTimeout(resolve, 5000)
        );
        toast.promise(resolveAfter3Sec, {
          pending: "Waiting for confirmation",
        });

        var interval = setInterval(async function () {
          const response = await waitForTransactionReceipt(config, { hash: result });
          if (response != null) {
            clearInterval(interval);
            if (response && response.status && response.status == 'success') {
              toast.success("success ! your last transaction is success");
              setUpdater(new Date());
              setLocklLoading(false);
            } else if (response.status === false) {
              toast.error("error ! Your last transaction is failed.");
              setUpdater(new Date());
              setLocklLoading(false);
            } else {
              toast.error("error ! something went wrong.");
              setUpdater(new Date());
              setLocklLoading(false);
            }
          }
        }, 5000);
      } else {
        toast.error("Please Connect to wallet !");
        setLocklLoading(false);
      }
    } catch (err) {
      toast.error(err.reason ? err.reason : err.message);
      setLocklLoading(false);
    }
  };

  const handleCancel = async (e) => {
    e.preventDefault();
    setLocklLoading(true);
    try {
      if (account) {
        const result = await writeContract(config, {
          abi: poolAbi,
          address: stats.poolAddress,
          functionName: 'cancel',
        });

        const resolveAfter3Sec = new Promise((resolve) =>
          setTimeout(resolve, 5000)
        );
        toast.promise(resolveAfter3Sec, {
          pending: "Waiting for confirmation",
        });

        var interval = setInterval(async function () {
          const response = await waitForTransactionReceipt(config, { hash: result });
          if (response != null) {
            clearInterval(interval);
            if (response && response.status && response.status == 'success') {
              toast.success("success ! your last transaction is success");
              setUpdater(new Date());
              setLocklLoading(false);
            } else if (response.status === false) {
              toast.error("error ! Your last transaction is failed.");
              setUpdater(new Date());
              setLocklLoading(false);
            } else {
              toast.error("error ! something went wrong.");
              setUpdater(new Date());
              setLocklLoading(false);
            }
          }
        }, 5000);
      } else {
        toast.error("Please Connect to wallet !");
        setLocklLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      toast.error(err.reason ? err.reason : err.message);
      setLocklLoading(false);
    }
  };

  const renderBrief = () => {
    if (!social.brief) return null;

    const isLongText = social.brief.length > 80; // Adjust the length as needed
    const displayedText = isBriefExpanded || !isLongText
      ? social.brief
      : `${social.brief.substring(0, 60)}...`;

    return (
      <p className='text-center text-md-left mt-3 custom-grey-text'>
        {displayedText}
        {isLongText && (
          <span
            onClick={() => setIsBriefExpanded(!isBriefExpanded)}
            style={{
              background: '#21A595',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              cursor: 'pointer',
              marginLeft: '5px'
            }}
          >
            {isBriefExpanded ? ' Show less' : 'view more'}
          </span>
        )}
      </p>
    );
  };

  return (
    <React.Fragment>
      <div className='detail-page container' style={{ marginTop: '0', paddingTop: '0' }}>
        <section className='item-details-area' style={{ marginTop: '15px', paddingTop: '0' }}>
          <div className={`row ${isMobile ? 'flex-column-reverse' : ''}`}>
            <div className={`col-12 ${isMobile ? '' : 'col-lg-8'}`}>


              {/* Header Section with Project Logo, Socials, Project Name and Brief */}
              <div className={`card project-card no-hover py-4 px-2 ${isMobile ? 'mb-4' : ''}`}>
                <div className='row'>
                  <div className='col-12 col-md-2 text-center'>
                    <img
                      className='card-img-top avatar-max-lg mt-1'
                      width='100%'
                      height='auto'
                      borderradius='50%'
                      src={imgageURL}
                      onError={() => setimage()}
                      alt='iconimage12'
                      style={{ borderRadius: '50%', objectFit: 'cover', minWidth: '50px' }}
                    />
                  </div>
                  <div className='col-12 col-md-10'>
                    <div className='row align-items-center justify-content-md-start justify-content-center'>
                      <h4 className='mt-1 mb-2 text-center text-md-left'>
                        {stats.tokenName}
                      </h4>
                      <div className='d-flex gap-1 justify-content-center audit-status'>
                        {stats.audit &&
                          (stats.auditStatus ||
                            (stats.auditLink && (
                              <a
                                target='_blank'
                                rel='noreferrer'
                                href={stats.auditLink}
                              >
                                <h4 className='tag-btn text-uppercase text-center bg-yellow'>
                                  Audit
                                </h4>
                              </a>
                            )))}
                        {stats.kyc &&
                          (stats.kycStatus ||
                            (stats.kycLink && (
                              <a
                                target='_blank'
                                rel='noreferrer'
                                href={stats.kycLink}
                              >
                                <h4 className='tag-btn text-uppercase text-center bg-purple'>
                                  KYC
                                </h4>
                              </a>
                            )))}
                      </div>
                    </div>
                    <div className='social-share d-flex justify-content-center justify-content-md-start'>
                      <ul className='d-flex list-unstyled'>
                        {social.twitter && (
                          <li>
                            <a
                              target='_blank'
                              rel='noreferrer'
                              href={social.twitter}
                            >
                              <img src={twitterIcon} alt='Twitter' style={{ color: 'grey', width: '24px', height: '24px' }} />
                            </a>
                          </li>
                        )}
                        {social.telegram && (
                          <li>
                            <a
                              target='_blank'
                              rel='noreferrer'
                              href={social.telegram}
                            >
                              <img src={telegramIcon} alt='Telegram' style={{ color: 'grey', width: '24px', height: '24px' }} />
                            </a>
                          </li>
                        )}
                        {social.website && (
                          <li>
                            <a
                              target='_blank'
                              rel='noreferrer'
                              href={social.website}
                            >
                              <img src={websiteIcon} alt='Website' style={{ color: 'grey', width: '24px', height: '24px' }} />
                            </a>
                          </li>
                        )}
                        {social.discord && (
                          <li>
                            <a
                              target='_blank'
                              rel='noreferrer'
                              href={social.discord}
                            >
                              <img src={discordIcon} alt='Discord' style={{ color: 'grey', width: '24px', height: '24px' }} />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                    {/* Project Brief */}
                    {renderBrief()}
                  </div>
                </div>
              </div>

              {/* Project Details Section */}
              <div className={`card project-card no-hover mt-3 ${isMobile ? 'mb-4' : ''}`} style={{ padding: '0px', paddingTop: '12px', paddingBottom: '12px' }}>
                <div className='row'>
                  {[
                    { label: "Launch", value: poolAddr, copyValue: stats.poolAddress, copyId: 1 },
                    { label: "Name", value: stats.tokenName },
                    { label: "Symbol", value: stats.tokenSymbol },
                    { label: "Decimals", value: stats.tokenDecimal },
                    { label: "Contract", value: tokenAddr, copyValue: stats.token, copyId: 2 },
                    { label: "Supply", value: !isNaN(parseFloat(stats.tokenSupply)) ? Math.floor(parseFloat(stats.tokenSupply)).toLocaleString() : 'N/A' }
                  ].map((item, index) => (
                    <div key={index} className='col-12 d-flex justify-content-between align-items-center' style={{ paddingTop: '12px', paddingBottom: '12px', borderBottom: '1px solid rgba(128, 128, 128, 0.3)', flexWrap: 'wrap' }}>
                      <p style={{ fontSize: '14px', fontWeight: 400, color: '#98A7B5', margin: 0, lineHeight: '1.2' }}>{item.label}:</p>
                      <p style={{ fontSize: '14px', fontWeight: 400, color: '#F1F8FD', whiteSpace: 'normal', wordWrap: 'break-word', margin: 0, lineHeight: '1.2', display: 'flex', alignItems: 'center' }}>
                        {item.label === "Launch" || item.label === "Contract" ? (
                          <span style={{
                            background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            display: 'inline-block',
                            wordBreak: 'break-all',
                          }}>
                            {item.value}
                          </span>
                        ) : (
                          item.value
                        )}
                        {item.copyValue && (
                          <CopyToClipboard
                            text={item.copyValue}
                            onCopy={() => {
                              item.copyId === 1 ? setRefcopy1(true) : setRefcopy2(true);
                              setTimeout(() => {
                                item.copyId === 1 ? setRefcopy1(false) : setRefcopy2(false);
                              }, 2000);
                            }}
                          >
                            <span
                              style={{
                                cursor: 'pointer',
                                display: 'inline-block',
                                marginLeft: '5px',
                                width: '16px',
                                height: '16px',
                                background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
                                WebkitMaskImage: `url(${iconImage})`,
                                WebkitMaskRepeat: 'no-repeat',
                                WebkitMaskSize: 'contain',
                                WebkitMaskPosition: 'center',
                              }}
                            ></span>
                          </CopyToClipboard>
                        )}
                        {item.copyId === 1 && refcopy1 && " copied"}
                        {item.copyId === 2 && refcopy2 && " copied"}
                      </p>
                    </div>
                  ))}
                  {status !== "Bonding" ? (
                    <React.Fragment>
                      <div className='col-12 d-flex justify-content-between align-items-center' style={{ paddingTop: '12px', paddingBottom: '12px', borderBottom: '1px solid rgba(128, 128, 128, 0.3)', flexWrap: 'wrap' }}>
                        <p style={{ fontSize: '14px', fontWeight: 400, color: '#98A7B5', margin: 0, lineHeight: '1.2' }}>Rate</p>
                        <p style={{ fontSize: '14px', fontWeight: 400, color: '#F1F8FD', whiteSpace: 'normal', wordWrap: 'break-word', margin: 0, lineHeight: '1.2' }}>1 {coinArray[chainId]} = {!isNaN(parseFloat(stats.rate)) ? Math.floor(parseFloat(stats.rate)).toLocaleString() : 'N/A'}</p>
                      </div>
                      <div className='col-12 d-flex justify-content-between align-items-center' style={{ paddingTop: '12px', paddingBottom: '12px', borderBottom: '1px solid rgba(128, 128, 128, 0.3)', flexWrap: 'wrap' }}>
                        <p style={{ fontSize: '14px', fontWeight: 400, color: '#98A7B5', margin: 0, lineHeight: '1.2' }}>Softcap</p>
                        <p style={{ fontSize: '14px', fontWeight: 400, color: '#F1F8FD', whiteSpace: 'normal', wordWrap: 'break-word', margin: 0, lineHeight: '1.2' }}>{stats.softCap} {coinArray[chainId]}</p>
                      </div>
                      <div className='col-12 d-flex justify-content-between align-items-center' style={{ paddingTop: '12px', paddingBottom: '12px', borderBottom: '1px solid rgba(128, 128, 128, 0.3)', flexWrap: 'wrap' }}>
                        <p style={{ fontSize: '14px', fontWeight: 400, color: '#98A7B5', margin: 0, lineHeight: '1.2' }}>Unsold</p>
                        <p style={{ fontSize: '14px', fontWeight: 400, color: '#F1F8FD', whiteSpace: 'normal', wordWrap: 'break-word', margin: 0, lineHeight: '1.2' }}>{stats.refundType === "0" ? "Refund" : "Burn"}</p>
                      </div>
                      <div className='col-12 d-flex justify-content-between align-items-center' style={{ paddingTop: '12px', paddingBottom: '12px', borderBottom: '1px solid rgba(128, 128, 128, 0.3)', flexWrap: 'wrap' }}>
                        <p style={{ fontSize: '14px', fontWeight: 400, color: '#98A7B5', margin: 0, lineHeight: '1.2' }}>Start</p>
                        <p style={{ fontSize: '14px', fontWeight: 400, color: '#F1F8FD', whiteSpace: 'normal', wordWrap: 'break-word', margin: 0, lineHeight: '1.2' }}>{dateFormat(startTime, "yyyy-mm-dd HH:MM")}</p>
                      </div>
                      <div className='col-12 d-flex justify-content-between align-items-center' style={{ paddingTop: '12px', paddingBottom: '12px', borderBottom: '1px solid rgba(128, 128, 128, 0.3)', flexWrap: 'wrap' }}>
                        <p style={{ fontSize: '14px', fontWeight: 400, color: '#98A7B5', margin: 0, lineHeight: '1.2' }}>End</p>
                        <p style={{ fontSize: '14px', fontWeight: 400, color: '#F1F8FD', whiteSpace: 'normal', wordWrap: 'break-word', margin: 0, lineHeight: '1.2' }}>{dateFormat(endTime, "yyyy-mm-dd HH:MM")}</p>
                      </div>
                      <div className='col-12 d-flex justify-content-between align-items-center' style={{ paddingTop: '12px', paddingBottom: '12px', borderBottom: '1px solid rgba(128, 128, 128, 0.3)', flexWrap: 'wrap' }}>
                        <p style={{ fontSize: '14px', fontWeight: 400, color: '#98A7B5', margin: 0, lineHeight: '1.2' }}>Listing</p>
                        <p style={{ fontSize: '14px', fontWeight: 400, color: '#F1F8FD', whiteSpace: 'normal', wordWrap: 'break-word', margin: 0, lineHeight: '1.2' }}>
                          {stats.routerVersion && stats.routerVersion === 1
                            ? "Uniswap V3"
                            : stats.routerVersion === 3
                              ? "PancakeSwap V3"
                              : "PancakeSwap V2"}
                        </p>
                      </div>
                      <div className='col-12 d-flex justify-content-between align-items-center' style={{ paddingTop: '12px', paddingBottom: '12px', borderBottom: '1px solid rgba(128, 128, 128, 0.3)', flexWrap: 'wrap' }}>
                        <p style={{ fontSize: '14px', fontWeight: 400, color: '#98A7B5', margin: 0, lineHeight: '1.2' }}>Liquidity</p>
                        <p style={{ fontSize: '14px', fontWeight: 400, color: '#F1F8FD', whiteSpace: 'normal', wordWrap: 'break-word', margin: 0, lineHeight: '1.2' }}>{stats.liquidityPercent} %</p>
                      </div>
                      <div className='col-12 d-flex justify-content-between align-items-center' style={{ paddingTop: '12px', paddingBottom: '12px', borderBottom: '1px solid rgba(128, 128, 128, 0.3)', flexWrap: 'wrap' }}>
                        <p style={{ fontSize: '14px', fontWeight: 400, color: '#98A7B5', margin: 0, lineHeight: '1.2' }}>LP Unlock</p>
                        <p style={{ fontSize: '14px', fontWeight: 400, color: '#F1F8FD', whiteSpace: 'normal', wordWrap: 'break-word', margin: 0, lineHeight: '1.2' }}>{Number(stats.liquidityLockDays) / 24 / 60 / 60} days</p>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className='mt-md-0 mt-3 d-flex justify-content-center'>
                        <div className='countdown'>
                          <div className='text-center' style={{ fontSize: "1.1rem" }}>
                            {status}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>




            </div>

            {/* Sidebar Section with Banner, Countdown, and Contribution Form */}
            <div className={`col-12 ${isMobile ? '' : 'col-lg-4'}`}>
              <div className={`card project-card no-hover ${isMobile ? 'mb-4' : ''}`}>
                <div className='card-header'>
                  <img
                    src={bannerURL}
                    alt="Banner"
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'contain',
                      borderTopLeftRadius: '15px',
                      borderTopRightRadius: '15px',
                    }}
                    onError={() => setBannerImage()}
                  />
                </div>
                <div className='custom-card-body'>
                  {status !== "Bonding" ? (
                    <React.Fragment>
                      {/* Countdown Timer */}
                      <div className='mt-md-0 mt-3 d-flex justify-content-center'>
                        <div className='countdown'>
                          <div className='status-display'>
                            {parseInt(stats.endTime) < Math.floor(new Date().getTime() / 1000.0) || stats.poolState == '1' || stats.poolState == '2'
                              ? "ENDED"
                              : parseInt(stats.startTime) > Math.floor(new Date().getTime() / 1000.0)
                                ? "START TIME"
                                : parseInt(stats.startTime) < Math.floor(new Date().getTime() / 1000.0) &&
                                  parseInt(stats.endTime) > Math.floor(new Date().getTime() / 1000.0)
                                  ? "ENDING IN"
                                  : ""}
                          </div>
                          {stats.poolState == '0' && <Countdown
                            key={Math.floor(Math.random() * 10 + 1)}
                            date={
                              stats.startTime > currentTime
                                ? stats.startTime * 1000
                                : stats.endTime * 1000
                            }
                            renderer={flipClockRenderer}
                          />}

                        </div>
                      </div>

                      {/* Progress Bar */}
                      <div className="item-progress">
                        <div className="custom-progress-bar">
                          <div
                            className="progress-fill"
                            style={{ width: `${parseFloat(stats.percentageRaise).toFixed(2)}%` }}
                          ></div>
                        </div>
                        <div className="progress-sale d-flex justify-content-between">
                          <span className="progress-text">
                            0 {coinArray[chainId]}
                          </span>
                          <span className="progress-text">
                            SOFTCAP ~ {stats.softCap} {coinArray[chainId]}
                          </span>
                        </div>
                      </div>
                    </React.Fragment>) : (
                    <div className='mt-md-0 mt-3 d-flex justify-content-center'>
                      <div className='countdown'>
                        <div
                          className='text-center'
                          style={{ fontSize: "1.1rem" }}
                        >
                          {status}
                        </div>
                      </div>
                    </div>)}

                  {/* Contribution Form */}
                  {stats.poolState == '0' && parseInt(stats.endTime) >= Math.floor(new Date().getTime() / 1000.0) && <div className='new-staking-area mt-4'>
                    <div className='new-staking-card'>
                      <p className="new-contribution-title">Contribute</p>
                      <div className='new-input-box my-1'>
                        <div className='new-input-area d-flex flex-column'>
                          <div className='new-input-text d-flex'>
                            <input
                              type='text'
                              value={amount}
                              onChange={(e) => {
                                handleChangeAmount(e);
                              }}
                              placeholder='0.00'
                              className="new-contribution-input"
                            />
                            <a
                              href='#sec'
                              onClick={(e) => {
                                handleMaxAmount(e);
                              }}
                              className="new-max-link"
                            >
                              Max
                            </a>
                          </div>
                          <div className='mt-2'>
                            <Button
                              variant='none'
                              disabled={btndisabled}
                              loading={loading}
                              type='button'
                              onClick={(e) => {
                                handleSubmitContribution(e);
                              }}
                              className='btn new-contribution-button'
                            >
                              SUBMIT
                            </Button>
                          </div>
                        </div>
                        <span className='text-danger'>
                          <small>{error_msg}</small>
                        </span>
                      </div>
                      {(stats.poolState === "2" || stats.poolState === "0") && (
                        <React.Fragment>
                          <div className='d-flex justify-content-between align-items-center mt-4'>
                            <p className='new-contribution-title'>My Contribution</p>
                            <span className='new-contribution-value'>
                              {accStats.contributionOf
                                ? formatPrice(accStats.contributionOf)
                                : "0"}{" "}
                              {coinArray[chainId]}
                            </span>
                          </div>
                        </React.Fragment>
                      )}
                      {stats.poolState === "1" && (
                        <React.Fragment>
                          <div className='d-flex justify-content-between align-items-center'>
                            <p className='new-contribution-title'>Claimable Tokens</p>
                            <span className='new-contribution-value'>
                              {accStats.userAvalibleClaim
                                ? formatPrice(accStats.userAvalibleClaim)
                                : "0"}{" "}
                              {stats.tokenSymbol}
                            </span>
                          </div>
                          <Button
                            loading={ctLoading}
                            variant='none'
                            className='btn new-contribution-button mt-2 mt-md-0 mr-md-3'
                            onClick={(e) => handleClaimToken(e)}
                          >
                            CLAIM TOKENS
                          </Button>
                        </React.Fragment>
                      )}
                      {accStats.contributionOf > 0 &&
                        (stats.poolState === "2" || stats.poolState === "0") && (
                          <>
                            <Button
                              loading={wcLoading}
                              className='btn new-contribution-button mt-4 mt-md-0 mr-md-3'
                              onClick={(e) => handleWithdrawContribution(e)}
                            >
                              WITHDRAW EARLY
                            </Button>
                            <small className='text-danger'>
                              * Early Withdraw only possible before the sale ends
                            </small>
                          </>
                        )}
                    </div>
                  </div>}

                </div>
              </div>

              {/* Action Buttons for Admins */}
              {(stats.owner === account && parseInt(stats.startTime) > Math.floor(new Date().getTime() / 1000.0)) ||
                (stats.owner === account && parseInt(stats.endTime) < Math.floor(new Date().getTime() / 1000.0)) ? (
                <>
                  <Button
                    variant='none'
                    className='btn new-contribution-button mt-2 mt-md-0 mr-md-3'
                    loading={editLoading}
                    onClick={() => setEditmodalShow(true)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant='none'
                    className='btn new-contribution-button mt-2 mt-md-0 mr-md-3'
                    loading={finalLoading}
                    onClick={(e) => handleFinalize(e)}
                  >
                    Finalize
                  </Button>
                  <Button
                    variant='none'
                    className='btn new-contribution-button mt-2 mt-md-0 mr-md-3'
                    loading={locklLoading}
                    onClick={(e) => handleWithdrawLiquidity(e)}
                  >
                    Withdraw Liquidity
                  </Button>
                  <Button
                    variant='none'
                    className='btn new-contribution-button mt-2 mt-md-0 mr-md-3'
                    loading={locklLoading}
                    onClick={(e) => handleCancel(e)}
                  >
                    Cancel
                  </Button>
                </>
              ) : null}
            </div>
          </div>
        </section>
      </div>

      {/* Edit Modal for Admins */}
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={editmodalShow}
        onHide={() => setEditmodalShow(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Edit Pool Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleEditSubmit}>
            <div className='form-group'>
              <label className='col-form-label'>
                Pool Logo URL <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                name='logourl'
                className='form-control'
                onChange={onChangeInput}
                value={editSocial.logourl}
                required
              />
              <span className='text-danger'>{error.logourl}</span>
            </div>
            <div className='form-group'>
              <label className='col-form-label'>
                Pool Banner URL <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                name='bannerurl'
                className='form-control'
                onChange={onChangeInput}
                value={editSocial.bannerurl}
                required
              />
              <span className='text-danger'>{error.bannerurl}</span>
            </div>
            <div className='form-group'>
              <label className='col-form-label'>
                Website URL <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                name='website'
                className='form-control'
                onChange={onChangeInput}
                value={editSocial.website}
                required
              />
              <span className='text-danger'>{error.website}</span>
            </div>
            <div className='form-group'>
              <label className='col-form-label'>Facebook URL</label>
              <input
                type='text'
                name='facebook'
                className='form-control'
                onChange={onChangeInput}
                value={editSocial.facebook}
              />
              <span className='text-danger'>{error.facebook}</span>
            </div>
            <div className='form-group'>
              <label className='col-form-label'>Twitter URL</label>
              <input
                type='text'
                name='twitter'
                className='form-control'
                onChange={onChangeInput}
                value={editSocial.twitter}
              />
              <span className='text-danger'>{error.twitter}</span>
            </div>
            <div className='form-group'>
              <label className='col-form-label'>Github URL</label>
              <input
                type='text'
                name='github'
                className='form-control'
                onChange={onChangeInput}
                value={editSocial.github}
              />
              <span className='text-danger'>{error.github}</span>
            </div>
            <div className='form-group'>
              <label className='col-form-label'>Telegram URL</label>
              <input
                type='text'
                name='telegram'
                className='form-control'
                onChange={onChangeInput}
                value={editSocial.telegram}
              />
              <span className='text-danger'>{error.telegram}</span>
            </div>
            <div className='form-group'>
              <label className='col-form-label'>Instagram URL</label>
              <input
                type='text'
                name='instagram'
                className='form-control'
                onChange={onChangeInput}
                value={editSocial.instagram}
              />
              <span className='text-danger'>{error.instagram}</span>
            </div>
            <div className='form-group'>
              <label className='col-form-label'>Discord URL</label>
              <input
                type='text'
                name='discord'
                className='form-control'
                onChange={onChangeInput}
                value={editSocial.discord}
              />
              <span className='text-danger'>{error.discord}</span>
            </div>
            <div className='form-group'>
              <label className='col-form-label'>Reddit URL</label>
              <input
                type='text'
                name='reddit'
                className='form-control'
                onChange={onChangeInput}
                value={editSocial.reddit}
              />
              <span className='text-danger'>{error.reddit}</span>
            </div>
            <div className='form-group'>
              <label className='col-form-label'>Youtube URL</label>
              <input
                type='text'
                name='youtube'
                className='form-control'
                onChange={onChangeInput}
                value={editSocial.youtube}
              />
              <span className='text-danger'>{error.youtube}</span>
            </div>
            <div className='form-group'>
              <label className='col-form-label'>Brief</label>
              <textarea
                name='brief'
                className='form-control'
                onChange={onChangeInput}
                value={editSocial.brief}
              />
              <span className='text-danger'>{error.brief}</span>
            </div>
            <div className='form-group'>
              <label className='col-form-label'>BlockStart</label>
              <input
                type='text'
                name='blockstart'
                className='form-control'
                onChange={onChangeInput}
                value={editSocial.blockstart}
              />
              <span className='text-danger'>{error.blockstart}</span>
            </div>
            <Button
              loading={editLoading}
              variant='none'
              className='btn btn-primary'
              type='submit'
            >
              Submit
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}