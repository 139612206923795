import React, { useState, useEffect, useRef } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { useAccount } from 'wagmi'; // Import useAccount from wagmi
import { database } from '../firebaseConfig';
import { ref, onValue, set, remove, query, limitToLast } from 'firebase/database';
import { FaTrash, FaEdit, FaEraser, FaBan } from 'react-icons/fa'; // Import icons for admin actions

// Import Degen images
import Degen1 from '../images/Degen1.png';
import Degen2 from '../images/Degen2.png';
import Degen3 from '../images/Degen3.png';
import Degen4 from '../images/Degen4.png';
import Degen5 from '../images/Degen5.png';
import Degen6 from '../images/Degen6.png';
import Degen7 from '../images/Degen7.png';
import Degen8 from '../images/Degen8.png';
import Degen9 from '../images/Degen9.png';
import Degen10 from '../images/Degen10.png';
import Degen11 from '../images/Degen11.png';
import Degen12 from '../images/Degen12.png';
import Degen13 from '../images/Degen13.png';
import Degen14 from '../images/Degen14.png';
import Degen15 from '../images/Degen15.png';
import Degen16 from '../images/Degen16.png';
import Degen17 from '../images/Degen17.png';
import Degen18 from '../images/Degen18.png';
import Degen19 from '../images/Degen19.png';
import Degen20 from '../images/Degen20.png';

const degenImages = [
  Degen1, Degen2, Degen3, Degen4, Degen5,
  Degen6, Degen7, Degen8, Degen9, Degen10,
  Degen11, Degen12, Degen13, Degen14, Degen15,
  Degen16, Degen17, Degen18, Degen19, Degen20
];

const adminAddresses = [
  '0x7308BD2ea2bc85B44e44c64B30c14C189F75531d'.toLowerCase(),
  '0x7384e9D49283D1195D1e8FD6515D72d622e65a70'.toLowerCase()
];

// Function to assign an avatar based on the address
const getAvatarForAddress = (address) => {
  if (!address) return ''; // Handle undefined addresses
  const index = Math.abs(address.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)) % degenImages.length;
  return degenImages[index];
};

// Function to generate a DGN number based on the address
const getDgnNumberForAddress = (address) => {
  if (!address) return 'DGN000'; // Handle undefined addresses
  if (address.toLowerCase() === adminAddresses[0]) return 'DGN001';
  if (address.toLowerCase() === adminAddresses[1]) return 'DGN002';
  const hash = Math.abs(address.split('').reduce((a, b) => ((a << 5) - a) + b.charCodeAt(0), 0));
  return `DGN${(hash % 1000).toString().padStart(3, '0')}`;
};

// Function to trim wallet addresses
const trimAddress = (address) => {
  if (!address) return '0x0000...0000'; // Handle undefined addresses
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

// Function to generate a unique background color based on the address
const getColorForAddress = (address) => {
  if (!address) return '#000000'; // Handle undefined addresses
  const hash = Math.abs(address.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0));
  const color = `hsl(${hash % 360}, 70%, 50%)`; // Generate a color using HSL
  return color;
};

const TestChat = () => {
  const { address, isConnected } = useAccount(); // Use wagmi to get the connected account
  const isAdmin = adminAddresses.includes(address?.toLowerCase()); // Check if the connected wallet is an admin

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [editingMessage, setEditingMessage] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const chatEndRef = useRef(null);

  // Sync messages with Firebase
  useEffect(() => {
    const messagesRef = query(ref(database, 'messages'), limitToLast(100)); // Get the last 100 messages

    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const loadedMessages = Object.values(data);
        setMessages(loadedMessages);
      } else {
        setMessages([]); // Clear messages if there's no data
      }
    });

    return () => {
      // Unsubscribe from the Firebase listener when the component unmounts
    };
  }, []);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (newMessage) {
      setIsTyping(true);
    } else {
      setIsTyping(false);
    }
  }, [newMessage]);

  const handleSendMessage = () => {
    if (!isConnected) {
      alert('Please connect your wallet to send messages.');
      return;
    }

    if (editingMessage) {
      const updatedMessages = messages.map((msg) =>
        msg.id === editingMessage.id ? { ...msg, text: newMessage } : msg
      );
      setMessages(updatedMessages);
      set(ref(database, `messages/${editingMessage.id}`), {
        ...editingMessage,
        text: newMessage,
      });
      setEditingMessage(null);
    } else if (newMessage.trim()) {
      const newId = messages.length > 0 ? messages[messages.length - 1].id + 1 : 1;
      const newMessageObj = {
        id: newId,
        text: newMessage,
        sender: address,
        system: false,
        role: isAdmin ? 'Admin' : 'User',
      };

      // Save to Firebase
      set(ref(database, `messages/${newId}`), newMessageObj);

      setMessages([...messages, newMessageObj]);
    }
    setNewMessage('');
    setShowEmojiPicker(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleEmojiSelect = (emojiObject) => {
    setNewMessage((prevMessage) => prevMessage + emojiObject.emoji);
  };

  const handleEditMessage = (msg) => {
    setEditingMessage(msg);
    setNewMessage(msg.text);
  };

  const handleDeleteMessage = (id) => {
    const updatedMessages = messages.filter((msg) => msg.id !== id);
    setMessages(updatedMessages);
    remove(ref(database, `messages/${id}`)); // Remove from Firebase
  };

  const handleClearChat = () => {
    if (window.confirm('Are you sure you want to clear the chat? This action cannot be undone.')) {
      remove(ref(database, 'messages')); // Clear all messages from Firebase
      setMessages([]); // Clear all messages locally
    }
  };

  const handleMuteUser = (userId, duration) => {
    alert(`User ${userId} muted for ${duration}.`);
    // Implementation for muting users can be added here
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      backgroundColor: '#161A42',  // Chat container background color
      color: 'white'
    }}>
      {/* Header Section (Fixed at the top) */}
      <div style={{
        backgroundColor: '#161A42',  // Header background color
        textAlign: 'center',
        padding: '10px',
        fontSize: '18px',
        fontWeight: 'bold',
        color: 'white',
        borderBottom: '0px solid #075985',
        marginBottom: '15px',
        borderRadius: '15px 15px 0 0',
        position: 'sticky',
        borderBottom: '1px solid #2A2F50',
        top: 0,
        zIndex: 1000
      }}>
        Degen Chat
      </div>

      <div style={{
        flexGrow: 1,
        overflowY: 'auto',
        padding: '10px',
        paddingBottom: '70px',  // Adds space at the bottom so last message is not hidden behind input
        backgroundColor: '#161A42',  // Chat container background color
        display: 'flex',
        flexDirection: 'column', // Normal column direction
        justifyContent: 'flex-start' // Messages stack normally from top to bottom
      }}>
        {messages.map((msg) => (
          <div key={msg.id} style={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '10px'
          }}>
            {!msg.system && (
              <div style={{ position: 'relative', marginRight: '10px', flexShrink: 0 }}>
                <img
                  src={getAvatarForAddress(msg.sender)}
                  alt="avatar"
                  style={{ width: '40px', height: '40px', borderRadius: '50%', border: '3px solid #F2C94C', marginTop: '4px', }}
                />
                <div style={{
                  position: 'absolute',
                  bottom: '-10px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  backgroundColor: getColorForAddress(msg.sender), // Set unique color for each user
                  color: '#ffff',
                  padding: '1px 4px',
                  borderRadius: '10px',
                  fontSize: '8px',
                  fontWeight: '400',
                  whiteSpace: 'nowrap'
                }}>
                  {getDgnNumberForAddress(msg.sender)}
                </div>
              </div>
            )}
            <div style={{ flexGrow: 1 }}>
              {/* Display Moderator for admins */}
              {!msg.system && (
                <div style={{ fontSize: '8px', color: '#888', marginBottom: '1px' }}>
                  {adminAddresses.includes(msg.sender.toLowerCase()) ? 'Moderator' : trimAddress(msg.sender)}
                </div>
              )}
              <div style={{
                backgroundColor: msg.system ? '#ff9900' : '#212760',  // Chat bubble background color
                borderRadius: '8px',
                padding: '10px',
                position: 'relative'
              }}>
                <div style={{ fontSize: '15px', fontWeight: 'normal' }}>{msg.text}</div>
                {isAdmin && (
                  <div style={{ position: 'absolute', top: '-15px', right: '10px', display: 'flex', gap: '5px' }}>
                    <FaEdit 
                      title="Edit Message" 
                      onClick={() => handleEditMessage(msg)} 
                      style={{ cursor: 'pointer', color: '#ffcc00', fontSize: '12px' }} 
                    />
                    <FaTrash 
                      title="Delete Message" 
                      onClick={() => handleDeleteMessage(msg.id)} 
                      style={{ cursor: 'pointer', color: 'red', fontSize: '12px' }} 
                    />
                    <FaBan 
                      title="Mute User (1 Day)" 
                      onClick={() => handleMuteUser(msg.sender, '1 day')} 
                      style={{ cursor: 'pointer', color: 'orange', fontSize: '12px' }} 
                    />
                    <FaBan 
                      title="Mute User (1 Week)" 
                      onClick={() => handleMuteUser(msg.sender, '1 week')} 
                      style={{ cursor: 'pointer', color: 'darkred', fontSize: '12px' }} 
                    />
                    <FaEraser 
                      title="Clear Chat" 
                      onClick={handleClearChat} 
                      style={{ cursor: 'pointer', color: '#3461FF', fontSize: '12px' }} 
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        <div ref={chatEndRef} />
      </div>

      <div style={{
        padding: '10px',
        borderTop: '1px solid #2A2F50',
        display: 'flex',
        gap: '10px',
        position: 'sticky',
        bottom: '60px',
        zIndex: 1000,
        backgroundColor: '#161A42'  // Chat input background color
      }}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder={!isConnected ? 'Connect Wallet' : 'Type your message...'}
          maxLength={180} // Limit the message length to 180 characters
          onKeyPress={handleKeyPress}
          style={{
            flexGrow: 1,
            padding: '10px',
            borderRadius: '5px',
            border: 'none',
            outline: 'none',
            color: 'white',
            backgroundColor: '#161A42'  // Chat input background color
          }}
          disabled={!isConnected} // Disable input if not connected
        />
        <button onClick={() => setShowEmojiPicker(!showEmojiPicker)} style={{
          padding: '10px',
          backgroundColor: '#3461FF',
          color: 'white',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer'
        }}
          disabled={!isConnected} // Disable button if not connected
        >
          😊
        </button>
        <button onClick={handleSendMessage} style={{
          padding: '10px',
          backgroundColor: '#3461FF',
          color: 'white',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer'
        }}
          disabled={!isConnected} // Disable button if not connected
        >
          {editingMessage ? 'Update' : 'Send'}
        </button>
        {showEmojiPicker && (
          <div style={{
            position: 'absolute',
            bottom: '60px',
            left: '0',
            zIndex: 1000
          }}>
            <EmojiPicker
              onEmojiClick={handleEmojiSelect}
              theme="dark"
              pickerStyle={{ fontSize: '10px' }} // Increase size of emojis in the picker
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TestChat;
