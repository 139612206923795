import React, { useState, useEffect } from "react";
import BackgroundImage from "../images/trenches.png";

function NoticeModal({ onClose }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const hasSeenNotice = localStorage.getItem("hasSeenNotice");
    if (!hasSeenNotice) {
      setIsVisible(true);
    }

    const updateWindowDimensions = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  const handleDoNotShowAgain = () => {
    localStorage.setItem("hasSeenNotice", "true");
    setIsVisible(false);
    onClose();
  };

  if (!isVisible) return null;

  const modalStyle = {
    backgroundColor: "rgba(30, 30, 30, 0.95)",
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundBlendMode: "multiply",
    padding: "30px",
    borderRadius: "12px",
    border: "1px solid #556B2F", // Army color border
    boxShadow: "0px 0px 10px rgba(85, 107, 47, 0.8)", // Small glow effect in army green
    width: "90%",
    maxWidth: "500px",
    minHeight: "50vh",
    maxHeight: "85vh",
    overflowY: "auto",
    textAlign: "center",
    color: "#fff",
    position: "fixed",
    top: "0", // Ensure it sticks to the top for mobile
    left: "50%",
    transform: "translateX(-50%)",
    paddingBottom: "20px",
    zIndex: 10000
  };

  return (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.85)",
      backdropFilter: "blur(5px)",
      WebkitBackdropFilter: "blur(5px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start", // Ensure modal is at the top for mobile
      zIndex: 9999,
    }}>
      <div style={modalStyle}>
        <h4 style={headerTextStyle}>WELCOME TO THE TRENCHES</h4> <br></br>
        <div style={contentStyle}>
          <p style={paragraphStyle}>
            Pick a Coin, Buy & Sell at any time!
          </p>
          <p style={paragraphStyle}>
            Fair Launches & Pump Sales
          </p> <br></br>
          <p style={paragraphStyle}>
            or
          </p> <br></br>
          <p style={paragraphStyle}>
            Create Advanced Meme Coins
          </p>

          <p style={paragraphStyle}>
            Launch on <strong style={textStyle}>Base, Linea, or BNB Chain</strong>
          </p> 
          <p style={paragraphStyle}>
            Zero Code Knowledge Required
          </p>
          <br></br>
        </div>
        <div style={buttonContainerStyle}>
          <button style={closeButtonStyle} onClick={handleClose}>Close</button>
          <button style={dontShowButtonStyle} onClick={handleDoNotShowAgain}>Don't Show Again</button>
        </div>
      </div>
    </div>
  );
}

const headerTextStyle = {
  fontSize: "22px",
  fontWeight: "bold",
  color: "#4CAF50",
  marginBottom: "15px",
  lineHeight: "1.2",
  textTransform: "uppercase"
};

const contentStyle = {
  color: "#fff",
  fontSize: "16px",
  lineHeight: "1.5"
};

const textStyle = {
  color: "#fff",
  fontWeight: "600"
};

const paragraphStyle = {
  marginBottom: "12px",
  color: "#fff"
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "center",
  gap: "10px",
  marginTop: "20px"
};

const baseButtonStyle = {
  padding: "8px 20px",
  border: "none",
  borderRadius: "6px",
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "500",
  backgroundColor: "#4379FF",
  color: "#fff",
  transition: "background-color 0.2s"
};

const closeButtonStyle = {
  ...baseButtonStyle
};

const dontShowButtonStyle = {
  ...baseButtonStyle
};

export default NoticeModal;