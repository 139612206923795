import React, { useState } from 'react';

/*
import { useAccount, useChainId } from 'wagmi';
import { writeContract, waitForTransactionReceipt } from '@wagmi/core';
import { parseEther } from 'ethers/lib/utils';
import ERC20Abi from '../../json/ERC20.json';
import StakingAbi from '../../json/StakingPool.json';
import { config } from '../../config';
*/
import { toast } from 'react-toastify';
import { Copy, ExternalLink } from 'lucide-react';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts';

const StakingPool = () => {
  /*
  const { address: account } = useAccount();
  const chainId = useChainId();
  */
  const [activeTab, setActiveTab] = useState('about');
  const [inputAmount, setInputAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [stakingData, setStakingData] = useState({
    totalStaked: '900,993,959',
    userStaked: '0',
    availableRewards: '0',
    stakingTokenBalance: '0',
    stakingTokenAllowance: '0',
    apr: '0',
    lockPeriod: '0',
    minStake: '5,000',
    maxStake: '100M',
    tokenName: 'HOLD',
    tokenSymbol: 'EARN',
    stakeAddress: '0xD7f1318845...30a275DF6',
    tokenAddress: '0x803b6...eAC66'
  });

  // Mock chart data
  const chartData = [
    { timestamp: 1641024000, value: 100 },
    { timestamp: 1641027600, value: 105 },
    { timestamp: 1641031200, value: 103 },
    { timestamp: 1641034800, value: 107 },
    { timestamp: 1641038400, value: 110 },
    { timestamp: 1641042000, value: 108 },
    { timestamp: 1641045600, value: 112 }
  ];

  /*
  const contracts = {
    stakingToken: "YOUR_TOKEN_ADDRESS",
    stakingPool: "YOUR_STAKING_POOL_ADDRESS"
  };

  useEffect(() => {
    if (account) {
      fetchStakingData();
    }
  }, [account]);

  const fetchStakingData = async () => {
    try {
      // Add calls to fetch staking data from smart contract
      console.log("Fetching staking data...");
    } catch (error) {
      console.error("Error fetching staking data:", error);
    }
  };
  */

  const handleApprove = async () => {
    setLoading(true);
    try {
      /*
      const amount = parseEther("1000000000000000000000000000").toString();
      const result = await writeContract(config, {
        abi: ERC20Abi,
        address: contracts.stakingToken,
        functionName: 'approve',
        args: [contracts.stakingPool, amount],
      });
      toast.promise(new Promise(resolve => setTimeout(resolve, 5000)), {
        pending: "Approving tokens..."
      });
      const receipt = await waitForTransactionReceipt(config, { hash: result });
      if (receipt) {
        toast.success("Token approval successful!");
        await fetchStakingData();
      }
      */
      toast.success("Token approval successful!");
    } catch (error) {
      console.error("Approval error:", error);
      toast.error(error.reason || "Failed to approve tokens");
    }
    setLoading(false);
  };

  const handleStake = async () => {
    if (!inputAmount) return;
    setLoading(true);
    try {
      /*
      const amount = parseEther(inputAmount).toString();
      const result = await writeContract(config, {
        abi: StakingAbi,
        address: contracts.stakingPool,
        functionName: 'stake',
        args: [amount],
      });
      toast.promise(new Promise(resolve => setTimeout(resolve, 5000)), {
        pending: "Staking tokens..."
      });
      const receipt = await waitForTransactionReceipt(config, { hash: result });
      if (receipt) {
        toast.success("Tokens staked successfully!");
        setInputAmount('');
        await fetchStakingData();
      }
      */
      toast.success("Tokens staked successfully!");
      setInputAmount('');
    } catch (error) {
      console.error("Staking error:", error);
      toast.error(error.reason || "Failed to stake tokens");
    }
    setLoading(false);
  };

  const handleWithdraw = async () => {
    if (!inputAmount) return;
    setLoading(true);
    try {
      /*
      const amount = parseEther(inputAmount).toString();
      const result = await writeContract(config, {
        abi: StakingAbi,
        address: contracts.stakingPool,
        functionName: 'withdraw',
        args: [amount],
      });
      toast.promise(new Promise(resolve => setTimeout(resolve, 5000)), {
        pending: "Withdrawing tokens..."
      });
      const receipt = await waitForTransactionReceipt(config, { hash: result });
      if (receipt) {
        toast.success("Tokens withdrawn successfully!");
        setInputAmount('');
        await fetchStakingData();
      }
      */
      toast.success("Tokens withdrawn successfully!");
      setInputAmount('');
    } catch (error) {
      console.error("Withdrawal error:", error);
      toast.error(error.reason || "Failed to withdraw tokens");
    }
    setLoading(false);
  };

  const handleClaimRewards = async () => {
    setLoading(true);
    try {
      /*
      const result = await writeContract(config, {
        abi: StakingAbi,
        address: contracts.stakingPool,
        functionName: 'claimRewards',
      });
      toast.promise(new Promise(resolve => setTimeout(resolve, 5000)), {
        pending: "Claiming rewards..."
      });
      const receipt = await waitForTransactionReceipt(config, { hash: result });
      if (receipt) {
        toast.success("Rewards claimed successfully!");
        await fetchStakingData();
      }
      */
      toast.success("Rewards claimed successfully!");
    } catch (error) {
      console.error("Claim error:", error);
      toast.error(error.reason || "Failed to claim rewards");
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        width: '100%',
        minHeight: '100vh',
        fontFamily: "'Quicksand', sans-serif",
        backgroundColor: '#0A0B1E',
        color: '#FFFFFF',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      {/* Main Content Container */}
      <div
        style={{
          width: '100%',
          maxWidth: '1200px',
          padding: '16px',
          backgroundColor: '#161A42',
          borderRadius: '12px',
          marginBottom: '20px'
        }}
      >
        {/* Header Card */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '24px',
          }}
        >
          <h1 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '8px' }}>
            {stakingData.tokenName}
          </h1>
          <span style={{ color: '#22C55E', fontSize: '1rem' }}>LIVE</span>
        </div>

        {/* Tab Navigation */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
            paddingBottom: '10px',
            marginBottom: '20px'
          }}
        >
          <button
            onClick={() => setActiveTab('about')}
            style={{
              backgroundColor: activeTab === 'about' ? '#3461FF' : 'transparent',
              color: activeTab === 'about' ? '#FFFFFF' : '#7B91B0',
              padding: '8px 16px',
              borderRadius: '8px',
              marginRight: '10px',
              cursor: 'pointer',
              border: 'none',
              fontSize: '1rem',
            }}
          >
            About
          </button>
          <button
            onClick={() => setActiveTab('stakePool')}
            style={{
              backgroundColor: activeTab === 'stakePool' ? '#3461FF' : 'transparent',
              color: activeTab === 'stakePool' ? '#FFFFFF' : '#7B91B0',
              padding: '8px 16px',
              borderRadius: '8px',
              cursor: 'pointer',
              border: 'none',
              fontSize: '1rem',
            }}
          >
            Stake Pool
          </button>
        </div>

        {/* Tab Content */}
        <div style={{ marginTop: '20px', width: '100%' }}>
          {activeTab === 'about' ? (
            // About Tab Content
            <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
              {[
                { label: 'Token Name', value: stakingData.tokenName },
                { label: 'Token Symbol', value: stakingData.tokenSymbol },
                { label: 'Stake Address', value: stakingData.stakeAddress, copyable: true },
                { label: 'Reward Type', value: 'Fixed Reward' },
                { label: 'Reward Ratio (year)', value: '26.6372484967821%' },
                { label: 'Min Amount to Stake', value: stakingData.minStake },
                { label: 'Hard Cap', value: stakingData.maxStake },
                { label: 'Total Staked', value: stakingData.totalStaked },
                { label: 'Total Stakers', value: '8' },
                { label: 'Staked Token transferrable', value: 'Yes' },
              ].map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px 0',
                    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                    fontSize: '1rem',
                  }}
                >
                  <span style={{ color: '#7B91B0' }}>{item.label}</span>
                  <div style={{ display: 'flex', alignItems: 'center', color: item.copyable ? '#22C55E' : '#FFFFFF' }}>
                    {item.value}
                    {item.copyable && (
                      <>
                        <button style={{ background: 'none', border: 'none', color: '#7B91B0', marginLeft: '5px', cursor: 'pointer' }}>
                          <Copy size={16} />
                        </button>
                        <button style={{ background: 'none', border: 'none', color: '#7B91B0', marginLeft: '5px', cursor: 'pointer' }}>
                          <ExternalLink size={16} />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            // Stake Pool Tab Content
            <div style={{ display: 'grid', gap: '20px', gridTemplateColumns: '1fr', width: '100%' }}>
              {/* Chart Area */}
              <div style={{ backgroundColor: '#1A1B26', borderRadius: '12px', padding: '16px', height: '300px' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={chartData}>
                    <defs>
                      <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#3461FF" stopOpacity={0.3} />
                        <stop offset="95%" stopColor="#3461FF" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <XAxis dataKey="timestamp" hide={true} />
                    <YAxis hide={true} />
                    <Tooltip
                      contentStyle={{
                        background: '#161A42',
                        border: '1px solid #3461FF',
                        borderRadius: '8px',
                        color: '#FFFFFF',
                      }}
                    />
                    <Area
                      type="monotone"
                      dataKey="value"
                      stroke="#3461FF"
                      fillOpacity={1}
                      fill="url(#colorValue)"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>

              {/* Staking Input */}
              <div style={{ backgroundColor: '#1A1B26', borderRadius: '12px', padding: '16px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                  <span style={{ color: '#7B91B0' }}>Amount</span>
                  <button
                    onClick={() => setInputAmount(stakingData.stakingTokenBalance)}
                    style={{
                      color: '#22C55E',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      fontSize: '1rem',
                    }}
                  >
                    MAX
                  </button>
                </div>
                <input
                  type="text"
                  value={inputAmount}
                  onChange={(e) => setInputAmount(e.target.value)}
                  placeholder="0.0"
                  style={{
                    width: '100%',
                    backgroundColor: 'transparent',
                    color: '#FFFFFF',
                    fontSize: '1.2rem',
                    border: 'none',
                    outline: 'none',
                  }}
                />
                <div style={{ color: '#7B91B0', marginTop: '10px' }}>
                  Balance: {stakingData.stakingTokenBalance} {stakingData.tokenSymbol}
                </div>
              </div>

              {/* Action Buttons */}
              <div style={{ display: 'flex', gap: '10px' }}>
                <button
                  onClick={handleStake}
                  disabled={loading}
                  style={{
                    flex: 1,
                    padding: '12px',
                    backgroundColor: '#3461FF',
                    color: '#FFFFFF',
                    fontSize: '1rem',
                    fontWeight: '500',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    opacity: loading ? 0.5 : 1,
                  }}
                >
                  {loading ? "Processing..." : "Stake"}
                </button>
                <button
                  onClick={handleWithdraw}
                  disabled={loading}
                  style={{
                    flex: 1,
                    padding: '12px',
                    backgroundColor: '#1A1B26',
                    color: '#FFFFFF',
                    fontSize: '1rem',
                    fontWeight: '500',
                    border: '1px solid #3461FF',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    opacity: loading ? 0.5 : 1,
                  }}
                >
                  {loading ? "Processing..." : "Withdraw"}
                </button>
              </div>

              {/* Rewards Section */}
              <div style={{ backgroundColor: '#1A1B26', borderRadius: '12px', padding: '16px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ color: '#7B91B0' }}>My Staked Amount</span>
                  <span style={{ color: '#FFFFFF' }}>{stakingData.userStaked} {stakingData.tokenSymbol}</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                  <span style={{ color: '#7B91B0' }}>My Withdrawable Rewards</span>
                  <span style={{ color: '#FFFFFF' }}>{stakingData.availableRewards} {stakingData.tokenSymbol}</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                  <span style={{ color: '#7B91B0' }}>Token Address</span>
                  <div style={{ display: 'flex', alignItems: 'center', color: '#22C55E' }}>
                    {stakingData.tokenAddress}
                    <button style={{ background: 'none', border: 'none', color: '#7B91B0', marginLeft: '5px', cursor: 'pointer' }}>
                      <Copy size={16} />
                    </button>
                    <button style={{ background: 'none', border: 'none', color: '#7B91B0', marginLeft: '5px', cursor: 'pointer' }}>
                      <ExternalLink size={16} />
                    </button>
                  </div>
                </div>
                {Number(stakingData.availableRewards) > 0 && (
                  <button
                    onClick={handleClaimRewards}
                    disabled={loading}
                    style={{
                      width: '100%',
                      padding: '12px',
                      backgroundColor: '#22C55E',
                      color: '#FFFFFF',
                      fontSize: '1rem',
                      fontWeight: '500',
                      borderRadius: '8px',
                      cursor: 'pointer',
                      marginTop: '20px',
                      opacity: loading ? 0.5 : 1,
                    }}
                  >
                    {loading ? "Processing..." : "Claim Rewards"}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StakingPool;
