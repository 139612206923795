import React, { useState, useContext, useEffect } from "react";
import "../launchpadApply/index.css";
import { useWeb3React } from "@web3-react/core";
import { getWeb3Contract } from '../../hooks/contractHelper';
import tokenAbi from '../../json/token.json';
import airdropAbi from '../../json/Airdrop.json';
import { getWeb3 } from '../../hooks/connectors';
import { contract } from '../../hooks/constant';
import { getContract } from '../../hooks/contractHelper';
import LoaderComponent from '../../component/LoaderComponent';
import Button from "react-bootstrap-button-loader";
import { toast } from "react-toastify";
import { parseEther, parseUnits } from "@ethersproject/units";
import { useAccount, useChainId } from "wagmi";
import { config } from "../../config";
import { writeContract, waitForTransactionReceipt } from "@wagmi/core";
import AirdropIcon from '../../images/AirdropIcon.svg';


export default function AirdropTokens() {
  const chainId = useChainId();
  const { address: account } = useAccount();
  const [isLoading, setIsLoading] = useState(false);
  const [createloading, setCreateloading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [value, setValue] = useState({ tokenAddress: '', tokenName: '', tokenSymbol: '', tokenDecimal: 0, maxAmount: 0, airdropAmount: 0, airdropAddrs: [] });
  const [airdropText, setAirdropText] = useState("");
  const [error, setError] = useState({
    tokenAddress: "",
    maxAmount: "",
    airdropAmount: "",
    airdropText: "",
  });
  const [serviceFee, setServiceFee] = useState(0.002);

  // Styles
  const headerContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '1200px',
    margin: '0 0',
    padding: '20px',
    marginBottom: '0px',
    marginTop: '24px',
  };

  const leftHeaderStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  };

  const logoStyle = {
    width: '35px',
    height: '35px',
    marginLeft: '30px',
    marginRight: '7px',
  };

  const headerStyle = {
    color: '#fff',
    fontSize: '24px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '600',
    margin: '0',
  };

  const subHeaderStyle = {
    color: '#7B91B0',
    fontSize: '14px',
    fontFamily: 'Quicksand, sans-serif',
    marginTop: '4px',
    margin: '0',
  };

  const containerStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0px',
    marginTop: '0',
    marginBottom: '0px',
};

const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    maxWidth: '1300px',  // Updated from 800px to 1400px
    background: '#111432',
    borderRadius: '8px',
    padding: '20px',
    border: '1px solid #075985',
};

  const labelStyle = {
    color: '#7B91B0',
    fontSize: '14px',
    fontWeight: '600',
    display: 'block',
    marginBottom: '8px',
    marginTop: '15px',

  };

  const inputStyle = {
    width: '100%',
    height: '50px',
    background: '#161A42',
    borderRadius: '8px',
    border: '1px solid #212554',
    padding: '10px',
    color: '#fff',
    fontSize: '14px',
  };

  const textareaStyle = {
    ...inputStyle,
    height: '100px',
    resize: 'none',
    marginBottom: '20px',
  };

  const buttonStyle = {
    width: '400px',
    height: '50px',
    background: '#2F6EFF',
    borderRadius: '8px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: '600',
    border: 'none',
    cursor: 'pointer',
    transition: 'background 0.2s',
    marginTop: '24px',
    padding: '0 32px',
    display: 'block',
    margin: '0px auto 0',
    marginBottom: '20px',

  };

  const cardStyle = {
    backgroundColor: '#1B2139',
    borderRadius: '8px',
    padding: '20px',
    color: '#FFFFFF',
  };

  const detailStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
  };

  const errorTextStyle = {
    color: 'red',
    fontSize: '12px',
  };

  const feeInfoContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px',
    alignItems: 'center',
    width: '400px',
    margin: '0 auto',
    marginBottom: '15px',
    marginTop: '50px',

  };

  // Mobile styles
  const mobileHeaderContainerStyle = {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    padding: '16px',
    marginBottom: '20px',
  };

  const mobileHeaderStyle = {
    fontSize: '20px',
  };

  const mobileSubHeaderStyle = {
    fontSize: '12px',
    marginTop: '4px',
  };

  // Add these
const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

useEffect(() => {
  const handleResize = () => setIsMobile(window.innerWidth <= 600);
  window.addEventListener("resize", handleResize);
  return () => window.removeEventListener("resize", handleResize);
}, []);

  useEffect(() => {
    if (!chainId || !account || !value.tokenAddress) return;

    if (chainId === 56) {
      setServiceFee(0.015);
    }

    const getTokenInfo = async () => {
      let web3 = getWeb3(chainId);
      let checkAddress = await web3.utils.isAddress(value.tokenAddress);
      if (checkAddress) {
        let tokenContract = await getWeb3Contract(tokenAbi, value.tokenAddress, chainId);
        let tokenName = await tokenContract.methods.name().call();
        let tokenDecimal = await tokenContract.methods.decimals().call();
        let tokenSymbol = await tokenContract.methods.symbol().call();
        let maxAmount = await tokenContract.methods.balanceOf(account).call();
        
        if (tokenDecimal > 0) {
          let maxAmountFit = maxAmount / (10 ** tokenDecimal);
          setValue({ ...value, tokenName, tokenSymbol, tokenDecimal, maxAmount: maxAmountFit });
        }
      }
    };
    
    getTokenInfo();
  }, [chainId, account, value.tokenAddress]);


  const resolveAfter3Sec = new Promise((resolve) => setTimeout(resolve, 10000));

  const checkStandardAllValidation = () => {
    let terror = 0;
    if (!value.airdropAddrs || value.airdropAddrs.length == 0) {
      setError({ ...error, airdropText: "Please input addresses!" });
      return false;
    }
    Object.keys(value).map((key, index) => {
      switch (key) {
        case "tokenAddress":
          if (value[key] === "") {
            setError({ ...error, tokenAddress: "Please input token address!" });
            toast.error("Please input token address!");
            terror += 1;
          }
          break;
        case "airdropAmount":
          if (value[key] < 0 || value[key] * value.airdropAddrs.length > value.maxAmount) {
            setError({ ...error, airdropAmount: "Your balance is not enough!" });
            toast.error("Your balance is not enough!")
            terror += 1;
          }
          break;
        case "airdropAddrs":
          let web3 = getWeb3(chainId);
          let checkAddress = true;
          for (let i = 0; i < value[key].length; i++) {
            checkAddress = web3.utils.isAddress(value[key][i]);
          }
          if (!checkAddress) {
            setError({ ...error, airdropText: "Please input valid addresses!" });
            toast.error("Please input valid addresses!");
            terror += 1;
          }
          break;
        default:
          terror += 0;
          break;
      }
      return true;
    });
    if (terror > 0) {
      return false;
    } else {
      return true;
    }
  };
  const handleApprove = async (e) => {
    if (!chainId || !account) {
      return;
    }
    if (value.tokenAddress) {
      setCreateloading(true);
      let airdropAddress = contract[chainId].airdrop;
      // let tokenContract = getContract(
      //     tokenAbi,
      //     value.tokenAddress,
      //     library
      // );
      let amount = parseEther("1000000000000000000000000000").toString();
      // let tx = await tokenContract.approve(airdropAddress, amount, { from: account });
      const result = await writeContract(config, {
        abi: tokenAbi,
        address: value.tokenAddress,
        functionName: 'approve',
        args: [airdropAddress, amount]
      });

      toast.promise(resolveAfter3Sec, { pending: "Waiting for confirmation 👌" });

      var interval = setInterval(async function () {
        let web3 = getWeb3(chainId);
        const response = await waitForTransactionReceipt(config, { hash: result });
        if (response && response.status && response.status == 'success') {
          clearInterval(interval);
          if (response.status === 'success') {
            toast.success(
              "success ! transaction successful 👍"
            );
            setCreateloading(false);
            setIsApproved(true);
          } else if (response.status === false) {
            toast.error("error ! Your transaction has failed.");
            setCreateloading(false);
          } else {
            toast.error("error ! something went wrong.");
            setCreateloading(false);
          }
        }
      }, 5000);
    } else {
      toast.error("Please enter a valid token address !");
      setCreateloading(false);
    }

  }
  const handleAirdrop = async (e) => {
    e.preventDefault();
    let check = false;
    try {

      if (!account) {
        toast.error("error ! connect wallet!");
        setCreateloading(false);
        return;
      }
      check = checkStandardAllValidation();
      if (check) {
        let airdropAddress = contract[chainId].airdrop;
        setCreateloading(true);
        let airdropAmounts = [];
        for (let i = 0; i < value.airdropAddrs.length; i++) {
          airdropAmounts.push(parseUnits(value.airdropAmount.toString(), Number(value.tokenDecimal)));
        }
        // let airdropContract = getContract(
        //   airdropAbi,
        //   airdropAddress,
        //   library
        // );
        // let tx = await airdropContract.distrubuteToken(value.tokenAddress, value.airdropAddrs, airdropAmounts, { from: account, value: parseEther(serviceFee.toString()).toString() });
        const result = await writeContract(config, {
          abi: airdropAbi,
          address: airdropAddress,
          functionName: 'distrubuteToken',
          args: [value.tokenAddress, value.airdropAddrs, airdropAmounts],
          value: parseEther(serviceFee.toString()).toString()
        });
        toast.promise(resolveAfter3Sec, { pending: "Waiting for confirmation 👌" });

        var interval = setInterval(async function () {
          let web3 = getWeb3(chainId);
          const response = await waitForTransactionReceipt(config, { hash: result });
          if (response && response.status && response.status == 'success') {
            clearInterval(interval);
            if (response.status === 'success') {
              toast.success(
                "success ! transaction successful 👍"
              );
              setCreateloading(false);
            } else if (response.status === false) {
              toast.error("error ! Your transaction has failed.");
              setCreateloading(false);
            } else {
              toast.error("error ! something went wrong.");
              setCreateloading(false);
            }
          }
        }, 5000);
      }

    }
    catch (err) {
      toast.error(err.reason);
    }
    setCreateloading(false);

  };
  const setAirdropAddrs = (e) => {
    e.preventDefault();
    if (!account || !chainId) {
      setValue({
        tokenAddress: "",
        maxAmount: "",
        airdropAmount: "",
        airdropText: "",
      });
      toast.error("error ! connect wallet!");
      return;
    }
    else {
      let addrs = e.target.value.split('\n');
      // checkStandardValidation(e.target.name, addrs);

      setValue({ ...value, "airdropAddrs": addrs });
    }
  }

  const onChangeInput = (e) => {
    e.preventDefault();
    if (!account || !chainId) {
      setValue({
        tokenAddress: "",
        maxAmount: "",
        airdropAmount: "",
        airdropText: "",
      });
      toast.error("error ! connect wallet!");
      return;
    }
    else {
      // checkStandardValidation(e.target.name, e.target.value);
      setValue({ ...value, [e.target.name]: e.target.value });
    }

  };
  const handleInput = async (e) => {
    try {
      // e.preventDefault();
      if (!chainId || !account) {
        toast.error("error ! connect wallet!");
        setIsLoading(false);
        return;
      }
      else {
        let web3 = getWeb3(chainId);
        let checkAddress = await web3.utils.isAddress(e.target.value);
        if (checkAddress) {
          let checkSumaddress = await web3.utils.toChecksumAddress(e.target.value)
          let isCode = await web3.eth.getCode(e.target.value);
          if (!checkSumaddress || isCode === '0x') {
            setError({ ...error, tokenAddress: 'Please enter a valid token address!' });
          }
          else {

            let tokenContract = await getWeb3Contract(tokenAbi, e.target.value, chainId);
            let tokenName = await tokenContract.methods.name().call();
            let tokenDecimal = await tokenContract.methods.decimals().call();
            let tokenSymbol = await tokenContract.methods.symbol().call();
            let maxAmount = await tokenContract.methods.balanceOf(account).call();
            if (tokenDecimal > 0) {
              let maxAmountFit = maxAmount / (10 ** tokenDecimal);
              setValue({ ...value, tokenAddress: e.target.value, tokenName, tokenSymbol, tokenDecimal, maxAmount: maxAmountFit });
              setError({
                tokenAddress: "",
                maxAmount: "",
                airdropAmount: "",
                airdropAddrs: "",
              });
            }
            else {
              setError({ ...error, tokenAddress: 'Please enter a valid token address!' });
            }
          }
        }
        else {
          // setValue({ ...value, ispoolExist : false , "tokenAddress": e.target.value, tokenName: "", tokenDecimal: "", tokenSymbol: "", isApprove: false });
          setError({ ...error, tokenAddress: 'Please enter a valid token address!' });
        }
      }

    }
    catch (err) {
      // setError(err.reason);
      toast.error(err.reason);
    }
    setIsLoading((prev) => !prev);
    return false;
  }
  return (
    <React.Fragment>
      {/* Header Section */}
      <div style={isMobile ? mobileHeaderContainerStyle : headerContainerStyle}>
        <div style={leftHeaderStyle}>
          <img src={AirdropIcon} alt="Logo" style={logoStyle} />
          <div>
            <h1 style={isMobile ? { ...headerStyle, ...mobileHeaderStyle } : headerStyle}>
              Airdrop Tokens
            </h1>
            <p style={isMobile ? { ...subHeaderStyle, ...mobileSubHeaderStyle } : subHeaderStyle}>
              Send project tokens to multiple wallets. Use this for running promos and rewards users.
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div style={containerStyle}>
        <div style={formStyle}>
          <form>
            <div className="form-group">
              <label style={labelStyle}>Token Address <span style={errorTextStyle}>*</span></label>
              <input
                style={inputStyle}
                type="text"
                name="tokenAddress"
                placeholder="Enter token address"
                onChange={(e) => { handleInput(e); setIsLoading(true); }}
              />
              <small style={errorTextStyle}>{error.tokenAddress}</small>
            </div>

            {isLoading && <LoaderComponent status={isLoading} />}

            {!isLoading && value.tokenName && (
              <div style={cardStyle}>
                <div style={detailStyle}><span>Name:</span> <span>{value.tokenName}</span></div>
                <div style={detailStyle}><span>Symbol:</span> <span>{value.tokenSymbol}</span></div>
                <div style={detailStyle}><span>Decimal:</span> <span>{value.tokenDecimal}</span></div>
                <div style={detailStyle}><span>Balance:</span> <span>{value.maxAmount}</span></div>
              </div>
            )}

            <div className="form-group">
              <label style={labelStyle}>Airdrop Amount <span style={errorTextStyle}>*</span></label>
              <input
                style={inputStyle}
                type="number"
                name="airdropAmount"
                placeholder="Amount per address"
                value={value.airdropAmount}
                onChange={(e) => onChangeInput(e)}
              />
              <small style={errorTextStyle}>{error.airdropAmount}</small>
            </div>

            <div className="form-group">
  <label style={labelStyle}>Address List <span style={errorTextStyle}>*</span></label>
  <textarea
    style={textareaStyle}
    name="airdropText"
    placeholder={`One address per line

0xDC24316b9AE028F1497c275EB9192a3Ea0f67022
0xDC24316b9AE028F1497c275EB9192a3Ea0f67022
0xDC24316b9AE028F1497c275EB9192a3Ea0f67022

`}
    onChange={(e) => setAirdropAddrs(e)}
  ></textarea>
  <small style={errorTextStyle}>{error.airdropText}</small>
</div>


            <div style={feeInfoContainerStyle}>
              <span>Total cost:</span>
              <span>{serviceFee} ETH</span>
            </div>

            <div className="text-center">
              {!isApproved ? (
                <Button
                  style={buttonStyle}
                  loading={createloading}
                  onClick={handleApprove}
                >
                  Approve
                </Button>
              ) : (
                <Button
                  style={buttonStyle}
                  loading={createloading}
                  onClick={handleAirdrop}
                >
                  Send Airdrop
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}