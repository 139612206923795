import React, { useContext, useEffect, useState } from "react";
import Context from "./context/Context";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { feesSetting } from "./context/defaults";
import { formatPrice } from "../../../hooks/contractHelper";
import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import { coinArray, convertDay } from "../../../hooks/constant";
import { getWeb3Contract } from "../../../hooks/contractHelper";
import tokenAbi from '../../../json/token.json';
import { BigNumber } from "ethers";
import { useAccount, useChainId } from "wagmi";

export default function Step2() {
  const { value, btnPrevStep, setValue } = useContext(Context);
  const context = useWeb3React();
  const chainId = useChainId();
  const { address: account } = useAccount();

  const [error, setError] = useState({
    saletoken: "",
    softcap: "",
    liquidity: "",
    starttime: "",
    endtime: "",
    llockup: "",
    balanceE: "",
  });
  const [totaltoken, setTotaltoken] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [showTooltip, setShowTooltip] = useState("");

  // Styles
  const styles = {
    container: {
      padding: '32px',
      background: '#0D1021',
      borderRadius: '16px',
      border: '1px solid rgba(255, 255, 255, 0.1)',
    },
    inputContainer: {
      marginBottom: '24px',
    },
    label: {
      color: '#34D399',
      fontSize: '14px',
      fontWeight: '400',
      marginBottom: '8px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    input: {
      width: '100%',
      height: '50px',
      background: '#111432',
      border: 'none',
      borderRadius: '8px',
      padding: '0 16px',
      color: '#fff',
      fontSize: '14px',
      outline: 'none',
    },
    select: {
      width: '100%',
      height: '50px',
      background: '#111432',
      borderRadius: '8px',
      border: 'none',
      padding: '0 16px',
      color: '#fff',
      fontSize: '14px',
      outline: 'none',
      appearance: 'none',
      backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'16\' height=\'16\' viewBox=\'0 0 16 16\'%3E%3Cpath fill=\'%23ffffff\' d=\'M4 6l4 4 4-4\'/%3E%3C/svg%3E")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 16px center',
      cursor: 'pointer',
    },
    header: {
      fontSize: '24px',
      color: '#fff',
      fontWeight: '600',
      marginBottom: '32px',
      textAlign: 'center',
    },
    requiredStar: {
      color: '#EF4444',
      marginLeft: '4px',
    },
    error: {
      color: '#EF4444',
      fontSize: '12px',
      marginTop: '4px',
    },
    tooltip: {
      position: 'relative',
      display: 'inline-block',
      marginLeft: '8px',
    },
    tooltipText: {
      visibility: 'hidden',
      width: '240px',
      backgroundColor: '#1F2937',
      color: '#7B91B0',
      textAlign: 'left',
      padding: '8px 12px',
      borderRadius: '6px',
      position: 'absolute',
      zIndex: 1,
      bottom: '150%',
      left: '50%',
      transform: 'translateX(-50%)',
      opacity: 0,
      transition: 'all 0.2s ease',
      fontSize: '12px',
      fontFamily: 'Quicksand, sans-serif',
      border: '1px solid #374151',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: '16px',
      marginTop: '32px',
    },
    button: {
      height: '50px',
      padding: '0 32px',
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: '600',
      border: 'none',
      cursor: 'pointer',
      minWidth: '150px',
      background: '#2F6EFF',
      color: '#fff',
    },
    backButton: {
      background: '#374151',
    },
    datePickerInput: {
      width: '100%',
      height: '50px',
      background: '#111432',
      border: 'none',
      borderRadius: '8px',
      padding: '0 16px',
      color: '#fff',
      fontSize: '14px',
      outline: 'none',
      cursor: 'pointer',
    },
    warningText: {
      color: '#FFB000',
      textAlign: 'center',
      margin: '24px 0',
      fontSize: '14px',
    }
  };

  // InfoIcon component
  const InfoIcon = ({ tooltip }) => (
    <div 
      style={styles.tooltip} 
      onMouseEnter={() => setShowTooltip(tooltip)}
      onMouseLeave={() => setShowTooltip("")}
    >
      <span style={{ color: '#7B91B0', cursor: 'pointer', fontSize: '14px' }}>ⓘ</span>
      <div style={{
        ...styles.tooltipText,
        visibility: showTooltip === tooltip ? 'visible' : 'hidden',
        opacity: showTooltip === tooltip ? 1 : 0,
      }}>
        {tooltip}
      </div>
    </div>
  );

  useEffect(() => {
    if (account && value.tokenAddress) {
      let tokenContract = getWeb3Contract(tokenAbi, value.tokenAddress, chainId);
      
      // Get balance only
      tokenContract.methods.balanceOf(account).call().then((rel) => {
        const realVal = rel / Number(10 ** value.tokenDecimal);
        setTokenBalance(realVal);
      }).catch(console.error);
    }
  }, [value]);

  useEffect(() => {
    if (!isNaN(value.saletoken) && !isNaN(value.liquidity)) {
      let totalToken =
        parseFloat(value.saletoken || 0) +
        parseFloat(((value.saletoken || 0) * (value.liquidity || 0)) / 100);
      let totalFees = parseFloat(
        ((value.saletoken || 0) *
          parseFloat(
            feesSetting[value.feesType].token + feesSetting[value.feesType].extra
          )) /
        100
      );
      let total = totalToken + totalFees;
      setTotaltoken(total);
    }
  }, [value]);

  const checkValidation = (input, inputValue) => {
    let terror = 0;
    let message = "";
    var reg;
    switch (input) {
      case "saletoken":
        if (!inputValue || inputValue === '') {
          terror += 1;
          message = "Please enter selling amount";
          break;
        }
        inputValue = parseFloat(inputValue);
        reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
        if (!reg.test(inputValue) || inputValue <= 0) {
          terror += 1;
          message = "Please enter a valid amount";
        } else {
          message = "";
        }
        break;

      case "softcap":
        inputValue = parseFloat(inputValue);
        reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
        if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Please Enter Valid Amount Ser!";
        } else {
          message = "";
        }
        break;
        
      case "liquidity":
        reg = new RegExp(/^\d+$/);
        if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Please Enter a Valid Amount bro!";
        } else if (parseFloat(inputValue) <= 50) {
          terror += 1;
          message = "Liquidity must be greater than 50% you filthy little rugger";
        } else if (parseFloat(inputValue) > 100) {
          terror += 1;
          message = "Liquidity must be less than 100%, you Chad";
        } else {
          message = "";
        }
        break;

      case "starttime":
        if (inputValue === "" || inputValue === null) {
          terror += 1;
          message = "Please enter valid date ser";
        } else if (inputValue < new Date()) {
          terror += 1;
          message = "Start Time must be after current time, check your watch bro";
        } else if (inputValue >= value.endtime) {
          terror += 1;
          message = "Start time needs to be before the End time Ser!";
        } else {
          message = "";
        }
        break;

      case "endtime":
        if (inputValue === "" || inputValue === null) {
          terror += 1;
          message = "Please enter a valid date";
        } else if (value.starttime >= inputValue) {
          terror += 1;
          message = "Start time needs to be before End time bro";
        } else {
          message = "";
        }
        break;

      case "llockup":
        reg = new RegExp(/^\d+$/);
        if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Please Ser, Enter a Valid Number!";
        } else {
          message = "";
        }
        break;

      default:
        terror += 0;
        break;
    }

    if (terror > 0) {
      setError({ ...error, [input]: message });
      return false;
    } else {
      if (input === "starttime" || input === "endtime") {
        setError({ ...error, starttime: "", endtime: "" });
      } else {
        setError({ ...error, [input]: "" });
      }
      return true;
    }
  };

  const checkAllValidation = () => {
    let terror = 0;
    var reg;
    Object.keys(value).forEach((key) => {
      switch (key) {
        case "saletoken":
          if (!value[key] || value[key] === '') {
            terror += 1;
          } else {
            const amount = parseFloat(value[key]);
            if (isNaN(amount) || amount <= 0) {
              terror += 1;
            }
          }
          break;

        case "softcap":
          reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
          if (!reg.test(parseFloat(value[key])) || parseFloat(value[key]) <= 0) {
            terror += 1;
          }
          break;

        case "liquidity":
          reg = new RegExp(/^\d+$/);
          if (!reg.test(value[key]) || parseFloat(value[key]) <= 0) {
            terror += 1;
          } else if (parseFloat(value[key]) <= 50) {
            terror += 1;
          } else if (parseFloat(value[key]) > 100) {
            terror += 1;
          }
          break;

        case "starttime":
          if (value[key] === "" || value[key] === null) {
            terror += 1;
          } else if (value[key] >= value.endtime) {
            terror += 1;
          }
          break;

        case "endtime":
          if (value[key] === "" || value[key] === null) {
            terror += 1;
          } else if (value.starttime >= value[key]) {
            terror += 1;
          }
          break;

        case "llockup":
          reg = new RegExp(/^\d+$/);
          if (!reg.test(value[key]) || parseFloat(value[key]) <= 0) {
            terror += 1;
          }
          break;

        default:
          break;
      }
    });

    if (tokenBalance < totaltoken) {
      terror += 1;
    }
    
    return terror === 0;
  };
  
  const onChangeInput = (e) => {
    e.preventDefault();
    checkValidation(e.target.name, e.target.value);
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handleStartTimeChange = (date) => {
    checkValidation("starttime", date);
    setValue({ ...value, starttime: date });
  };

  const handleEndTimeChange = (date) => {
    checkValidation("endtime", date);
    setValue({ ...value, endtime: date });
  };

  const btnNextStepValidation = () => {
    let check = checkAllValidation();
    if (check) {
      setValue({
        ...value,
        totaltoken: totaltoken,
        step: parseInt(value.step + 1),
      });
    } else {
      toast.error("All fields required bro! please check again");
    }
  };

  // Custom style for form grid and sections
  const formGridStyles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '24px',
  };

  // Custom DatePicker style overrides
  const datePickerCustomStyles = {
    customInput: {
      ...styles.input,
      cursor: 'pointer',
    },
    calendar: {
      background: '#111432',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      borderRadius: '8px',
      color: '#fff',
      '& .react-datepicker__header': {
        background: '#161A42',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      },
      '& .react-datepicker__time-container': {
        borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
      },
    }
  };

  // Helper text styles
  const helperTextStyle = {
    color: '#7B91B0',
    fontSize: '14px',
    marginTop: '8px',
  };

  // Section separator style
  const sectionSeparatorStyle = {
    margin: '32px 0',
    borderTop: '1px solid rgba(255, 255, 255, 0.1)',
  };

  // Warning message container style
  const warningContainer = {
    background: 'rgba(255, 176, 0, 0.1)',
    border: '1px solid #FFB000',
    borderRadius: '8px',
    padding: '12px',
    marginTop: '24px',
  };

  // Error message style
  const errorMessageStyle = {
    color: '#EF4444',
    fontSize: '14px',
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  };

  // Custom style for the token balance warning
  const balanceWarningStyle = {
    ...errorMessageStyle,
    padding: '8px 12px',
    background: 'rgba(239, 68, 68, 0.1)',
    borderRadius: '6px',
    marginTop: '16px',
  };

  // Button container styles
  const buttonGroupStyles = {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    marginTop: '32px',
  };

  // Additional helper components
  const RequiredStar = () => (
    <span style={styles.requiredStar}>*</span>
  );

  const HelperText = ({ children }) => (
    <small style={helperTextStyle}>{children}</small>
  );

  const ErrorMessage = ({ children }) => (
    <small style={styles.error}>{children}</small>
  );

  return (
    <div style={styles.container}>
      <h4 style={styles.header}>Add the Fair Launch details</h4>

      <div style={styles.inputContainer}>
        <label style={styles.label}>
          Total selling amount
          <RequiredStar />
          <InfoIcon tooltip="The total amount of tokens you want to add to the sale for users to buy." />
        </label>
        <input
          style={styles.input}
          value={value.saletoken}
          onChange={onChangeInput}
          type="text"
          name="saletoken"
          placeholder="e.g. 100"
        />
        <ErrorMessage>{error.saletoken}</ErrorMessage>
      </div>

      <div style={styles.inputContainer}>
        <label style={styles.label}>
          Refund type
          <InfoIcon tooltip="Tokens that do not sell can either be refunded to your wallet or burnt." />
        </label>
        <select
          style={styles.select}
          defaultValue="1"
          onChange={onChangeInput}
          name="refund"
        >
          <option value="1">Burn</option>
          <option value="0">Refund</option>
        </select>
      </div>

      <div style={formGridStyles}>
        <div style={styles.inputContainer}>
          <label style={styles.label}>
            Softcap ({coinArray[chainId]})
            <RequiredStar />
            <InfoIcon tooltip="The minimum amount required for the sale to be considered successful (Eg: 0.00001 ETH or BNB)" />
          </label>
          <input
            style={styles.input}
            onChange={onChangeInput}
            value={value.softcap}
            type="text"
            name="softcap"
            placeholder="e.g. 1"
          />
          <ErrorMessage>{error.softcap}</ErrorMessage>
        </div>

        <div style={styles.inputContainer}>
          <label style={styles.label}>
            Liquidity (%)
            <RequiredStar />
            <InfoIcon tooltip="The % of funds raised to be added to DEX LP. (Min 51%. The balance goes to your owner wallet)" />
          </label>
          <input
            style={styles.input}
            value={value.liquidity}
            onChange={onChangeInput}
            type="text"
            name="liquidity"
            placeholder="e.g. 55"
          />
          <ErrorMessage>{error.liquidity}</ErrorMessage>
        </div>
      </div>

      <div style={formGridStyles}>
        <div style={styles.inputContainer}>
          <label style={styles.label}>
            Start time (LocalTime)
            <RequiredStar />
            <InfoIcon tooltip="The start time of the presale." />
          </label>
          <DatePicker
            selected={value.starttime}
            onChange={handleStartTimeChange}
            isClearable
            placeholderText="Select Start Time!"
            minDate={new Date()}
            showDisabledMonthNavigation
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            className="date-picker-custom"
            calendarClassName="dark-calendar"
            customInput={
              <input 
                style={datePickerCustomStyles.customInput}
                placeholder="Select Start Time"
              />
            }
          />
          <ErrorMessage>{error.starttime}</ErrorMessage>
        </div>

        <div style={styles.inputContainer}>
          <label style={styles.label}>
            End time (LocalTime)
            <RequiredStar />
            <InfoIcon tooltip="The end time of the presale." />
          </label>
          <DatePicker
            selected={value.endtime}
            onChange={handleEndTimeChange}
            isClearable
            placeholderText="Select End Time!"
            minDate={new Date()}
            showDisabledMonthNavigation
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            className="date-picker-custom"
            calendarClassName="dark-calendar"
            customInput={
              <input 
                style={datePickerCustomStyles.customInput}
                placeholder="Select End Time"
              />
            }
          />
          <ErrorMessage>{error.endtime}</ErrorMessage>
        </div>
      </div>

      <div style={styles.inputContainer}>
        <label style={styles.label}>
          Liquidity lockup (days)
          <RequiredStar />
          <InfoIcon tooltip="The number of days liquidity will be locked." />
        </label>
        <input
          style={styles.input}
          value={value.llockup}
          type="text"
          onChange={onChangeInput}
          name="llockup"
          placeholder="e.g. 3"
        />
        <ErrorMessage>{error.llockup}</ErrorMessage>
      </div>

      {/* Warning Messages Section */}
      <div style={warningContainer}>
        <div style={styles.warningText}>
          Need {formatPrice(totaltoken)} {value.tokenSymbol} to create Fair Launch.
        </div>
        
        {tokenBalance < totaltoken && (
          <div style={balanceWarningStyle}>
            Your {value.tokenSymbol} balance is {tokenBalance}. Token balance is not enough!
          </div>
        )}
      </div>

      {/* Buttons Section */}
      <div style={buttonGroupStyles}>
        <button
          type="button"
          style={{ ...styles.button, ...styles.backButton }}
          onClick={btnPrevStep}
        >
          Back
        </button>
        <button
          type="button"
          style={styles.button}
          onClick={btnNextStepValidation}
        >
          Continue
        </button>
      </div>

      {/* Custom DatePicker Styles */}
      <style>
        {`
          .react-datepicker {
            background-color: #111432 !important;
            border: 1px solid rgba(255, 255, 255, 0.1) !important;
            color: #fff !important;
          }
          .react-datepicker__header {
            background-color: #161A42 !important;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
          }
          .react-datepicker__current-month,
          .react-datepicker__day-name,
          .react-datepicker__day,
          .react-datepicker__time-name {
            color: #fff !important;
          }
          .react-datepicker__day:hover {
            background-color: #2F6EFF !important;
          }
          .react-datepicker__day--selected {
            background-color: #2F6EFF !important;
            color: #fff !important;
          }
          .react-datepicker__time-container {
            border-left: 1px solid rgba(255, 255, 255, 0.1) !important;
          }
          .react-datepicker__time-container .react-datepicker__time {
            background: #111432 !important;
          }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
            color: #fff !important;
          }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
            background-color: #2F6EFF !important;
          }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
            background-color: #2F6EFF !important;
          }
          .react-datepicker__input-container input {
            background: #111432 !important;
            border: none !important;
            color: #fff !important;
            height: 50px !important;
            border-radius: 8px !important;
            padding: 0 16px !important;
            width: 100% !important;
            font-size: 14px !important;
          }
          .react-datepicker__input-container input::placeholder {
            color: #7B91B0 !important;
          }
          .react-datepicker__navigation-icon::before {
            border-color: #fff !important;
          }
          .react-datepicker__day--keyboard-selected {
            background-color: #2F6EFF !important;
          }
        `}
      </style>
    </div>
  );
}