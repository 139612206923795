import axios from 'axios';

class PriceService {
  constructor() {
    this.coingeckoAPI = 'https://api.coingecko.com/api/v3';
    this.priceCache = new Map();
    this.cacheDuration = 5 * 60 * 1000; // 5 minutes
  }

  async getNativeTokenPrice(chainId) {
    const chainMapping = {
      '1': 'ethereum',
      '56': 'binancecoin',
      '137': 'matic-network',
      '42161': 'ethereum',
      '10': 'ethereum',
      '8453': 'ethereum',
      '59144': 'ethereum',
      '43114': 'avalanche-2',
      '250': 'fantom'
    };

    const coinId = chainMapping[chainId];
    if (!coinId) return 0;

    try {
      const cacheKey = `native_${chainId}`;
      if (this.isCacheValid(cacheKey)) {
        return this.priceCache.get(cacheKey).price;
      }

      const response = await axios.get(`${this.coingeckoAPI}/simple/price`, {
        params: {
          ids: coinId,
          vs_currencies: 'usd'
        }
      });

      const price = response.data[coinId]?.usd || 0;
      this.updateCache(cacheKey, price);
      return price;
    } catch (error) {
      console.error('Error fetching native token price:', error);
      return 0;
    }
  }

  async getPrice(tokenAddress, chainId) {
    const cacheKey = `${chainId}_${tokenAddress}`;
    if (this.isCacheValid(cacheKey)) {
      return this.priceCache.get(cacheKey).price;
    }

    try {
      // Try CoinGecko first
      const price = await this.getPriceFromCoinGecko(tokenAddress, chainId);
      if (price > 0) {
        this.updateCache(cacheKey, price);
        return price;
      }

      // Fallback to 1inch API
      const oneInchPrice = await this.getPriceFrom1inch(tokenAddress, chainId);
      if (oneInchPrice > 0) {
        this.updateCache(cacheKey, oneInchPrice);
        return oneInchPrice;
      }

      return 0;
    } catch (error) {
      console.error('Error fetching token price:', error);
      return 0;
    }
  }

  async getPriceChange24h(tokenAddress, chainId) {
    try {
      const platformMapping = {
        '1': 'ethereum',
        '56': 'binance-smart-chain',
        '137': 'polygon-pos',
        '42161': 'arbitrum-one',
        '10': 'optimistic-ethereum',
        '8453': 'base',
        '59144': 'linea',
        '43114': 'avalanche',
        '250': 'fantom'
      };

      const platform = platformMapping[chainId];
      if (!platform) return 0;

      const response = await axios.get(
        `${this.coingeckoAPI}/coins/${platform}/contract/${tokenAddress}/market_chart`, {
          params: {
            vs_currency: 'usd',
            days: '1',
            interval: 'daily'
          }
        }
      );

      if (response.data?.prices?.length >= 2) {
        const prices = response.data.prices;
        const startPrice = prices[0][1];
        const endPrice = prices[prices.length - 1][1];
        return ((endPrice - startPrice) / startPrice) * 100;
      }

      return 0;
    } catch (error) {
      console.error('Error fetching price change:', error);
      return 0;
    }
  }

  async getPriceFromCoinGecko(tokenAddress, chainId) {
    const platformMapping = {
      '1': 'ethereum',
      '56': 'binance-smart-chain',
      '137': 'polygon-pos',
      '42161': 'arbitrum-one',
      '10': 'optimistic-ethereum',
      '8453': 'base',
      '59144': 'linea',
      '43114': 'avalanche',
      '250': 'fantom'
    };

    try {
      const platform = platformMapping[chainId];
      if (!platform) return 0;

      const response = await axios.get(
        `${this.coingeckoAPI}/simple/token_price/${platform}`, {
          params: {
            contract_addresses: tokenAddress,
            vs_currencies: 'usd'
          }
        }
      );

      return response.data[tokenAddress.toLowerCase()]?.usd || 0;
    } catch (error) {
      console.error('Error fetching CoinGecko price:', error);
      return 0;
    }
  }

  async getPriceFrom1inch(tokenAddress, chainId) {
    try {
      const response = await axios.get(
        `https://api.1inch.io/v5.0/${chainId}/quote`, {
          params: {
            fromTokenAddress: tokenAddress,
            toTokenAddress: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', // Native token
            amount: '1000000000000000000' // 1 token in wei
          }
        }
      );

      if (response.data?.toTokenAmount) {
        const nativePrice = await this.getNativeTokenPrice(chainId);
        const priceInNative = Number(response.data.toTokenAmount) / 1e18;
        return priceInNative * nativePrice;
      }

      return 0;
    } catch (error) {
      console.error('Error fetching 1inch price:', error);
      return 0;
    }
  }

  isCacheValid(key) {
    const cached = this.priceCache.get(key);
    if (!cached) return false;
    return Date.now() - cached.timestamp < this.cacheDuration;
  }

  updateCache(key, price) {
    this.priceCache.set(key, {
      price,
      timestamp: Date.now()
    });
  }
}

export default new PriceService();