// import binanceCoin from '../images/binance-coin.png';

import dyno from "../images/dyno.png";
import ethCoin from "../images/coins/eth-coin2.png";
import lineaCoin from "../images/coins/linea-coin.png";
import bnbCoin from "../images/coins/bnb-coin.png";
import baseCoin from "../images/coins/base-coin.png";
import zkCoin from "../images/coins/zkSyncIcon.png";



export const supportNetwork = {

// 84531: {
//  name: "Base Testnet",
//  chainId: 84531,
// rpc: "https://base-goerli.public.blastapi.io",
// image: baseCoin,
// symbol: "ETH",
  // },
  56: {
    name: "BNB Chain",
    chainId: 56,
    rpc: "https://bsc-pokt.nodies.app",
    image: bnbCoin,
    symbol: "BNB",
    available: true,
    scan: "https://bscscan.com/"
  },
  97: {
    name: "BNB Testnet",
    chainId: 97,
    rpc: "https://bsc-testnet-rpc.publicnode.com",
    image: bnbCoin,
    symbol: "TBNB",
    available: true,
    scan: "https://testnet.bscscan.com/"
  },
  84532:{
    name: "Base Testnet",
    chainId: 84532,
    rpc: "https://sepolia.base.org",
    image: baseCoin,
    symbol: "ETH",
    available: true,
    scan: "https://sepolia.basescan.org/"
  },
  8453: {
    name: "Base",
    chainId: 8453,
    rpc: "https://mainnet.base.org",
    image: baseCoin,
    symbol: "ETH",
    available: true,
    scan: "https://basescan.org/"

  },
  59144: {
    name: "Linea",
    chainId: 59144,
    rpc: "https://rpc.linea.build",
    image: lineaCoin,
    symbol: "ETH",
    available: true,
    scan: "https://lineascan.build/"

  },
  59140: {
    name: "Linea Testnet",
    chainId: 59140,
    rpc: "https://linea-goerli.blockpi.network/v1/rpc/public",
    image: lineaCoin,
    symbol: "ETH",
    available: false,
    scan: "https://explorer.goerli.linea.build/"
  },
  324: {
    name: "zkSync",
    chainId: 324, // Make sure the chainId matches the network
    rpc: "https://mainnet.era.zksync.io",
    image: zkCoin,
    symbol: "ETH",
    available: false,
    scan: "https://explorer.zksync.io/"
  },
  
 300: {
   name: "zkSync Testnet",
   chainId: 300, // Make sure the chainId matches the network
   rpc: "https://sepolia.era.zksync.dev",
   image: zkCoin,
   symbol: "ETH",
   available: false,
   scan: "https://sepolia.explorer.zksync.io/"
 },

  default: {
    name: "Base",
    chainId: 8453,
    rpc: "https://mainnet.base.org",
    image: baseCoin,
    symbol: "ETH",
    available: true,
    scan: "https://basescan.org/"

  },
};

export const netOrder = ['default', '8453', '56', '59144', '84532', '97', '59140' ];
// export const netOrder= ['default', '8453',  '56', '59144', '84532', '59140', '324' ];

export const networkLists = [
  { code: 0, chainId: 56, label: "BNB", image: bnbCoin, available: true },
  { code: 1, chainId: 97, label: "TBNB", image: bnbCoin, available: true },
  { order: 2, chainId: 8453, name: "BASE", image: baseCoin, available: true },
  { order: 3, chainId: 84532, name: "BASE Testnet", image: baseCoin, available: false },
  { order: 4, chainId: 59144, name: "Linea", image: lineaCoin, available: false },
  { order: 5, chainId: 59140, name: "Linea Testnet", image: lineaCoin, available: false },
  { order: 6, chainId: 324, name: "zkSync", image: zkCoin, available: false },
  { order: 7, chainId: 300, name: "zkSync Testnet (Soon)", image: zkCoin, available: false },
]

export const sortedNetworkLists = networkLists.sort((a, b) => a.order - b.order);

export const RPC_URLS = {
  56: "https://bsc-dataseed.bnbchain.org",
  97: "https://data-seed-prebsc-1-s1.binance.org:8545",
  84532: "https://sepolia.base.org",
  8453: "https://mainnet.base.org",
  59144: "https://rpc.linea.build",
  59140: "https://linea-goerli.blockpi.network/v1/rpc/public",
  324: "https://mainnet.era.zksync.io",
  300: "https://sepolia.era.zksync.dev",
};
