import React, { useState, useContext, useEffect } from "react";
import "../launchpadApply/index.css";
import { useWeb3React } from "@web3-react/core";
import Button from "react-bootstrap-button-loader";
import { getWeb3 } from '../../hooks/connectors';
import { toast } from "react-toastify";
import { parseEther, parseUnits, formatEther } from "@ethersproject/units";
import airdropAbi from '../../json/Airdrop.json';
import { contract } from '../../hooks/constant';
import { getContract } from '../../hooks/contractHelper';
import { useAccount, useChainId } from "wagmi";

import { config } from "../../config";
import { writeContract, waitForTransactionReceipt } from "@wagmi/core";

export default function AirdropEth() {
  // const { chainId, account, library } = useWeb3React();
  const chainId = useChainId()
  const { address: account } = useAccount()
  const [createloading, setCreateloading] = useState(false);
  const resolveAfter3Sec = new Promise((resolve) => setTimeout(resolve, 10000));
  const [airdropText, setAirdropText] = useState("");
  const [value, setValue] = useState({ maxAmount: 0, airdropAmount: 0, airdropAddrs: [] });
  const [error, setError] = useState({
    maxAmount: "",
    airdropAmount: "",
    airdropText: "",
  });
  const [serviceFee, setServiceFee] = useState(0.002);
  const [baseToken, setBaseToken] = useState('ETH');
  useEffect(() => {
    if (!chainId || !account) {
      return;
    }
    else {
      const getEthBalance = async () => {
        let web3 = getWeb3(chainId);
        let balance = await web3.eth.getBalance(account);
        setValue({ ...value, maxAmount: formatEther(balance) });
      }
      getEthBalance()
      if (chainId == 56) {
        setServiceFee(0.015);
        setBaseToken('BNB');
      }
    }
  }, [account, chainId])
  const checkStandardAllValidation = () => {
    let terror = 0;
    if (!value.airdropAddrs || value.airdropAddrs.length == 0) {
      setError({ ...error, airdropText: "Please input addresses to send airdrop!" });
      toast.error("Please input addresses to send airdrop!!")
      return false;
    }
    Object.keys(value).map((key, index) => {
      switch (key) {
        case "airdropAmount":
          if (value[key] < 0 || value[key] * value.airdropAddrs.length + serviceFee > value.maxAmount) {
            terror += 1;
            setError({ ...error, airdropAmount: "Your balance is not enough!" });
            toast.error("Your balance is not enough!");
          }
          break;
        case "airdropAddrs":
          let web3 = getWeb3(chainId);
          let checkAddress = true;
          for (let i = 0; i < value[key].length; i++) {
            checkAddress = web3.utils.isAddress(value[key][i]);
          }
          if (!checkAddress) {
            terror += 1;
            setError({ ...error, airdropText: "Please input valid addresses!" });
            toast.error("Please input valid addresses!");
          }
          break;
        default:
          terror += 0;
          break;
      }
      return true;
    });
    if (terror > 0) {
      return false;
    } else {
      return true;
    }
  };
  const setAirdropAddrs = (e) => {
    e.preventDefault();
    if (!account || !chainId) {
      setValue({
        maxAmount: "",
        airdropAmount: "",
        airdropText: "",
      });
      toast.error("error ! connect wallet! 👍");
      return;
    }
    else {
      let addrs = e.target.value.split('\n');
      // checkStandardValidation(e.target.name, addrs);

      setValue({ ...value, "airdropAddrs": addrs });
    }
  }
  const onChangeInput = (e) => {
    e.preventDefault();
    if (!account || !chainId) {
      setValue({
        maxAmount: "",
        airdropAmount: "",
        airdropText: "",
      });
      toast.error("error ! connect wallet! 👍");
      return;
    }
    else {
      // checkStandardValidation(e.target.name, e.target.value);
      setValue({ ...value, [e.target.name]: e.target.value });
    }

  };
  const handleAirdrop = async (e) => {
    e.preventDefault();
    let check = false;
    try {

      if (!account) {
        toast.error("error ! connect wallet! 👍");
        setCreateloading(false);
        return;
      }
      check = checkStandardAllValidation();
      if (check) {
        let airdropAddress = contract[chainId].airdrop;
        setCreateloading(true);
        let airdropAmounts = [];
        for (let i = 0; i < value.airdropAddrs.length; i++) {
          airdropAmounts.push(parseEther(value.airdropAmount));
        }
        // let airdropContract = getContract(
        //   airdropAbi,
        //   airdropAddress,
        //   library
        // );
        // let tx = await airdropContract.distrubuteEther(value.airdropAddrs, airdropAmounts, { from: account, value: (parseEther((value.airdropAmount * value.airdropAddrs.length + serviceFee).toString())).toString() });
        const result = await writeContract(config, {
          abi: airdropAbi,
          address: airdropAddress,
          functionName: 'distrubuteEther',
          args: [value.airdropAddrs, airdropAmounts],
          value: (parseEther((value.airdropAmount * value.airdropAddrs.length + serviceFee).toString())).toString()
        });
        toast.promise(resolveAfter3Sec, { pending: "Waiting for confirmation 👌" });

        var interval = setInterval(async function () {
          let web3 = getWeb3(chainId);
          const response = await waitForTransactionReceipt(config, { hash: result });
          if (response && response.status && response.status == 'success') {
            clearInterval(interval);
            if (response.status === 'success') {
              toast.success(
                "success ! transaction successful 👍"
              );
              setCreateloading(false);
            } else if (response.status === false) {
              toast.error("error ! Your transaction is failed.");
              setCreateloading(false);
            } else {
              toast.error("error ! something went wrong.");
              setCreateloading(false);
            }
          }
        }, 5000);
      }

    }
    catch (err) {
      console.log("here", err);
      // setError(err.reason);
      toast.error(err.reason)
    }
    setCreateloading(false);

  };
  return (
    // <Context.Provider>
    <React.Fragment>
      <section className="explore-area prev-project-area">
        <div className="container px-3">
          <div className="intro">
            <div className="intro-content text-center">
              <span className="intro-text">Send Airdrop</span>
            </div>
          </div>
          <div className="my-5">
            <div className="card stepcard">
              <div className="card-body">
                <form className="login-box">
                  <div className={`tab-pane active mt-3`} role="tabpanel" id="step1">
                    <h4 className="text-center">You have {value.maxAmount} {baseToken}</h4>
                    <div className="row">
                      <div className="col-md-12 mt-4 mb-0">
                        <div className="">
                          <label>
                            {baseToken} amount for airdrop<span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            onChange={(e) => onChangeInput(e)}
                            // value={value.decimals}
                            type="number"
                            name="airdropAmount"
                            placeholder="Ex: 0.02"
                          />
                          <small className="text-danger">{error.airdropAmount}</small>
                          <br />
                        </div>
                      </div>
                      <div className='col-md-12 mt-4 mb-0'>
                        <div className='form-group'>
                          <label>Address list<span className="text-danger">*</span></label>
                          <textarea
                            type='text'
                            name='airdropText'
                            onChange={(e) => setAirdropAddrs(e)}
                            className='brief'
                            placeholder='0x7308BD2ea2bc85B44e44c64B30c14C189F75531d&#10;0xb29a63Bbe11A6a8C9753fB54506D0d0696Ade46e&#10;0xD05565dB55Da00f5Ed439CF2861DFC722780a796'
                          >
                            {airdropText}
                          </textarea>
                          <small className='text-danger'>{error.airdropText}</small>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="list-inline text-center">
                    <li>
                      <Button
                        type="button"
                        className="default-btn"
                        loading={createloading}
                        onClick={(e) => handleAirdrop(e)}
                      >
                        Send Airdrop
                      </Button>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
    // </Context.Provider>
  );
}
