import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CreatePool = () => {
  const navigate = useNavigate();
  
  // State for form inputs
  const [formData, setFormData] = useState({
    name: '',
    tokenSymbol: '',
    apy: '',
    hardCap: '',
    logoUrl: '',
    bannerUrl: '',
    totalStaked: '', // Optional
    liveStatus: true, // Default to live
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to create the pool (could send data to backend or update state)
    console.log("Creating pool with data:", formData);
    // Redirect to staking pool list after creating the pool
    navigate('/staking');
  };

  return (
    <div
      style={{
        width: '100%',
        minHeight: '100vh',
        backgroundColor: '#0A0B1E',
        color: '#FFFFFF',
        padding: '20px',
        fontFamily: "'Quicksand', sans-serif",
      }}
    >
      <h1 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '20px' }}>Create Staking Pool</h1>
      
      <form
        onSubmit={handleSubmit}
        style={{
          backgroundColor: '#161A42',
          padding: '20px',
          borderRadius: '12px',
          maxWidth: '500px',
          margin: '0 auto',
        }}
      >
        {/* Pool Name */}
        <label style={{ display: 'block', marginBottom: '10px' }}>
          Pool Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '10px',
              marginTop: '5px',
              borderRadius: '8px',
              border: 'none',
              backgroundColor: '#0A0B1E',
              color: '#FFF',
            }}
            required
          />
        </label>

        {/* Token Symbol */}
        <label style={{ display: 'block', marginBottom: '10px' }}>
          Token Symbol:
          <input
            type="text"
            name="tokenSymbol"
            value={formData.tokenSymbol}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '10px',
              marginTop: '5px',
              borderRadius: '8px',
              border: 'none',
              backgroundColor: '#0A0B1E',
              color: '#FFF',
            }}
            required
          />
        </label>

        {/* APY */}
        <label style={{ display: 'block', marginBottom: '10px' }}>
          APY (%):
          <input
            type="number"
            name="apy"
            value={formData.apy}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '10px',
              marginTop: '5px',
              borderRadius: '8px',
              border: 'none',
              backgroundColor: '#0A0B1E',
              color: '#FFF',
            }}
            required
          />
        </label>

        {/* Hard Cap */}
        <label style={{ display: 'block', marginBottom: '10px' }}>
          Hard Cap:
          <input
            type="text"
            name="hardCap"
            value={formData.hardCap}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '10px',
              marginTop: '5px',
              borderRadius: '8px',
              border: 'none',
              backgroundColor: '#0A0B1E',
              color: '#FFF',
            }}
            required
          />
        </label>

        {/* Logo URL */}
        <label style={{ display: 'block', marginBottom: '10px' }}>
          Logo URL:
          <input
            type="url"
            name="logoUrl"
            value={formData.logoUrl}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '10px',
              marginTop: '5px',
              borderRadius: '8px',
              border: 'none',
              backgroundColor: '#0A0B1E',
              color: '#FFF',
            }}
            required
          />
        </label>

        {/* Banner URL */}
        <label style={{ display: 'block', marginBottom: '10px' }}>
          Banner URL:
          <input
            type="url"
            name="bannerUrl"
            value={formData.bannerUrl}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '10px',
              marginTop: '5px',
              borderRadius: '8px',
              border: 'none',
              backgroundColor: '#0A0B1E',
              color: '#FFF',
            }}
            required
          />
        </label>

        <button
          type="submit"
          style={{
            width: '100%',
            padding: '10px',
            marginTop: '20px',
            backgroundColor: '#3461FF',
            color: '#FFF',
            fontWeight: '500',
            borderRadius: '8px',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          Create Pool
        </button>
      </form>
    </div>
  );
};

export default CreatePool;
