import React, { useContext, useState } from 'react';
import { formatPrice } from '../../../hooks/contractHelper';
import Context from "./context/Context";
// import { useCommonStats } from './hooks/useStats';
import { useWeb3React } from "@web3-react/core";
import { supportNetwork } from '../../../hooks/network';
import kycImg from '../../../images/kyc.png';
import auditImg from '../../../images/secure.png';


export default function Step4() {
  const { value, btnNextStep, btnPrevStep, setValue } = useContext(Context);
  const [updater] = useState(new Date());
  // const commonStats = useCommonStats(updater)
  const btnNextStepValidation = () => {
    btnNextStep();
  }

  // const handleCheckboxAudit = (e) => {
  //   setValue({
  //     ...value, [e.target.name]: !value.audit, totalCost: e.target.checked === true ? Math.round((parseFloat(value.totalCost) + parseFloat(commonStats.auditPrice)) * 1e12) / 1e12 :
  //       Math.round((parseFloat(value.totalCost) - parseFloat(commonStats.auditPrice)) * 1e12) / 1e12
  //   })

  // }

  // const handleCheckboxKyc = (e) => {
  //   setValue({
  //     ...value, [e.target.name]: !value.kyc, totalCost: e.target.checked === true ?
  //       Math.round((parseFloat(value.totalCost) + parseFloat(commonStats.kycPrice)) * 1e12) / 1e12 :
  //       Math.round((parseFloat(value.totalCost) - parseFloat(commonStats.kycPrice)) * 1e12) / 1e12
  //   })
  // }

  const onChangeInput = (e) => {
    e.preventDefault();
    setValue({ ...value, [e.target.name]: e.target.value });
  }

  return (
    <div className={`tab-pane ${value.step === 4 ? 'active' : ''}`} role="tabpanel" id="step4">
      <h4 className="text-center">AUDIT, KYC & SAFU badges.</h4>
      {/* New line of text under the header */}
      <p className="text-center">Give users trust & get free marketing.
        Apply on our <a href="https://discord.gg/Hqg4FzjPVz" target="_blank" style={{ color: '#28a745' }} rel="noopener noreferrer">Discord</a></p>

      <div className="container text-center">
        {/* Increased marginBottom for the image */}
        <img src={`${process.env.PUBLIC_URL}/assets/servicesOverviewImage.png`} alt="Services Overview" style={{ width: '50%', marginBottom: '40px' }} />
        <p style={{ textAlign: 'left', marginLeft: 'auto', marginRight: 'auto', maxWidth: '600px' }}>

        </p>
        {/* Unstyled list */}
        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
          <li>Audit badge $199 (Usually $1500+)</li>
          <li>Audit & KYC badge $399 (Usually $2500+)</li>
          <li>SAFU badge is FREE for tokens created on our App</li>

        </ul>
      </div>
      <div className="text-center">
        <button type="button" className="btn default-btn prev-step" onClick={btnPrevStep}>Back</button>
        <button type="button" className="btn default-btn next-step" onClick={btnNextStep}>Continue</button>
      </div>
    </div>
  );
}
