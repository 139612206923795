import React, { useContext, useState } from "react";
import Context from "./context/Context";
import { toast } from "react-toastify";

export default function Step3() {
  const { value, btnNextStep, btnPrevStep, setValue } = useContext(Context);
  const [error, setError] = useState({
    logourl: "",
    bannerurl: "",
    website: "",
    facebook: "",
    twitter: "",
    github: "",
    telegram: "",
    instagram: "",
    discord: "",
    reddit: "",
    youtube: "",
    brief: "",
    blockstart: "",
  });
  const [showTooltip, setShowTooltip] = useState("");
  const [showSocials, setShowSocials] = useState(false);
  const [isMobile] = useState(window.innerWidth <= 600);

  const styles = {
    container: {
      padding: '32px',
      background: '#0D1021',
      borderRadius: '16px',
      border: '1px solid rgba(255, 255, 255, 0.1)',
    },
    inputContainer: {
      marginBottom: '24px',
      width: '100%',
    },
    label: {
      color: '#34D399',
      fontSize: '14px',
      fontWeight: '400',
      marginBottom: '8px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    input: {
      width: '100%',
      height: '50px',
      background: 'linear-gradient(162deg, #161A42 0%, rgba(22, 26, 66, 0) 100%)',
      borderRadius: '8px',
      border: '2.57px #212554 solid',
      padding: '0 16px',
      color: '#fff',
      fontSize: '14px',
      outline: 'none',
    },
    textarea: {
      width: '100%',
      minHeight: '120px',
      background: 'linear-gradient(162deg, #161A42 0%, rgba(22, 26, 66, 0) 100%)',
      borderRadius: '8px',
      border: '2.57px #212554 solid',
      padding: '16px',
      color: '#fff',
      fontSize: '14px',
      outline: 'none',
      resize: 'vertical',
      fontFamily: 'inherit',
    },
    header: {
      fontSize: '24px',
      color: '#fff',
      fontWeight: '600',
      marginBottom: '32px',
      textAlign: 'center',
    },
    formGrid: {
      display: 'grid',
      gridTemplateColumns: isMobile ? '1fr' : 'repeat(2, 1fr)',
      gap: '24px',
      width: '100%',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: '16px',
      marginTop: '32px',
    },
    button: {
      width: '400px',
      height: '50px',
      background: '#2F6EFF',
      borderRadius: '8px',
      color: '#fff',
      fontSize: '16px',
      fontWeight: '600',
      border: 'none',
      cursor: 'pointer',
      transition: 'background 0.2s',
      padding: '0 32px',
      display: 'block',
      margin: '24px auto 0',
    },
    backButton: {
      background: '#374151',
    },
    requiredStar: {
      color: '#EF4444',
      marginLeft: '4px',
    },
    error: {
      color: '#EF4444',
      fontSize: '12px',
      marginTop: '4px',
    },
    tooltip: {
      position: 'relative',
      display: 'inline-block',
      marginLeft: '8px',
    },
    tooltipText: {
      visibility: 'hidden',
      width: '240px',
      backgroundColor: '#1F2937',
      color: '#7B91B0',
      textAlign: 'left',
      padding: '8px 12px',
      borderRadius: '6px',
      position: 'absolute',
      zIndex: 1,
      bottom: '150%',
      left: '50%',
      transform: 'translateX(-50%)',
      opacity: 0,
      transition: 'all 0.2s ease',
      fontSize: '12px',
      fontFamily: 'Quicksand, sans-serif',
      border: '1px solid #374151',
    },
    socialSection: {
      marginTop: '24px',
      borderTop: '1px solid rgba(255, 255, 255, 0.1)',
      paddingTop: '24px',
      width: '100%',
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      marginBottom: '16px',
      cursor: 'pointer',
    },
    checkbox: {
      width: '20px',
      height: '20px',
      background: 'linear-gradient(162deg, #161A42 0%, rgba(22, 26, 66, 0) 100%)',
      borderRadius: '4px',
      border: '2.57px #212554 solid',
      appearance: 'none',
      outline: 'none',
      cursor: 'pointer',
      position: 'relative',
      accentColor: '#34D399',
    },
    checkboxLabel: {
      color: '#34D399',
      fontSize: '14px',
      fontWeight: '400',
    },
    descriptionContainer: {
      width: '100%',
      marginTop: '24px',
    }
  };

  const InfoIcon = ({ tooltip }) => (
    <div 
      style={styles.tooltip} 
      onMouseEnter={() => setShowTooltip(tooltip)}
      onMouseLeave={() => setShowTooltip("")}
    >
      <span style={{ color: '#7B91B0', cursor: 'pointer', fontSize: '14px' }}>ⓘ</span>
      <div style={{
        ...styles.tooltipText,
        visibility: showTooltip === tooltip ? 'visible' : 'hidden',
        opacity: showTooltip === tooltip ? 1 : 0,
      }}>
        {tooltip}
      </div>
    </div>
  );

  const checkValidation = (input, inputValue) => {
    let terror = 0;
    let message = "";
    var reg;
    switch (input) {
      case "facebook":
      case "twitter":
      case "github":
      case "telegram":
      case "instagram":
      case "discord":
      case "reddit":
      case "youtube":
      case "blockstart":
        reg = new RegExp(
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
        );
        if (inputValue !== "" && !reg.test(inputValue)) {
          terror += 1;
          message = "Please Enter a Valid URL Ser!";
        } else {
          message = "";
        }
        break;

      case "logourl":
      case "bannerurl":
      case "website":
        reg = new RegExp(
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
        );
        if (!reg.test(inputValue)) {
          terror += 1;
          message = "Please Enter a Valid URL bro!";
        } else {
          message = "";
        }
        break;
      default:
        terror += 0;
        break;
    }

    if (terror > 0) {
      setError({ ...error, [input]: message });
      return false;
    } else {
      setError({ ...error, [input]: "" });
      return true;
    }
  };

  const checkAllValidation = () => {
    let terror = 0;
    var reg;
    Object.keys(value).forEach((key) => {
      switch (key) {
        case "facebook":
        case "twitter":
        case "github":
        case "telegram":
        case "instagram":
        case "discord":
        case "reddit":
        case "youtube":
        case "blockstart":
          reg = new RegExp(
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
          );
          if (value[key] !== "" && !reg.test(value[key])) {
            terror += 1;
          }
          break;

        case "logourl":
        case "bannerurl":
        case "website":
          reg = new RegExp(
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
          );
          if (!reg.test(value[key])) {
            terror += 1;
          }
          break;
        default:
          break;
      }
    });

    return terror === 0;
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    checkValidation(e.target.name, e.target.value);
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const btnNextStepValidation = () => {
    let check = checkAllValidation();
    if (check) {
      setValue({
        ...value,
        step: parseInt(5) // Skip step 4 and go directly to step 5
      });
    } else {
      toast.error("All fields are required Ser! Please check again");
    }
  };

    // Add this toggle function
    const toggleSocials = () => {
      setShowSocials(!showSocials);
    };

 // Helper components
 const RequiredStar = () => (
  <span style={styles.requiredStar}>*</span>
);

const ErrorMessage = ({ children }) => (
  <small style={styles.error}>{children}</small>
);

const FormGroup = ({ children }) => (
  <div style={styles.inputContainer}>
    {children}
  </div>
);

return (
  <div style={styles.container}>
    <h4 style={styles.header}>Let users know who you are</h4>

    {/* Required Fields Section */}
    <div style={styles.formGrid}>
      <FormGroup>
        <label style={styles.label}>
          Logo URL
          <RequiredStar />
          <InfoIcon tooltip="URL to your project's logo. Use https://postimages.org/ and copy the Direct Link URL." />
        </label>
        <input
          style={styles.input}
          onChange={onChangeInput}
          type="text"
          name="logourl"
          value={value.logourl}
          placeholder="e.g. https://i.postimg.cc/my_logo.png"
        />
        <ErrorMessage>{error.logourl}</ErrorMessage>
      </FormGroup>

      <FormGroup>
        <label style={styles.label}>
          Banner Image URL
          <RequiredStar />
          <InfoIcon tooltip="URL to your project's banner. Use https://postimages.org/ and copy the Direct Link URL." />
        </label>
        <input
          style={styles.input}
          onChange={onChangeInput}
          type="text"
          name="bannerurl"
          value={value.bannerurl}
          placeholder="e.g. https://i.postimg.cc/my_banner.png"
        />
        <ErrorMessage>{error.bannerurl}</ErrorMessage>
      </FormGroup>

      <FormGroup>
        <label style={styles.label}>
          Website
          <RequiredStar />
        </label>
        <input
          style={styles.input}
          onChange={onChangeInput}
          type="text"
          name="website"
          value={value.website}
          placeholder="e.g. https://defilaunch.app"
        />
        <ErrorMessage>{error.website}</ErrorMessage>
      </FormGroup>
    </div>

    {/* Add Socials Toggle Section */}
    <div style={styles.socialSection}>
      <FormGroup>
        <div style={styles.checkboxContainer}>
          <input
            type="checkbox"
            style={styles.checkbox}
            checked={showSocials}
            onChange={toggleSocials}
          />
          <label style={styles.checkboxLabel}>
            Add Socials
          </label>
        </div>
      </FormGroup>

      {/* Social Media Fields */}
      {showSocials && (
        <div style={styles.formGrid}>
          <FormGroup>
            <label style={styles.label}>
              X/Twitter
            </label>
            <input
              style={styles.input}
              onChange={onChangeInput}
              type="text"
              name="twitter"
              value={value.twitter}
              placeholder="e.g. https://twitter.com/"
            />
            <ErrorMessage>{error.twitter}</ErrorMessage>
          </FormGroup>

          <FormGroup>
            <label style={styles.label}>
              Telegram
            </label>
            <input
              style={styles.input}
              onChange={onChangeInput}
              type="text"
              name="telegram"
              value={value.telegram}
              placeholder="e.g. https://t.me/defilaunchapp"
            />
            <ErrorMessage>{error.telegram}</ErrorMessage>
          </FormGroup>

          <FormGroup>
            <label style={styles.label}>
              Discord
            </label>
            <input
              style={styles.input}
              onChange={onChangeInput}
              type="text"
              name="discord"
              value={value.discord}
              placeholder="e.g. https://discord.com/"
            />
            <ErrorMessage>{error.discord}</ErrorMessage>
          </FormGroup>

          <FormGroup>
            <label style={styles.label}>
              Youtube Video
              <InfoIcon tooltip="Embedded on your sale page." />
            </label>
            <input
              style={styles.input}
              onChange={onChangeInput}
              type="text"
              name="youtube"
              value={value.youtube}
              placeholder="e.g. https://youtube.com/watch"
            />
            <ErrorMessage>{error.youtube}</ErrorMessage>
          </FormGroup>
        </div>
      )}
    </div>

    {/* Description Section */}
    <FormGroup>
      <label style={styles.label}>
        Description
        <InfoIcon tooltip="Brief description of what your project is about." />
      </label>
      <textarea
        style={styles.textarea}
        name="brief"
        onChange={onChangeInput}
        placeholder="Eg: The new Degenerate meme coin. Etc.."
        value={value.brief}
      />
      <ErrorMessage>{error.brief}</ErrorMessage>
    </FormGroup>

    {/* Button Section */}
    <div style={styles.buttonContainer}>
      <button
        type="button"
        style={{ ...styles.button, ...styles.backButton }}
        onClick={btnPrevStep}
      >
        Back
      </button>
      <button
        type="button"
        style={styles.button}
        onClick={btnNextStepValidation}
      >
        Continue
      </button>
    </div>
  </div>
);
}